import { useEffect, useRef, useState } from 'react';

type Timer = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  isExpired: boolean;
};

const TIMER_INTERVAL_MS = 1000;

export function useTimer(timestamp: string): Timer {
  const intervalId = useRef<ReturnType<typeof setInterval> | null>(null);
  const countDownDate = new Date(timestamp).getTime();
  const now = new Date().getTime();
  const distance = countDownDate - now;

  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  const isExpired = distance < 0;
  const [timer, setTimer] = useState({
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
    isExpired: isExpired,
  });

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const now = new Date().getTime();
      const distance = countDownDate - now;
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      const isExpired = distance < 0;

      setTimer({ days, hours, minutes, seconds, isExpired });

      if (isExpired && intervalId.current) {
        clearInterval(intervalId.current);
      }
    }, TIMER_INTERVAL_MS);

    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, [countDownDate]);

  return timer;
}
