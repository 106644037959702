import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import { css } from '@emotion/core';

import { RootState } from '../../../store/rootReducer';
import { Size } from '../types';
import {
  sortSizes,
  getIsSizeAvailable,
  getSizeMessage,
  getSizeLabel,
  getProductImage,
  sizeNoLongerAvailable,
} from '../utils';
import { Sizes } from './Sizes';
import { Colors } from './Colors';
import { changeSize, setSizeErrMsg } from '../actions';
import { Box, Icon, DynamicButton, DynamicButtonStatus, breakpoints } from '../../../design-system';
import { Color } from './../../catalog/components/Color';
import { Status } from '../../../design-system/button/components/DynamicButton';
import { CTA_CONTENT_STICKY_CTA, LBL_SIZE, LBL_COLOR, LBL_QUANTITY } from '../locale';
import { Product } from '../types';
import { formatPrice, getIsClient } from '../../common/utils';
import { zIndex, opacities, durations, transitions } from '../../common/constants';
import { transitionStylesBackground } from '../../common/components/Sidebar';

const transitionStylesContent = {
  entering: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
  entered: {
    opacity: 1,
    transform: 'translateY(0)',
    transition: 'transform 0.5s ease-out',
  },
  exiting: {
    opacity: 0,
    transform: 'translateY(100%)',
    transition: 'transform 0.5s ease-out',
  },
  exited: {
    opacity: 0,
    transform: 'translateY(100%)',
  },
};

type Props = {
  size: Size;
  sizeErrMsg: string;
  ctaState: DynamicButtonStatus;
  quantity: number;
  objectID: string;
  isQuickAddOnPDP: boolean;
  product: Product;
  isTablet: boolean;
  pdpAddToCartRef: MutableRefObject<HTMLDivElement | null>;
  handleCtaClick: () => void;
};

const StickySelector = ({
  product,
  sizeErrMsg,
  size,
  isQuickAddOnPDP,
  ctaState,
  quantity,
  objectID,
  isTablet,
  pdpAddToCartRef,
  handleCtaClick,
}: Props) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const position = 1;
  const width = 48;
  const {
    productName,
    productRef,
    colorRef,
    colorLabel,
    detailedColorLabel,
    colorVariants,
    sizeVariants,
    status,
    storePrice,
  } = product ?? {};
  const image = getProductImage({ productRef, productName, colorRef, colorLabel, position, width });
  const name = productName && productName.split(' - ')[0];
  const category = productName && productName.split(' - ')[1];
  const color = colorVariants.filter((item) => item.colorRef === colorRef);
  const [colorBlockIsOpen, setColorBlockIsOpen] = useState(false);
  const [sizeBlockIsOpen, setSizeBlockIsOpen] = useState(false);
  const [hoveredSku, setHoveredSku] = useState('');
  const [sizeSelected, setSizeSelected] = useState('');
  const [isActive, setIsActive] = useState(false);
  const colorBlockRef = useRef<HTMLDivElement>(null);
  const sizeBlockRef = useRef<HTMLDivElement>(null);
  const sortedSizes = sortSizes(sizeVariants ?? []);
  const hoveredSize = sortedSizes.find((size) => size.sku === hoveredSku);
  const sizeMessage = getSizeMessage(hoveredSize);
  const activeColors = (colorVariants ?? []).filter((color) => color.status);
  const { cart } = useSelector((state: RootState) => state.cart);
  const isNoLongerAvailable = sizeNoLongerAvailable(cart, productRef, colorRef, sizeVariants);
  const selectorWidth = isTablet ? 144 : 164;
  const modalColorWidth = isTablet ? 320 : 360;
  const modalSizeWidth = isTablet ? 427 : 496;
  const onColorClick = (colorRef: string) => {
    const link = pathname.split('-').slice(0, -1).concat(colorRef).join('-');
    navigate(link);
  };
  const onSizeClick = (sku: string) => {
    const clickedSize = sortedSizes.find((size) => size.sku === sku);
    if (clickedSize && getIsSizeAvailable(clickedSize)) {
      setSizeSelected(getSizeLabel(clickedSize));
      dispatch(changeSize(clickedSize));
    }
  };
  const onClick = (target: string) => {
    if (target === 'color') {
      sizeBlockIsOpen && setSizeBlockIsOpen(false);
      setColorBlockIsOpen(!colorBlockIsOpen);
    }
    if (target === 'size') {
      colorBlockIsOpen && setColorBlockIsOpen(false);
      setSizeBlockIsOpen(!sizeBlockIsOpen);
    }
  };
  const onCtaClick = () => {
    if (sizeSelected) {
      colorBlockIsOpen && setColorBlockIsOpen(false);
      sizeBlockIsOpen && setSizeBlockIsOpen(false);
    }
    if (sizeSelected === '') {
      colorBlockIsOpen && setColorBlockIsOpen(false);
      !sizeBlockIsOpen && setSizeBlockIsOpen(true);
    }
    handleCtaClick();
  };
  const handleBackgroundClick = () => {
    colorBlockIsOpen && setColorBlockIsOpen(false);
    sizeBlockIsOpen && setSizeBlockIsOpen(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsActive(!entry.isIntersecting);
      },
      {
        root: null,
        threshold: 1,
      }
    );

    if (pdpAddToCartRef.current) {
      observer.observe(pdpAddToCartRef.current);
    }

    return () => {
      if (pdpAddToCartRef.current) {
        observer.unobserve(pdpAddToCartRef.current);
      }
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    setSizeSelected(getSizeLabel(size));
  }, [size]);

  useEffect(() => {
    if (!isActive) {
      colorBlockIsOpen && setColorBlockIsOpen(false);
      sizeBlockIsOpen && setSizeBlockIsOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    dispatch(setSizeErrMsg(''));
  }, [size, hoveredSku]);

  const adjustPositionForWideScreen =
    getIsClient() && window.innerWidth > 1440 ? (window.innerWidth - 1440) / 2 : 0;

  return (
    <>
      <Transition
        in={colorBlockIsOpen || sizeBlockIsOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            position="fixed"
            width="100%"
            height="100%"
            bg="rgba(0, 0, 0, 0)"
            top={0}
            left={0}
            opacity={opacities.BACKGROUND}
            zIndex={zIndex.PRODUCT_SELECTOR_MODAL - 1}
            transition={transitions.GENERIC}
            onClick={handleBackgroundClick}
            style={{
              ...transitionStylesBackground[state],
            }}
          />
        )}
      </Transition>

      <Transition
        in={colorBlockIsOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            id="color-sticky-cta-modal"
            width={`${modalColorWidth}px`}
            height="92px"
            justifyContent="center"
            display="flex"
            position="fixed"
            bg="white"
            borderRadius={8}
            bottom={88}
            left={
              colorBlockRef.current?.offsetLeft &&
              adjustPositionForWideScreen +
                colorBlockRef.current.offsetLeft +
                selectorWidth -
                modalColorWidth
            }
            zIndex={zIndex.PRODUCT_SELECTOR_MODAL}
            border="1px solid #F2F2F2"
            boxShadow="0px -2px 8px rgba(0, 0, 0, 0.15)"
            style={{
              ...transitionStylesContent[state],
            }}
          >
            <Colors
              productRef={productRef}
              objectID={objectID}
              colorRef={colorRef}
              detailedColorLabel={detailedColorLabel}
              onColorClick={onColorClick}
              colorVariants={activeColors}
              setColorBlockIsOpen={setColorBlockIsOpen}
              reverseToolTip
              isStickyCta
            />
          </Box>
        )}
      </Transition>

      <Transition
        in={sizeBlockIsOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            id="size-sticky-cta-modal"
            width={`${modalSizeWidth}px`}
            height="auto"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            position="fixed"
            bg="white"
            borderRadius={8}
            bottom={88}
            py="m"
            pl="m"
            left={
              sizeBlockRef.current?.offsetLeft &&
              adjustPositionForWideScreen +
                sizeBlockRef.current.offsetLeft +
                selectorWidth -
                modalSizeWidth
            }
            zIndex={zIndex.PRODUCT_SELECTOR_MODAL}
            border="1px solid #F2F2F2"
            boxShadow="0px -2px 8px rgba(0, 0, 0, 0.15)"
            style={{
              ...transitionStylesContent[state],
            }}
          >
            <Sizes
              {...{
                objectID,
                sizeMessage,
                sortedSizes,
                setHoveredSku,
                isNoLongerAvailable,
              }}
              errMsg={sizeErrMsg}
              selectedSku={size.sku}
              setSelectedSku={onSizeClick}
              setSizeBlockIsOpen={setSizeBlockIsOpen}
              isStickyCta
            />
          </Box>
        )}
      </Transition>

      <Transition
        in={isActive}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: durations.EXIT }}
      >
        {(state) => (
          <Box
            id="sticky-cta-pdp-block"
            width="100%"
            maxWidth="1440px"
            alignItems="center"
            justifyContent="center"
            display="flex"
            position="fixed"
            bottom={0}
            zIndex={zIndex.PRODUCT_SELECTOR_MODAL}
            style={{
              ...transitionStylesContent[state],
            }}
          >
            <Box
              width="inherit"
              height="80px"
              borderRadius={8}
              display="flex"
              justifyContent="space-between"
              bg="white"
              mx={isTablet ? '16px' : '24px'}
              style={{ boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.16)' }}
              css={css`
                padding: 8px;

                @media (min-width: ${breakpoints.M}px) {
                  padding: 8px 40px;
                }
              `}
            >
              <Box display="flex" id="sticky-cta-product-name" width="auto">
                <Box
                  width="48px"
                  minWidth="48px"
                  height="64px"
                  backgroundImage={`url(${image.jpg})`}
                  backgroundPosition="center"
                  backgroundSize="contain"
                  backgroundRepeat="no-repeat"
                  borderRadius="8px"
                />
                <Box alignItems="center" justifyContent="center" alignSelf="center" mx="m">
                  <Box
                    fontSize={isTablet ? '14px' : '16px'}
                    lineHeight="130%"
                    style={{
                      overflow: 'hidden',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <span style={{ fontWeight: 700 }}>{category}</span>
                    <span style={{ fontWeight: 700, marginLeft: '4px', marginRight: '4px' }}>
                      |
                    </span>
                    <span style={{ fontWeight: 400 }}>{name}</span>
                  </Box>
                  <Box fontSize="14px" lineHeight="110%" mt="xs" color="#5A5A5A">
                    <span>{LBL_QUANTITY}</span>
                    <span style={{ fontWeight: 700, marginLeft: '4px' }}>{quantity}</span>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" width="auto">
                <Box
                  ref={colorBlockRef}
                  id="sticky-cta-color-button"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  width={`${selectorWidth}px`}
                  height="50px"
                  p="m"
                  fontSize="14px"
                  lineHeight="19px"
                  letterSpacing="0.04em"
                  fontWeight={400}
                  border="1px solid #E6E6E6"
                  borderRadius={8}
                  onClick={() => onClick('color')}
                >
                  <Box mr="s">{LBL_COLOR}</Box>

                  {color.length > 0 && (
                    <Color productRef={productRef} color={color[0]} reverseToolTip isSelected />
                  )}
                  <Box
                    ml="m"
                    transform={colorBlockIsOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
                    transition="all .25s ease-in-out"
                  >
                    <Icon name="chevronDown" size={12} />
                  </Box>
                </Box>

                <Box
                  ref={sizeBlockRef}
                  id="sticky-cta-size-button"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  width={`${selectorWidth}px`}
                  height="50px"
                  p={isTablet ? '12px' : 'm'}
                  ml="s"
                  fontSize="14px"
                  lineHeight="19px"
                  letterSpacing="0.04em"
                  fontWeight={400}
                  border="1px solid #E6E6E6"
                  borderRadius={8}
                  onClick={() => onClick('size')}
                >
                  {LBL_SIZE}
                  <span style={{ fontWeight: 600, marginLeft: '8px' }}>{sizeSelected}</span>
                  <Box
                    ml="m"
                    transform={sizeBlockIsOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
                    transition="all .25s ease-in-out"
                  >
                    <Icon name="chevronDown" size={12} />
                  </Box>
                </Box>
                <Box
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  display="flex"
                  fontWeight={700}
                  ml="s"
                  p="m"
                  width="auto"
                  height="54px"
                  css={css`
                    font-size: 16px;

                    @media (min-width: ${breakpoints.M}px) {
                      font-size: 20px;
                    }
                  `}
                >
                  {formatPrice(quantity * storePrice)}
                </Box>
                <Box
                  width={isTablet ? '129px' : '229px'}
                  p="m"
                  height="50px"
                  alignItems="center"
                  justifyContent="center"
                  alignSelf="center"
                  display="flex"
                  ml="s"
                  bg="black"
                  borderRadius={8}
                >
                  <DynamicButton
                    id="sticky-cta-button-pdp-add-to-cart"
                    data-testid="sticky-cta-button-pdp-add-to-cart"
                    onClick={() => onCtaClick()}
                    fontWeight="500"
                    data={CTA_CONTENT_STICKY_CTA(isTablet ? true : false)}
                    feedback={isQuickAddOnPDP ? Status.Default : ctaState}
                    disabled={!status}
                    icon="shoppingCart"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Transition>
    </>
  );
};

export default StickySelector;
