import * as React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { Box } from '../../../design-system';
import { CmsText } from '../../cms/types';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  isDesktop: boolean;
  textColor: string;
  text_up?: CmsText;
  text_right?: CmsText;
  text?: CmsText;
};

export const BannerText = ({ textColor, text_up, text_right, text, isDesktop }: Props) => (
  <Box
    overflow="hidden"
    display="flex"
    flexDirection="column"
    justifyContent="center"
    color={textColor}
    mx={isDesktop ? 'initial' : 'm'}
  >
    {hasValidCmsText(text_up) && (
      <Box mb={isDesktop ? 's' : 'na'}>
        <PrismicRichText field={text_up} />
      </Box>
    )}
    {hasValidCmsText(text) && hasValidCmsText(text_right) ? (
      <Box
        display="grid"
        gridAutoFlow="column"
        gridAutoColumns="1fr auto 1fr"
        alignItems="center"
        my="s"
      >
        <Box mr={isDesktop ? 'xxxl' : 'l'}>
          <PrismicRichText field={text} />
        </Box>
        <Box height={isDesktop ? '160px' : '88px'} width="1px" bg={textColor} />
        <Box ml={isDesktop ? 'xxxl' : 'l'}>
          <PrismicRichText field={text_right} />
        </Box>
      </Box>
    ) : (
      hasValidCmsText(text) && <PrismicRichText field={text} />
    )}
  </Box>
);
