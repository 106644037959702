import React from 'react';
import { css } from '@emotion/core';

import { breakpoints } from './../../../design-system';
import { CmsBlock } from './CmsBlock';
import { DeliveryBlock } from './DeliveryBlock';
import { Notifications } from './Notifications';
import GiftBlock from './GiftBlock';
import { CartItemList } from './CartItemList';
import { MultiItemList } from './MultiItemList';
import { CartRecommendations } from './CartRecommendations';
import { CartNotification, CartStep } from '../types';
import { CmsCart } from '../../cms/types';
import { CartFaq } from './CartFaq';

type Props = {
  cmsContent: CmsCart;
  couponAccepted: boolean;
  notifications: CartNotification[];
  step: CartStep;
  hasGift: boolean;
  isDigital: boolean;
};

export const CartListningStep = ({
  cmsContent,
  couponAccepted,
  notifications,
  step,
  hasGift,
  isDigital,
}: Props) => {
  return (
    <>
      <CmsBlock cmsContent={cmsContent} />
      {isDigital ? null : <DeliveryBlock />}
      <Notifications couponAccepted={couponAccepted} notifications={notifications} step={step} />
      {hasGift ? <GiftBlock cmsContent={cmsContent} /> : null}
      <div
        css={css`
          display: block;
          @media (min-width: ${breakpoints.S}px) {
            display: none;
          }
        `}
      >
        <CartItemList />
      </div>
      <div
        css={css`
          display: none;
          @media (min-width: ${breakpoints.S}px) {
            display: block;
            width: auto;
            margin: 0 16px;
          }
          @media (min-width: ${breakpoints.L}px) {
            margin: 0 72px;
          }
        `}
      >
        <MultiItemList />
      </div>
      <div
        css={css`
          margin: 32px 16px;
          @media (min-width: ${breakpoints.L}px) {
            margin: 24px 72px;
          }
        `}
      >
        <CartRecommendations />
      </div>
      <div
        css={css`
          display: none;
          @media (min-width: ${breakpoints.M}px) {
            display: ${cmsContent?.cart_faq_is_active_desktop ? 'block' : 'none'};
            width: calc(100% - 16px);
          }
          @media (min-width: ${breakpoints.L}px) {
            width: calc(100% - 72px);
          }
        `}
      >
        <CartFaq cmsContent={cmsContent.body} />
      </div>
    </>
  );
};
