import React from 'react';
import { css } from '@emotion/core';
import { PrismicRichText } from '@prismicio/react';

import { breakpoints, colors } from '../../../design-system';
import { Icon } from '../../../design-system';
import { CmsText } from '../../cms/types';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  items?: {
    banner_with_pictos_icon_name?: string;
    banner_with_pictos_icon_text?: CmsText;
  }[];
  banner_with_pictos_is_active_desktop?: boolean;
  banner_with_pictos_is_active_mobile?: boolean;
  banner_with_pictos_icon_background_color?: string;
};

export const BannerWithPictos = ({
  items,
  banner_with_pictos_is_active_desktop,
  banner_with_pictos_is_active_mobile,
  banner_with_pictos_icon_background_color,
}: Props) => {
  const filteredItems = items?.filter(
    (item) => item?.banner_with_pictos_icon_name && item?.banner_with_pictos_icon_text?.[0]?.text
  );
  return !filteredItems?.length ? null : (
    <div
      id="catalog-banner-with-pictos"
      css={css`
        display: ${banner_with_pictos_is_active_mobile ? 'flex' : 'none'};
        justify-content: center;
        @media (min-width: ${breakpoints.S}px) {
          display: ${banner_with_pictos_is_active_desktop ? 'flex' : 'none'};
        }
      `}
    >
      <div
        css={css`
          overflow-x: auto;
          padding: 24px 16px;
          width: auto;
          display: flex;
          flex-direction: row;
          @media (min-width: ${breakpoints.S}px) {
            padding: 32px 0;
          }
        `}
      >
        {filteredItems.map((item, index) => {
          return (
            <div
              key={`catalog-banner-with-pictos-item-${index}`}
              css={css`
                display: flex;
                align-items: center;
                flex-direction: row;
                width: 280px;
                margin-right: ${filteredItems.length === index + 1 ? 0 : 16}px;
              `}
            >
              <div
                css={css`
                  height: 56px;
                  min-width: 56px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: ${banner_with_pictos_icon_background_color ?? colors.WHITE};
                `}
              >
                <Icon name={item.banner_with_pictos_icon_name ?? ''} size={32} />
              </div>
              {hasValidCmsText(item.banner_with_pictos_icon_text) && (
                <div
                  css={css`
                    align-self: center;
                    width: max-content;
                    padding-left: 16px;
                  `}
                >
                  <PrismicRichText field={item.banner_with_pictos_icon_text} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
