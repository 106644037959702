import React from 'react';

import { Box, Icon } from '../../../design-system';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { Product } from '../types';
import { CmsProduct } from '../../cms/types';
import { CsrTable } from './CsrTable';

type Props = {
  product: Product;
  cmsProduct: CmsProduct;
};

export const CsrBlock = ({ product, cmsProduct }: Props) => {
  const { isMobile, isTablet } = useMediaQueries();

  const [firstItemIsOpen, setFirstItemIsOpen] = React.useState(false);
  const [secondItemIsOpen, setSecondItemIsOpen] = React.useState(false);
  const [thirdItemIsOpen, setThirdItemIsOpen] = React.useState(false);
  const [fourthItemIsOpen, setFourthItemIsOpen] = React.useState(false);
  const [fifthItemIsOpen, setFifthItemIsOpen] = React.useState(false);

  const { contentRecycledMaterials, recyclability, rejectsMicrofibers, toxicity, traceability } =
    product ?? {};

  if (
    !contentRecycledMaterials &&
    !recyclability &&
    !rejectsMicrofibers &&
    !toxicity &&
    !traceability
  ) {
    return null;
  }

  const {
    csr_block_title,
    title_rejects_microfibers,
    product_rejects_microfibers_is_true,
    product_rejects_microfibers_is_false,
    title_recycled_materials,
    recycled_material_is_null,
    recycled_materials_intermediate_value,
    recycled_materials_is_full,
    title_recyclability,
    product_recyclability_is_true,
    product_recyclability_is_false,
    title_toxicity,
    product_toxicity_is_true,
    product_toxicity_is_false,
    title_traceability,
  } = cmsProduct ?? {};

  const displayPercentage = recycled_materials_intermediate_value?.replace(
    '$percentage',
    contentRecycledMaterials?.toString()
  );

  return (
    <Box
      id="csr-block"
      backgroundColor="#F9F9F9"
      display="flex"
      flexDirection="column"
      alignItems="center"
      py="32px"
      my={isMobile ? '16px' : 'na'}
      mx={isMobile ? '0' : '24px'}
      borderRadius="8px"
    >
      <Box
        id="csr-block-title"
        textAlign="center"
        fontSize="20px"
        lineHeight="22px"
        fontWeight="700"
        width={isMobile ? '90%' : isTablet ? '75%' : '800px'}
        mb="24px"
      >
        {csr_block_title && csr_block_title}
      </Box>
      <Box
        id="csr-block-items"
        width={isMobile ? '100%' : isTablet ? '90%' : '976px'}
        display="flex"
        flexDirection="column"
      >
        {((contentRecycledMaterials === 0 &&
          recycled_material_is_null &&
          recycled_material_is_null !== '') ||
          (contentRecycledMaterials === 100 &&
            recycled_materials_is_full &&
            recycled_materials_is_full !== '') ||
          (contentRecycledMaterials > 0 &&
            contentRecycledMaterials < 100 &&
            recycled_materials_intermediate_value &&
            recycled_materials_intermediate_value !== '')) && (
          <Box
            id="csr-block-item-0"
            display="flex"
            flexDirection="row"
            width="100%"
            p="16px"
            borderBottom="solid 1px #E6E6E6"
            onClick={() => setFirstItemIsOpen(!firstItemIsOpen)}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="calc(100% - 32px)"
              fontSize="16px"
              lineHeight="110%"
              fontWeight="700"
            >
              <Box fontSize="16px" lineHeight="110%" fontWeight="700">
                {title_recycled_materials && title_recycled_materials}
              </Box>
              {firstItemIsOpen && (
                <Box mt="16px" fontSize="14px" lineHeight="18px">
                  {contentRecycledMaterials > 0 && contentRecycledMaterials < 100
                    ? displayPercentage
                    : contentRecycledMaterials === 100
                      ? recycled_materials_is_full
                      : recycled_material_is_null}
                </Box>
              )}
            </Box>
            <Box width="32px" display="flex" justifyContent="flex-end">
              <Icon name={firstItemIsOpen ? 'minus' : 'plus'} size={isMobile ? 12 : 16} />
            </Box>
          </Box>
        )}

        {Boolean(traceability) && typeof traceability === 'string' && (
          <Box
            id="csr-block-item-1"
            display="flex"
            flexDirection="row"
            width="100%"
            p="16px"
            borderBottom={secondItemIsOpen ? 'na' : 'solid 1px #E6E6E6'}
            onClick={() => setSecondItemIsOpen(!secondItemIsOpen)}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="calc(100% - 32px)"
              fontSize="16px"
              lineHeight="110%"
              fontWeight="700"
            >
              <Box fontSize="16px" lineHeight="110%" fontWeight="700">
                {title_traceability && title_traceability}
              </Box>
            </Box>
            <Box width="32px" display="flex" justifyContent="flex-end">
              <Icon name={secondItemIsOpen ? 'minus' : 'plus'} size={isMobile ? 12 : 16} />
            </Box>
          </Box>
        )}

        {secondItemIsOpen && isMobile && traceability && (
          <Box width="calc(100vw - 32px)">
            <CsrTable cmsProduct={cmsProduct} traceability={traceability} />
          </Box>
        )}
        {secondItemIsOpen && !isMobile && traceability && (
          <Box width="100%">
            <CsrTable cmsProduct={cmsProduct} traceability={traceability} />
          </Box>
        )}
        {secondItemIsOpen && <Box height="1px" borderTop="solid 1px #E6E6E6" mt="24px" />}
        {((recyclability === true &&
          product_recyclability_is_true &&
          product_recyclability_is_true !== '') ||
          (recyclability === false &&
            product_recyclability_is_false &&
            product_recyclability_is_false !== '')) && (
          <Box
            id="csr-block-item-2"
            display="flex"
            flexDirection="row"
            width="100%"
            p="16px"
            borderBottom="solid 1px #E6E6E6"
            onClick={() => setThirdItemIsOpen(!thirdItemIsOpen)}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="calc(100% - 32px)"
              fontSize="16px"
              lineHeight="110%"
              fontWeight="700"
            >
              <Box fontSize="16px" lineHeight="110%" fontWeight="700">
                {title_recyclability && title_recyclability}
              </Box>
              {thirdItemIsOpen && (
                <Box mt="16px" fontSize="14px" lineHeight="18px">
                  {recyclability ? product_recyclability_is_true : product_recyclability_is_false}
                </Box>
              )}
            </Box>
            <Box width="32px" display="flex" justifyContent="flex-end">
              <Icon name={thirdItemIsOpen ? 'minus' : 'plus'} size={isMobile ? 12 : 16} />
            </Box>
          </Box>
        )}

        {((rejectsMicrofibers === true &&
          product_rejects_microfibers_is_true &&
          product_rejects_microfibers_is_true !== '') ||
          (rejectsMicrofibers === false &&
            product_rejects_microfibers_is_false &&
            product_rejects_microfibers_is_false !== '')) && (
          <Box
            id="csr-block-item-3"
            display="flex"
            flexDirection="row"
            width="100%"
            p="16px"
            borderBottom="solid 1px #E6E6E6"
            onClick={() => setFourthItemIsOpen(!fourthItemIsOpen)}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="calc(100% - 32px)"
              fontSize="16px"
              lineHeight="110%"
              fontWeight="700"
            >
              <Box fontSize="16px" lineHeight="110%" fontWeight="700">
                {title_rejects_microfibers && title_rejects_microfibers}
              </Box>
              {fourthItemIsOpen && (
                <Box mt="16px" fontSize="14px" lineHeight="18px">
                  {rejectsMicrofibers
                    ? product_rejects_microfibers_is_true
                    : product_rejects_microfibers_is_false}
                </Box>
              )}
            </Box>
            <Box width="32px" display="flex" justifyContent="flex-end">
              <Icon name={fourthItemIsOpen ? 'minus' : 'plus'} size={isMobile ? 12 : 16} />
            </Box>
          </Box>
        )}

        {((toxicity === true && product_toxicity_is_true && product_toxicity_is_true !== '') ||
          (toxicity === false &&
            product_toxicity_is_false &&
            product_toxicity_is_false !== '')) && (
          <Box
            id="csr-block-item-4"
            display="flex"
            flexDirection="row"
            width="100%"
            p="16px"
            borderBottom="solid 1px #E6E6E6"
            onClick={() => setFifthItemIsOpen(!fifthItemIsOpen)}
          >
            <Box
              display="flex"
              flexDirection="column"
              width="calc(100% - 32px)"
              fontSize="16px"
              lineHeight="110%"
              fontWeight="700"
            >
              <Box fontSize="16px" lineHeight="110%" fontWeight="700">
                {title_toxicity && title_toxicity}
              </Box>
              {fifthItemIsOpen && (
                <Box mt="16px" fontSize="14px" lineHeight="18px">
                  {toxicity ? product_toxicity_is_true : product_toxicity_is_false}
                </Box>
              )}
            </Box>
            <Box width="32px" display="flex" justifyContent="flex-end">
              <Icon name={fifthItemIsOpen ? 'minus' : 'plus'} size={isMobile ? 12 : 16} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CsrBlock;
