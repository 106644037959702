import React from 'react';
import { Link } from 'react-router-dom';
import { PrismicRichText } from '@prismicio/react';
import { css } from '@emotion/core';

import { Box, Text, Divider, Icon, Button } from '../../../design-system';
import { ColorText, ToolTip } from '../../common/components/Custom';
import { CmsOfferItem } from '../types';
import { offers as locale } from '../locale';
import { copyToClipboard, formatDate, getDiffHour } from '../utils';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { hasValidCmsText } from '../../home/utils';

type Props = {
  index: number;
  item: CmsOfferItem;
};

const COLOR_EXPIRING = '#FFA500';
const COLOR_EXPIRED = '#FF0000';

export const OfferItem = ({ item, index }: Props) => {
  const { isMobile } = useMediaQueries();

  const [hasCopied, setHasCopied] = React.useState(false);

  const {
    background_color,
    conditions,
    coupon_code,
    cta_link,
    exclusions,
    image,
    name,
    text_color,
    tooltip_message,
    tooltip_message_expired,
    valid_from,
    valid_to,
  } = item ?? {};

  const handleCopy = async () => {
    if (coupon_code) {
      const response = await copyToClipboard(coupon_code);
      if (response) {
        setHasCopied(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        setHasCopied(false);
      }
    }
  };

  const diffHour = valid_to ? getDiffHour(valid_to) : 0;

  const isExpiring = diffHour >= 0 && diffHour <= 48;
  const isExpired = diffHour < 0;
  const showClock = isExpiring || isExpired;
  const iconColor = isExpiring ? COLOR_EXPIRING : COLOR_EXPIRED;
  const tooltipMessage = isExpiring
    ? tooltip_message || locale.EXPIRING_PROMOTION
    : tooltip_message_expired || locale.EXPIRED_PROMOTION;

  return isMobile ? (
    <Box display="grid" gridGap="m" id={`box-offer-item-${index}`}>
      {index !== 0 && <Divider color="BLACK" />}
      <Box
        display="grid"
        gridGap="m"
        gridAutoFlow="column"
        justifyContent={['left', 'left', 'center']}
      >
        <Box
          background={`url(${image?.mobile?.url ?? ''}) no-repeat center / contain`}
          borderRadius="50%"
          size="h"
        />
        <Box
          display="grid"
          gridRowGap="s"
          gridColumnGap="m"
          alignItems="center"
          justifyContent="center"
          gridTemplateColumns="40px 1fr"
        >
          <Text color="GREY" preset="caption">
            {locale.LBL_NAME}
          </Text>
          <ColorText
            preset="caption"
            fontWeight="bold"
            bg={background_color}
            cmsColor={text_color}
            py="xs"
            px="s"
            justifySelf="left"
          >
            {name}
          </ColorText>
          <Text color="GREY" preset="caption">
            {locale.LBL_VALIDITY}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="xs"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="caption" fontWeight="bold">
              {`${valid_from ? formatDate(valid_from) : ''} - ${
                valid_to ? formatDate(valid_to) : ''
              }`}
            </Text>
            {showClock && (
              <Box mb="xs">
                <Icon name="clock" color={iconColor} />
              </Box>
            )}
          </Box>
          <Text color="GREY" preset="caption">
            {locale.LBL_COUPON_CODE}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="m"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="caption" fontWeight="bold">
              {coupon_code ?? locale.TXT_NO_COUPON_CODE}
            </Text>
            {coupon_code && (
              <button
                className="anchor-animated"
                data-testid={`cta-copy-offer-item-${index}`}
                id={`cta-copy-offer-item-${index}`}
                type="button"
                onClick={handleCopy}
                css={css`
                  font-size: 1.2rem;
                `}
              >
                {hasCopied ? locale.CTA_COPIED : locale.CTA_COPY}
              </button>
            )}
          </Box>
        </Box>
      </Box>
      {hasValidCmsText(conditions) && (
        <Box display="grid" gridGap="s">
          <Text color="GREY" preset="caption">
            {locale.LBL_CONDITIONS}
          </Text>
          <PrismicRichText field={conditions} />
        </Box>
      )}
      {hasValidCmsText(exclusions) && (
        <Box display="grid" gridGap="s">
          <Text color="GREY" preset="caption">
            {locale.LBL_EXCLUSIONS}
          </Text>
          <PrismicRichText field={exclusions} />
        </Box>
      )}
      <Box mx="auto" my="s">
        <Link
          className="anchor-animated"
          data-testid={`link-offer-item-${index}`}
          id={`link-offer-item-${index}`}
          to={getFormatedLinkForReactRouterLink(cta_link?.url)}
          target={cta_link?.target ?? '_self'}
          css={css`
            font-size: 1.6rem;
          `}
        >
          {locale.CTA_LINK}
        </Link>
      </Box>
    </Box>
  ) : (
    <Box display="grid" gridGap="xxl" id={`box-offer-item-${index}`}>
      {index !== 0 && <Divider />}
      <Box display="grid" gridAutoFlow="column" gridGap="xxl">
        <Box
          backgroundImage={[
            `url(${image?.mobile?.url ?? ''})`,
            `url(${image?.mobile?.url ?? ''})`,
            `url(${image?.desktop?.url ?? ''})`,
          ]}
          backgroundPosition="center"
          backgroundSize="contain"
          backgroundRepeat="no-repeat"
          width={['100px', '100px', '400px']}
          height={['100px', '100px', '360px']}
          borderRadius={['50%', '50%', 'initial']}
        />
        <Box
          display="grid"
          gridRowGap="m"
          gridColumnGap="l"
          alignItems="center"
          justifyContent="center"
          gridTemplateColumns="80px 1fr"
        >
          <Text color="GREY" preset="subheading" py="xs">
            {locale.LBL_NAME}
          </Text>
          <ColorText
            preset="heading"
            fontWeight="bold"
            bg={background_color}
            cmsColor={text_color}
            py="xs"
            px="s"
            justifySelf="left"
          >
            {name}
          </ColorText>
          <Text color="GREY" preset="subheading">
            {locale.LBL_VALIDITY}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="xs"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="subheading" fontWeight="bold">
              {`${valid_from ? formatDate(valid_from) : ''} - ${
                valid_to ? formatDate(valid_to) : ''
              }`}
            </Text>
            {showClock && (
              <Box mb="s">
                <ToolTip>
                  <Icon name="clock" color={iconColor} />
                  <Box>
                    <Text preset="caption" withEllipsis>
                      {tooltipMessage}
                    </Text>
                  </Box>
                </ToolTip>
              </Box>
            )}
          </Box>
          <Text color="GREY" preset="subheading">
            {locale.LBL_COUPON_CODE}
          </Text>
          <Box
            display="grid"
            gridAutoFlow="column"
            gridGap="m"
            alignItems="center"
            justifyContent="left"
          >
            <Text color="GREY" preset="subheading" fontWeight="bold">
              {coupon_code ?? locale.TXT_NO_COUPON_CODE}
            </Text>
            {coupon_code && (
              <ToolTip>
                <button
                  className="anchor-animated"
                  data-testid={`cta-copy-offer-item-${index}`}
                  id={`cta-copy-offer-item-${index}`}
                  type="button"
                  onClick={handleCopy}
                  css={css`
                    font-size: 1.2rem;
                  `}
                >
                  {hasCopied ? locale.CTA_COPIED : locale.CTA_COPY}
                </button>
                <Box>
                  <Text preset="caption" withEllipsis>
                    {locale.TOOLTIP_COPY}
                  </Text>
                </Box>
              </ToolTip>
            )}
          </Box>
          <Text
            color="GREY"
            preset="subheading"
            alignSelf="start"
            marginBlockStart="1rem"
            marginBlockEnd="1rem"
          >
            {locale.LBL_CONDITIONS}
          </Text>
          {hasValidCmsText(conditions) && <PrismicRichText field={conditions} />}
          <Text
            color="GREY"
            preset="subheading"
            alignSelf="start"
            marginBlockStart="1rem"
            marginBlockEnd="1rem"
          >
            {locale.LBL_EXCLUSIONS}
          </Text>
          {hasValidCmsText(exclusions) && <PrismicRichText field={exclusions} />}
          <Box />
          <Box mx="auto" mt="m" width="180px">
            <Link
              data-testid={`link-offer-item-${index}`}
              id={`link-offer-item-${index}`}
              to={getFormatedLinkForReactRouterLink(cta_link?.url)}
              target={cta_link?.target ?? '_self'}
              css={css`
                text-decoration: none;
              `}
            >
              <Button id={`btn-offer-item-${index}`} preset="subtle">
                {locale.CTA_LINK}
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
