import React, { useEffect, useState } from 'react';
import { useRange } from 'react-instantsearch';
import Slider from 'rc-slider';

import { Box, Text } from '../../../design-system';

type Props = { attribute: string };

export const PriceFilter: React.FC<Props> = ({ attribute }) => {
  const { start, range, refine } = useRange({ attribute });
  const [currentRange, setCurrentRange] = useState<number[]>([
    start[0] !== -Infinity && typeof start[0] === 'number' ? start[0] : (range.min ?? 0),
    start[1] !== Infinity && typeof start[1] === 'number' ? start[1] : (range.max ?? 0),
  ]);

  useEffect(() => {
    setCurrentRange([
      start[0] !== -Infinity && typeof start[0] === 'number' ? start[0] : (range.min ?? 0),
      start[1] !== Infinity && typeof start[1] === 'number' ? start[1] : (range.max ?? 0),
    ]);
  }, [range]);

  if (range.min === range.max) {
    return null;
  }

  return (
    <Box p="m" mb="m">
      <Box display="flex" justifyContent="center" my="m">
        <Text id="price-range">{currentRange.join(' - ')} EUR</Text>
      </Box>
      {range.min !== range.max && (
        <Slider
          range
          min={range.min}
          max={range.max}
          defaultValue={currentRange}
          value={currentRange}
          onChange={(values: number | number[]) => Array.isArray(values) && setCurrentRange(values)}
          onChangeComplete={(values: number | number[]) =>
            Array.isArray(values) && values.length === 2 && refine([values[0], values[1]])
          }
        />
      )}
    </Box>
  );
};
