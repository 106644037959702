import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { PrismicRichText } from '@prismicio/react';

import { Helmet } from 'react-helmet-async';
import { css } from '@emotion/core';

import {
  Breadcrumbs,
  Icon,
  Field,
  MultilineField,
  Button,
  RadioGroup,
  DynamicButton,
  DynamicButtonStatus,
  Selector,
  breakpoints,
  colors,
} from '../../../design-system';
import { CmsCustomTypes, CmsContact, CmsText } from '../types';
import withForm from '../../form';
import { Forms, FieldType, FieldCallback, FormContact, FieldRefs } from '../../form/types';
import { WithFormActions } from '../../form/hocs/withForm';
import withCms from '../hocs/withCms';
import { loadUserDetails } from '../../account/actions';
import {
  validate,
  populateForm,
  isPhoneOnChangeValid,
  formatedPhoneValue,
  validateName,
  addSpacesForPhoneNumber,
  removeSpaces,
} from '../../form/utils';
import { getBFFData, Mutations } from '../../api';
import { ERR_NOT_SAVED } from '../../form/locale';
import {
  LBL_CONTACT,
  LBL_CONTACT_ERROR,
  LBL_CONTACT_FEEDBACK,
  LBL_CONTINUE_SHOPPING,
  LBL_HELP_ADVICE,
  LBL_MESSAGE_SEND_SUCCESS,
  LBL_OOOPS,
  LBL_RELOAD,
} from '../locale';
import { countryOptions } from '../../account/utils';
import locale from '../../account/locale';
import { WithCmsActions } from '../hocs/withCms';
import { RootState } from '../../../store/rootReducer';
import { scrollToTop } from '../../common/utils';
import { hasValidCmsText } from '../../home/utils';

const CTA_CONTENT: [string, string, string] = ['Envoyer votre question', '', ''];

type Props = {
  cmsContent: CmsContact;
  form: FormContact;
  history: {
    push: (path: string) => void;
  };
  mini?: boolean;
} & WithFormActions &
  WithCmsActions;

export const Contact = ({
  cmsContent,
  loadCmsContent,
  setFormValidation,
  setFormValues,
  setFeedback,
  setCtaState,
  form,
  history,
  mini,
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.account) ?? {};

  React.useEffect(() => {
    if (user) {
      dispatch(loadUserDetails() as unknown as UnknownAction);
      const userInfos = {
        email: user.email ?? '',
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        phone: user.phone ?? '',
        prefix: user.prefix ?? '',
      };
      for (const property in userInfos) {
        dispatch(
          setFormValues({
            form: Forms.contact,
            values: { [property]: userInfos[property] },
          }) as unknown as UnknownAction
        );
      }
    }
  }, [user?.email, user?.firstName, user?.lastName, user?.phone, user?.prefix]);

  const refs: FieldRefs = {};
  Object.keys(form.values).forEach((key) => {
    refs[key] = React.useRef(null);
  });

  const [step, setStep] = React.useState(1);
  const [subject, setSubject] = React.useState('');

  const title = 'Contactez-nous';
  const subtitle = cmsContent?.subtitle?.[0]?.text ?? '';
  const description = cmsContent?.description;
  const info = cmsContent?.info;
  const link: {
    icon: string;
    text: CmsText;
    note: CmsText;
  }[] = cmsContent?.link ?? [];

  React.useEffect(() => {
    if (!subtitle) {
      loadCmsContent(CmsCustomTypes.contact);
    }
  }, []);

  React.useEffect(() => {
    if (form.focus) {
      const node = refs[form.focus].current;
      if (node) {
        node.focus();
      }
    }
  }, [form.focus]);

  React.useEffect(() => {
    setFormValues({ form: Forms.contact, values: { theme: '' } });
    setFormValidation({ form: Forms.contact, values: { theme: '' } });
  }, [subject]);

  React.useEffect(() => {
    if (step === 2) {
      scrollToTop();
    }
  }, [step]);

  const options = (cmsContent?.body ?? []).map((subject) => ({
    value: subject.primary.subject,
    label: subject.primary.subject,
    options: (subject.items ?? []).map((option) => ({
      value: option.option,
      label: option.option,
    })),
  }));

  const validateField = (key: string, value: FieldType): string =>
    validate({
      value: String(value),
      key,
      isRequired: !['orderNumber', 'address', 'postal', 'city', 'country', 'phone'].includes(key),
    });

  const validateForm = (): boolean => {
    const { values } = form;
    const newErrMsgs = form.validation;
    let fieldToFocus = '';
    const errMsgs = Object.keys(values).map((key) => {
      const errMsg = validateField(key, values[key]);
      newErrMsgs[key] = errMsg;
      setFormValidation({ form: Forms.contact, values: { [key]: errMsg } });
      if (errMsg && !fieldToFocus) {
        fieldToFocus = key;
      }
      return errMsg;
    });
    if (fieldToFocus) {
      const node = refs[fieldToFocus].current;
      if (node) {
        node.focus();
      }
    }
    return errMsgs.every((errMsg) => !errMsg);
  };

  const handleFieldChange = ({ key, value }: FieldCallback) => {
    let newValue = value;
    if (validateName(key, value)) {
      return null;
    }

    if (
      key === 'phone' &&
      typeof value === 'string' &&
      !isPhoneOnChangeValid({ value, dispatch, formType: Forms.contact, form })
    ) {
      return null;
    }

    newValue = key === 'phone' && typeof value === 'string' ? formatedPhoneValue(value) : value;
    if (form.validation[key] && key !== 'phone') {
      setFormValidation({ form: Forms.contact, values: { [key]: validateField(key, newValue) } });
    }

    return setFormValues({ form: Forms.contact, values: { [key]: newValue } });
  };

  const handleFieldBlur = ({ key, value }: FieldCallback) => {
    const errMsg = validateField(key, value);
    setFormValidation({ form: Forms.contact, values: { [key]: errMsg } });
  };

  const getField = ({
    key,
    label,
    autoComplete,
    type,
  }: {
    key: string;
    label: string;
    autoComplete?: string;
    type?: string;
  }) => (
    <div
      css={css`
        grid-area: ${key};
      `}
    >
      <Field
        id={`${Forms.contact}-${key}`}
        onChange={(value: string) =>
          handleFieldChange({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        onBlur={(value: string) =>
          handleFieldBlur({ key, value: key === 'phone' ? removeSpaces(value) : value })
        }
        value={key === 'phone' ? addSpacesForPhoneNumber(form.values[key]) : form.values[key]}
        errMsg={form.validation[key]}
        label={label}
        ref={refs[key]}
        autoComplete={autoComplete}
        type={type}
      />
    </div>
  );

  const reset = () => {
    setStep(1);
    const values = populateForm({}, Object.keys(form.values));
    setFormValidation({ form: Forms.contact, values });
    setFormValues({ form: Forms.contact, values });
    setFeedback({
      form: Forms.contact,
      ok: true,
      message: '',
      isDirty: false,
    });
  };

  const sendForm = async () => {
    const response = await getBFFData(Mutations.contact, {
      form: {
        ...form.values,
        orderNumber: form.values.orderNumber || undefined,
        country: form.values.country || undefined,
      },
    });
    return response.data.contactUs?.success;
  };

  const handleSubmit = async () => {
    const isValidated = validateForm();
    if (isValidated) {
      setCtaState({ form: Forms.contact, ctaState: DynamicButtonStatus.Loading });
      const isOk = await sendForm();
      if (isOk) {
        reset();
      } else {
        setFeedback({
          form: Forms.contact,
          ok: false,
          message: ERR_NOT_SAVED,
          isDirty: false,
        });
      }
      setStep(2);
      setCtaState({ form: Forms.contact, ctaState: DynamicButtonStatus.Default });
    }
  };

  const handleGoHome = () => {
    reset();
    history.push('/');
  };

  const handleReload = () => {
    reset();
  };

  const metaTitle = 'Contactez-nous | Darjeeling';

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
      </Helmet>
      <div
        css={css`
          margin: 16px 0;
          @media (min-width: ${breakpoints.S}px) {
            margin: 16px 32px;
          }
        `}
      >
        {!mini && (
          <div
            css={css`
              display: none;
              @media (min-width: ${breakpoints.S}px) {
                display: block;
              }
            `}
          >
            <Breadcrumbs items={[{ label: title, value: '#', readonly: true }]} />
          </div>
        )}

        <div
          css={css`
            text-align: center;
            @media (min-width: ${breakpoints.S}px) {
              margin: 24px 0;
            }
          `}
        >
          <h2
            css={css`
              font-size: 2rem;
              line-height: 2.4rem;
              margin-block-start: 0;
              margin-block-end: 0;
            `}
          >
            {title}
          </h2>
        </div>
        {hasValidCmsText(description) && (
          <div
            css={css`
              display: none;
              @media (min-width: ${breakpoints.S}px) {
                display: block;
                margin: 24px auto;
                max-width: 640px;
                text-align: center;
              }
            `}
          >
            <div
              css={css`
                font-size: 1.6rem;
                line-height: 2rem;
              `}
            >
              <PrismicRichText field={description} />
            </div>
          </div>
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            margin: 16px auto;
            justify-content: center;
            @media (min-width: ${breakpoints.S}px) {
              flex-direction: row;
              margin: 32px auto;
            }
          `}
        >
          {!mini && (
            <div
              css={css`
                @media (min-width: ${breakpoints.S}px) {
                  width: 344px;
                  margin-right: 32px;
                }
              `}
            >
              {subtitle && (
                <div
                  css={css`
                    background: ${colors.BACKGROUND};
                    padding: 24px;
                  `}
                >
                  <div
                    css={css`
                      margin-bottom: 16px;
                      text-align: center;
                      padding: 0 32px;
                      @media (min-width: ${breakpoints.S}px) {
                        text-align: left;
                        padding: 0;
                      }
                    `}
                  >
                    <p
                      css={css`
                        font-size: 1.6rem;
                        line-height: 2rem;
                        margin-block-start: 0;
                        margin-block-end: 0;
                      `}
                    >
                      {subtitle}
                    </p>
                  </div>
                  {(link ?? []).map((item, index) => (
                    <div key={index}>
                      <div
                        css={css`
                          display: flex;
                          align-items: start;
                        `}
                      >
                        <div
                          css={css`
                            margin: 16px;
                          `}
                        >
                          <Icon name={item.icon} />
                        </div>
                        {hasValidCmsText(item.text) && (
                          <div
                            css={css`
                              font-size: 1.4rem;
                              line-height: 1.8rem;
                            `}
                          >
                            <PrismicRichText field={item.text} />
                          </div>
                        )}
                        {hasValidCmsText(item.note) && (
                          <div
                            css={css`
                              font-size: 1.2rem;
                              line-height: 1.6rem;
                              color: ${colors.GREY};
                            `}
                          >
                            <PrismicRichText field={item.note} />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {hasValidCmsText(info) && (
                <div
                  css={css`
                    display: none;
                    @media (min-width: ${breakpoints.S}px) {
                      display: block;
                      margin: 24px 0;
                    }
                  `}
                >
                  <div
                    css={css`
                      font-size: 1.2rem;
                      line-height: 1.6rem;
                      color: ${colors.GREY};
                    `}
                  >
                    <PrismicRichText field={info} />
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            css={css`
              margin: 24px 16px;
              @media (min-width: ${breakpoints.S}px) {
                margin: 24px 32px;
                width: 576px;
              }
            `}
          >
            {step === 1 ? (
              <div
                css={css`
                  display: grid;
                  grid-column-gap: 8px;
                  grid-row-gap: 24px;
                  grid-template-areas: ${`'subject' 'theme' 'title' 'prefix' 'firstName' 'lastName' 'email' 'subtitle' 'orderNumber' 'address' 'postal' 'city' 'country' 'phone' 'message' 'submitbutton'`};
                  @media (min-width: ${breakpoints.S}px) {
                    grid-template-areas: ${`'subject subject'  'theme theme'  'title title' 'prefix prefix' 'firstName lastName' 'email email' 'subtitle subtitle' 'orderNumber orderNumber' 'address address' 'postal city' 'country phone' 'message message' 'submitbutton submitbutton'`};
                  }
                `}
              >
                <div
                  css={css`
                    grid-area: ${`title`};
                    text-align: center;
                  `}
                >
                  <p
                    css={css`
                      font-size: 1.6rem;
                      line-height: 2rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_CONTACT}
                  </p>
                </div>
                <div
                  css={css`
                    grid-area: ${`subject`};
                  `}
                >
                  <Selector
                    id={`select-${Forms.contact}-subject`}
                    onChange={(value: string) => {
                      setSubject(value);
                      handleFieldChange({
                        key: 'subject',
                        value,
                      });
                    }}
                    value={form.values.subject}
                    errMsg={form.validation.subject}
                    placeholder="Sujet*"
                    options={options}
                    ref={refs.subject}
                  />
                </div>
                <div
                  css={css`
                    grid-area: ${`theme`};
                  `}
                >
                  <Selector
                    id={`select-${Forms.contact}-theme`}
                    onChange={(value: string) => handleFieldChange({ key: 'theme', value })}
                    value={form.values.theme}
                    errMsg={form.validation.theme}
                    placeholder="Précision*"
                    options={options.find((option) => option.value === subject)?.options ?? []}
                    ref={refs.theme}
                  />
                </div>
                <div
                  css={css`
                    grid-area: ${`prefix`};
                    text-align: center;
                    @media (min-width: ${breakpoints.S}px) {
                      text-align: left;
                    }
                  `}
                >
                  <RadioGroup
                    id="radio-contact-prefix"
                    ref={refs.prefix}
                    inline
                    checked={form.values.prefix}
                    errMsg={form.validation.prefix}
                    onChange={(value) => handleFieldChange({ key: 'prefix', value: String(value) })}
                    options={[
                      {
                        id: 'Madame',
                        value: 'Madame',
                      },
                      {
                        id: 'Monsieur',
                        value: 'Monsieur',
                      },
                    ]}
                  />
                </div>
                {getField({ key: 'firstName', label: 'Prénom*', autoComplete: 'given-name' })}
                {getField({ key: 'lastName', label: 'Nom*', autoComplete: 'family-name' })}
                {getField({ key: 'email', label: 'Email*', autoComplete: 'email' })}

                <div
                  css={css`
                    grid-area: ${`subtitle`};
                    text-align: center;
                  `}
                >
                  <p
                    css={css`
                      font-size: 1.6rem;
                      line-height: 2rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_HELP_ADVICE}
                  </p>
                </div>
                {getField({ key: 'orderNumber', label: 'Votre numéro de commande' })}
                {getField({
                  key: 'address',
                  label: 'Votre adresse',
                  autoComplete: 'street-address',
                })}
                {getField({ key: 'postal', label: 'Code postal', autoComplete: 'postal-code' })}
                {getField({ key: 'city', label: 'Ville', autoComplete: 'address-level2' })}
                <div
                  css={css`
                    grid-area: ${`country`};
                  `}
                >
                  <Selector
                    id={`${Forms.contact}-country`}
                    onChange={(value: string) => handleFieldChange({ key: 'country', value })}
                    value={form.values.country}
                    options={countryOptions}
                    note={locale.NOTE_COUNTRY}
                  />
                </div>
                {getField({ key: 'phone', label: 'Téléphone', autoComplete: 'tel', type: 'tel' })}
                <div
                  css={css`
                    grid-area: ${`message`};
                  `}
                >
                  <MultilineField
                    id={`${Forms.contact}-message`}
                    onChange={(value: string) => handleFieldChange({ key: 'message', value })}
                    onBlur={(value: string) => handleFieldBlur({ key: 'message', value })}
                    value={form.values.message}
                    label="Votre question*"
                    errMsg={form.validation.message}
                    maxLength={2000}
                    note={`${form.values.message.length}/2000 caractères maximum`}
                    ref={refs.message}
                  />
                </div>
                <div
                  css={css`
                    grid-area: ${`submitbutton`};
                  `}
                >
                  <div
                    css={css`
                      width: 100%;
                      @media (min-width: ${breakpoints.S}px) {
                        width: 256px;
                      }
                    `}
                  >
                    <DynamicButton
                      id="btn-submit-contact"
                      onClick={handleSubmit}
                      feedback={form.ctaState}
                      data={CTA_CONTENT}
                    />
                  </div>
                </div>
              </div>
            ) : form.feedback.ok ? (
              <div
                css={css`
                  text-align: center;
                `}
              >
                <div
                  css={css`
                    display: grid;
                    grid-gap: 24px;
                  `}
                >
                  <p
                    css={css`
                      font-size: 1.6rem;
                      line-height: 2rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_MESSAGE_SEND_SUCCESS}
                  </p>
                  <p
                    css={css`
                      font-size: 1.4rem;
                      line-height: 1.8rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_CONTACT_FEEDBACK}
                  </p>
                  <div
                    css={css`
                      width: 240px;
                      margin: auto;
                    `}
                  >
                    <Button id="btn-contact-go-back" onClick={handleGoHome}>
                      {LBL_CONTINUE_SHOPPING}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                css={css`
                  text-align: center;
                `}
              >
                <div
                  css={css`
                    display: grid;
                    grid-gap: 24px;
                  `}
                >
                  <p
                    css={css`
                      font-size: 1.6rem;
                      line-height: 2rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_OOOPS}
                  </p>
                  <p
                    css={css`
                      font-size: 1.4rem;
                      line-height: 1.8rem;
                      margin-block-start: 0;
                      margin-block-end: 0;
                    `}
                  >
                    {LBL_CONTACT_ERROR}
                  </p>
                  <div
                    css={css`
                      width: 240px;
                      margin: auto;
                    `}
                  >
                    <Button id="btn-contact-reload" onClick={handleReload}>
                      {LBL_RELOAD}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default withForm(withCms(Contact, CmsCustomTypes.contact), Forms.contact);
