import React from 'react';
import styled from '@emotion/styled';
import { UseRefinementListProps, useRefinementList } from 'react-instantsearch';

import { colors, breakpoints } from '../../../design-system';
import { removeDiacritics } from '../../store-locator/utils';
import { removeSpaces } from '../../form/utils';

const StyledStandardWithIconFilter = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 32px;

  @media (min-width: ${breakpoints.M}px) {
    display: grid;
    align-items: start;
    grid-column-gap: 16px;
    grid-row-gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(80px, 80px));
    justify-content: space-between;
  }

  .filter-item {
    border: unset;
    background: unset;
    outline: none;
    padding: 0;
    margin-top: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    color: ${colors.BLACK};
    @media (min-width: ${breakpoints.M}px) {
      margin-top: unset;
    }
  }
  @media (hover: hover) {
    .filter-item:not(.filter-item--is-refined):hover,
    .filter-item:not(.filter-item--is-refined):focus-visible {
      .filter-item__text {
        font-weight: bold;
      }
      .filter-item__image {
        border: 1px solid ${colors.LIGHT};
        border-radius: 8px;
      }
    }
  }
  .filter-item--is-refined {
    .filter-item__text {
      font-weight: bold;
    }
    .filter-item__image {
      border: 1px solid ${colors.BLACK};
      border-radius: 8px;
    }
  }
  .filter-item__text {
    font-size: 14px;
    margin: 8px 0;
    font-weight: 400;
    transition: all 0.3s;
  }
  .filter-item__image {
    width: 70px;
    height: 70px;
    margin: auto;
    background: ${colors.LIGHT};
    border: 1px solid transparent;
    transition: all 0.3s;
  }

  // CSS For 'Maintien' / 'Support' Filter
  .filter-item__image--leger {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienLeger.svg') no-repeat center;
  }
  .filter-item__image--normal {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienNormal.svg') no-repeat center;
  }
  .filter-item__image--renforce {
    background: url('https://cdn.new.darjeeling.fr/icons/filterMaintienRenforce.svg') no-repeat
      center;
  }

  // CSS For 'Hauteur de taille' / 'Waist' Filter
  .filter-item__image--haute {
    background: url('https://cdn.new.darjeeling.fr/icons/filterWaistHaute.svg') no-repeat center;
  }
  .filter-item__image--standard {
    background: url('https://cdn.new.darjeeling.fr/icons/filterWaistStandard.svg') no-repeat center;
  }
  .filter-item__image--basse {
    background: url('https://cdn.new.darjeeling.fr/icons/filterWaistBasse.svg') no-repeat center;
  }

  // CSS For 'Décolleté' / 'Neckline' Filter
  .filter-item__image--droit {
    background: url('https://cdn.new.darjeeling.fr/icons/filterNecklineDroit.svg') no-repeat center;
  }
  .filter-item__image--couvrant {
    background: url('https://cdn.new.darjeeling.fr/icons/filterNecklineCouvrant.svg') no-repeat
      center;
  }
  .filter-item__image--plongeant {
    background: url('https://cdn.new.darjeeling.fr/icons/filterNecklinePlongeant.svg') no-repeat
      center;
  }

  // CSS For 'Fermeture' / 'Closure' Filter
  .filter-item__image--devant {
    background: url('https://cdn.new.darjeeling.fr/icons/filterClosuresDevant.svg') no-repeat center;
  }
  .filter-item__image--derriere {
    background: url('https://cdn.new.darjeeling.fr/icons/filterClosuresDerriere.svg') no-repeat
      center;
  }
  .filter-item__image--aenfiler {
    background: url('https://cdn.new.darjeeling.fr/icons/filterClosuresAEnfiler.svg') no-repeat
      center;
  }

  // CSS For 'Opacité' / 'Opacity' Filter
  .filter-item__image--opaque {
    background: url('https://cdn.new.darjeeling.fr/icons/filterOpacityOpaque.svg') no-repeat center;
  }
  .filter-item__image--semi-opaque {
    background: url('https://cdn.new.darjeeling.fr/icons/filterOpacitySemiOpaque.svg') no-repeat
      center;
  }
  .filter-item__image--transparent {
    background: url('https://cdn.new.darjeeling.fr/icons/filterOpacityTransparent.svg') no-repeat
      center;
  }
`;

export const StandardWithIconsFilter = ({ attribute, ...props }: UseRefinementListProps) => {
  const { items, refine } = useRefinementList({
    ...props,
    attribute,
    sortBy: ['name:asc'],
    limit: 6,
    showMoreLimit: 200,
  });

  return (
    <StyledStandardWithIconFilter>
      {items?.map((item, index) => (
        <button
          className={`filter-item ${item.isRefined ? 'filter-item--is-refined' : ''}`}
          key={`filter-${item.label}-${index}`}
          id={`filter-${attribute}-${item.value}`}
          onClick={() => refine(item.value)}
        >
          <div
            className={`filter-item__image filter-item__image--${removeSpaces(
              removeDiacritics(item.value.toLowerCase())
            )}`}
          ></div>
          <p className="filter-item__text filter-item__label">{item.label}</p>
          <p className="filter-item__text filter-item__count">({item.count})</p>
        </button>
      ))}
    </StyledStandardWithIconFilter>
  );
};
