import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '../../../store/rootReducer';
import { cmsCatalogInit, setCmsContent, setUid } from '../../cms/actions';
import { CmsBannerWithPictos, CmsCustomTypes } from '../../cms/types';
import { getCanonicalUrl } from '../../common/utils';
import { getMetaTitleForPLP, getPage } from '../utils';
import { CatalogTitle } from './CatalogTitle';
import { PromoBanner } from '../../banners';
import { DescriptionBannerWithPicture } from '../../banners/catalog/DescriptionBannerWithPicture';
import { BannerWithPictos } from '../../banners/catalog/BannerWithPictos';
import { CmsSliderWithNumber } from './CmsSliderWithNumber';
import { LBL_DARJEELING } from '../locale';

type Props = {
  uid: string;
  name?: string;
};

export const CmsBlock = ({ uid, name }: Props) => {
  const dispatch = useDispatch();
  const { catalog, masterList, uid: loadedUid } = useSelector((state: RootState) => state.cms);
  const sliderItems = useSelector(
    (state: RootState) =>
      state.cms.catalog?.body4?.find((el) => el.slice_type === 'slider_with_number')?.items
  );
  const hasBanner = uid && masterList.includes(`catalog-${(uid ?? '').toLowerCase()}`);

  const { pathname, search } = useLocation();
  const page = getPage(search);

  useEffect(() => {
    if (hasBanner) {
      if (uid !== loadedUid) {
        dispatch(cmsCatalogInit(uid) as unknown as UnknownAction);
      }
    } else if (masterList && masterList.length > 0) {
      dispatch(setCmsContent({ type: CmsCustomTypes.catalog, payload: null }));
      if (uid) {
        dispatch(setUid(uid));
      }
    }
  }, [uid, masterList, loadedUid]);

  if (uid !== loadedUid) {
    return null;
  }

  const {
    seotitle,
    seodescription,
    title,
    description,
    description_banner_is_active_on_desktop,
    description_banner_is_active_on_mobile,
    slider_with_number_is_active_desktop,
    slider_with_number_is_active_mobile,
    slider_with_number_text_is_active,
  } = catalog ?? {};

  const cmsBannerWithPictosContent = catalog?.body2?.find(
    (el: CmsBannerWithPictos) => el.slice_type === 'bannerwithpictos'
  );
  const primary = cmsBannerWithPictosContent?.primary;
  const items = cmsBannerWithPictosContent?.items;
  const banner_with_pictos_is_active_desktop = primary?.banner_with_pictos_is_active_desktop;
  const banner_with_pictos_is_active_mobile = primary?.banner_with_pictos_is_active_mobile;
  const banner_with_pictos_icon_background_color =
    primary?.banner_with_pictos_icon_background_color;

  const metaTitle = getMetaTitleForPLP({ name, page, seotitle, defaultTitle: LBL_DARJEELING });

  const shouldDisplayDescriptionBanner =
    description_banner_is_active_on_desktop || description_banner_is_active_on_mobile;
  const shouldDisplayBannerWithPictos =
    banner_with_pictos_is_active_desktop || banner_with_pictos_is_active_mobile;
  const shouldDisplaySliderWithNumber =
    slider_with_number_is_active_desktop || slider_with_number_is_active_mobile;
  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta property="og:title" content={metaTitle} />
        {seodescription && <meta name="description" content={seodescription} />}
        {seodescription && <meta property="og:description" content={seodescription} />}
        {page > 1 && <link rel="canonical" href={`${getCanonicalUrl(pathname)}?page=${page}`} />}
      </Helmet>
      <CatalogTitle title={title} subtitle={description} />
      {catalog && <PromoBanner />}
      {shouldDisplayDescriptionBanner && <DescriptionBannerWithPicture />}
      {shouldDisplayBannerWithPictos && (
        <BannerWithPictos
          items={items}
          banner_with_pictos_is_active_desktop={banner_with_pictos_is_active_desktop}
          banner_with_pictos_is_active_mobile={banner_with_pictos_is_active_mobile}
          banner_with_pictos_icon_background_color={banner_with_pictos_icon_background_color}
        />
      )}
      {shouldDisplaySliderWithNumber && (
        <CmsSliderWithNumber
          items={sliderItems ?? []}
          slider_with_number_is_active_desktop={slider_with_number_is_active_desktop}
          slider_with_number_is_active_mobile={slider_with_number_is_active_mobile}
          slider_with_number_text_is_active={slider_with_number_text_is_active}
        />
      )}
    </>
  );
};
