import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { DynamicButtonStatus } from '../../design-system';
import types from './actionTypes';
import { Cart, ShippingTypes, ShippingPrice, CartStep, CartItem } from './types';
import { getBFFData, Mutations, Queries } from '../api';
import { Address as AddressType } from '../account/types';
import { updateUserInfo, requestUserSuccess } from '../account/actions';
import { setFeedback, setCtaState, setFormValidation } from '../form/actions';
import { ERR_NOT_SAVED } from '../form/locale';
import { Forms } from '../form/types';
import { persistData } from '../common/utils';
import { pushToGTM, getTrackingProduct, getClientID } from '../tracking';
import { Events } from '../tracking/types';
import { Product } from '../product/types';
import { CatalogProduct } from '../catalog/types';
import { PickupStation } from '../pickup/types';
import { RootState } from '../../store/rootReducer';
import { refreshIfNeeded } from './utils';
import {
  requestWishlistSuccess,
  requestWishlistFailure,
  WishlistProductInput,
} from '../wishlist/actions';
import { WishlistItem } from '../wishlist/types';
import { requestProductSuccessModal } from '../product/actions';

export const requestCartSuccess = (payload: Cart) => action(types.REQUEST_CART_SUCCESS, payload);
export const resetStoreId = () => action(types.RESET_STORE_ID);
export const resetPickupStationId = () => action(types.RESET_PICKUP_STATION_ID);
export const requestCartFailure = (payload: string) => action(types.REQUEST_CART_FAILURE, payload);

export const requestShippingTypesSuccess = (payload: ShippingPrice[]) =>
  action(types.REQUEST_SHIPPING_TYPES_SUCCESS, payload);

type AsyncCartActions = ActionType<
  | typeof requestCartSuccess
  | typeof requestCartFailure
  | typeof requestShippingTypesSuccess
  | typeof setPaypalECS
  | typeof setCouponLoadingState
  | typeof resetStoreId
  | typeof resetPickupStationId
>;

export const setStep = (payload: number) => action(types.SET_STEP, payload);

export const setStoreId = (payload: string) => action(types.SET_STORE_ID, payload);

export const setPickupStationId = (payload: PickupStation) =>
  action(types.SET_PICKUP_STATION_ID, payload);

export const removePickupStationInfo = (payload: PickupStation) =>
  action(types.REMOVE_PICKUP_STATION_INFO, payload);

export const changeCtaState = (payload: DynamicButtonStatus) =>
  action(types.CHANGE_CTA_STATE, payload);

export const setShowFeedback = (payload: boolean) => action(types.SET_SHOW_FEEDBACK, payload);

export const setCouponLoadingState = (payload: boolean) =>
  action(types.SET_COUPON_LOADING_STATE, payload);

export type CartActions =
  | AsyncCartActions
  | ActionType<
      | typeof setStep
      | typeof changeCtaState
      | typeof setStoreId
      | typeof setPickupStationId
      | typeof removePickupStationInfo
      | typeof setShowFeedback
    >;

export function loadCart() {
  return async (dispatch) => {
    const response = await getBFFData(Queries.getCart);
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.cart));
      dispatch(requestShippingTypesSuccess(response.data.shippingTypes));
      dispatch(requestWishlistSuccess(response.data.wishlist));
    } else {
      dispatch(requestCartFailure(response.data));
      dispatch(requestWishlistFailure(response.data));
    }
  };
}

type AsyncAddToCartActions = ActionType<
  typeof changeCtaState | typeof requestCartSuccess | typeof requestProductSuccessModal
>;

export type ProductInput = {
  productRef: string;
  colorRef: string;
  sku: string;
  quantity: number;
};

export function addToCart(product: ProductInput, trackingProduct: Product | CatalogProduct) {
  return async (dispatch: Dispatch<AsyncAddToCartActions>, getState: () => RootState) => {
    dispatch(changeCtaState(DynamicButtonStatus.Loading));
    const response = await getBFFData(Mutations.addToCart, { product });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.addToCart));
      dispatch(changeCtaState(DynamicButtonStatus.Success));
      pushToGTM(Events.addToCart, {
        product: getTrackingProduct({ product: { ...trackingProduct, sku: product.sku } }),
      });
      await new Promise((resolve) => setTimeout(resolve, 3000));
      dispatch(changeCtaState(DynamicButtonStatus.Default));
    } else {
      dispatch(changeCtaState(DynamicButtonStatus.Error));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(changeCtaState(DynamicButtonStatus.Default));
      const error = new Error(response.data);
      error.name = 'Add to cart error';
    }

    refreshIfNeeded(getState());
  };
}

export type TAddGiftCardRequest = {
  productRef: string;
  colorRef: string;
  sku: string;
  price: number;
  from: string;
  to: string;
  email: string;
  date?: string;
  message?: string;
};

export function addGiftCard(
  giftCard: TAddGiftCardRequest,
  trackingProduct: Product | CatalogProduct
) {
  return async (dispatch: Dispatch<AsyncAddToCartActions>, getState: () => RootState) => {
    dispatch(changeCtaState(DynamicButtonStatus.Loading));
    const response = await getBFFData(Mutations.addGiftCard, { giftCard });

    if (!response.ok) {
      dispatch(changeCtaState(DynamicButtonStatus.Default));
      if (response.data === 'RangeError: Everest validation failed') {
        return { ok: false, data: "Cette adresse email n'existe pas." };
      }
    }
    dispatch(requestProductSuccessModal(response.data.addGiftCard.items[0]));
    dispatch(requestCartSuccess(response.data.addGiftCard));
    dispatch(changeCtaState(DynamicButtonStatus.Success));
    pushToGTM(Events.addToCart, {
      product: getTrackingProduct({ product: { ...trackingProduct, sku: giftCard.sku } }),
    });
    await new Promise((resolve) => setTimeout(resolve, 3000));
    dispatch(changeCtaState(DynamicButtonStatus.Default));
    refreshIfNeeded(getState());
    return { ok: true };
  };
}

export function removeGiftCard(itemId: string, trackingProduct: CartItem) {
  return async (
    dispatch: Dispatch<ActionType<typeof updateQuantity | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.removeGiftCard, { itemId });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.removeGiftCard));
      pushToGTM(Events.removeFromCart, {
        product: getTrackingProduct({ product: trackingProduct, isCart: true }),
      });
    }
  };
}

export function updateGiftCard(itemId: string, giftCard: TAddGiftCardRequest) {
  return async (
    dispatch: Dispatch<ActionType<typeof updateGiftCard | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.updateGiftCard, { itemId, giftCard });
    if (!response.ok) {
      dispatch(changeCtaState(DynamicButtonStatus.Default));
      if (response.data === 'RangeError: Everest validation failed') {
        return { ok: false, data: "Cette adresse email n'existe pas." };
      }
    }

    dispatch(requestCartSuccess(response.data.updateGiftCard));
    return { ok: true };
  };
}

export function updateQuantity(product: ProductInput, trackingProduct: CartItem) {
  const difference = product.quantity - trackingProduct.quantity;
  const isIncrease = difference >= 0;

  return async (
    dispatch: Dispatch<ActionType<typeof updateQuantity | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.updateQuantity, { product });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.updateCart));
      pushToGTM(isIncrease ? Events.addToCart : Events.removeFromCart, {
        product: getTrackingProduct({
          product: { ...trackingProduct, quantity: Math.abs(difference) },
          isCart: true,
        }),
      });
    }
  };
}

export function addCoupon(couponCode: string) {
  return async (dispatch: Dispatch<ActionType<typeof addCoupon | typeof requestCartSuccess>>) => {
    dispatch(setCouponLoadingState(true));
    const response = await getBFFData(Mutations.addCoupon, { couponCode });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.addCoupon));
      if (response.data?.addCoupon?.couponAccepted) {
        pushToGTM(Events.applyPromo, {
          code: couponCode,
          isApplied: true,
        });
      } else {
        pushToGTM(Events.applyPromo, {
          code: couponCode,
          isApplied: false,
        });
      }
    }
    dispatch(setCouponLoadingState(false));
  };
}

export function deleteCoupon(couponCode: string) {
  return async (
    dispatch: Dispatch<ActionType<typeof deleteCoupon | typeof requestCartSuccess>>
  ) => {
    dispatch(setCouponLoadingState(true));
    const response = await getBFFData(Mutations.deleteCoupon, { couponCode });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.deleteCoupon));
    }
    dispatch(setCouponLoadingState(false));
  };
}

export function addShippingType(type: ShippingTypes) {
  return async (
    dispatch: Dispatch<ActionType<typeof addShippingType | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.addShippingType, { type });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.addShippingType));
    }
  };
}

export type RemoveItemParams = {
  productRef: string;
  colorRef: string;
  sku: string;
};

export function removeItem(product: RemoveItemParams, trackingProduct: CartItem) {
  return async (
    dispatch: Dispatch<ActionType<typeof updateQuantity | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.removeItem, { product });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.deleteProduct));
      pushToGTM(Events.removeFromCart, {
        product: getTrackingProduct({ product: trackingProduct, isCart: true }),
      });
    }
  };
}

export function bulkRemove(products: RemoveItemParams[], trackingProducts: CartItem[]) {
  return async (
    dispatch: Dispatch<ActionType<typeof updateQuantity | typeof requestCartSuccess>>
  ) => {
    const response = await getBFFData(Mutations.bulkRemove, { products });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.deleteProducts));
      trackingProducts.forEach((trackingProduct) =>
        pushToGTM(Events.removeFromCart, {
          product: getTrackingProduct({ product: trackingProduct, isCart: true }),
        })
      );
    }
  };
}

export function mergeCart() {
  return async (_dispatch, getState: () => RootState) => {
    const state = getState();
    const { cartId, items } = state.cart.cart ?? {};
    const { wishlistId, items: wishlistItems } = state.wishlist.wishlist ?? {};

    const mergeCart = cartId && (items ?? []).length > 0;
    const mergeWishlist = wishlistId && (wishlistItems ?? []).length > 0;

    if (mergeCart && mergeWishlist) {
      await getBFFData(Mutations.mergeCartAndWishlist, { cartId, wishlistId });
    } else {
      if (mergeCart) {
        await getBFFData(Mutations.mergeCart, { cartId });
      }
      if (mergeWishlist) {
        await getBFFData(Mutations.mergeWishlist, { wishlistId });
      }
    }
  };
}

export function setCheckoutAddresses({
  shippingType,
  billing,
  storeId,
  isDigital,
  giftMessage,
  contactPhone,
  pickupStation,
  shipping,
}: {
  billing: AddressType;
  isDigital?: boolean;
  storeId?: string;
  giftMessage?: string;
  contactPhone?: string;
  shippingType?: ShippingTypes;
  pickupStation?: PickupStation;
  shipping?: AddressType;
}) {
  return async (
    dispatch: Dispatch<
      ActionType<
        typeof setFeedback | typeof setStep | typeof requestCartSuccess | typeof setCtaState
      >
    >,
    getState: () => RootState
  ) => {
    dispatch(setCtaState({ form: Forms.payment, ctaState: DynamicButtonStatus.Loading }));

    const gaClientId = getClientID();

    let responseData;

    if (isDigital) {
      const response = await getBFFData(Mutations.setCheckoutAddresses, {
        billing,
        gaClientId,
      });
      if (response.ok) {
        responseData = response.data.checkoutAddress;
      }
    } else if (shippingType === ShippingTypes.HOME) {
      const response = await getBFFData(Mutations.setCheckoutAddresses, {
        shipping,
        billing,
        gaClientId,
        giftMessage,
      });
      if (response.ok) {
        responseData = response.data.checkoutAddress;
      }
    } else if (shippingType === ShippingTypes.STORE && storeId) {
      const response = await getBFFData(Mutations.setCheckoutStore, {
        storeId,
        billing,
        gaClientId,
        giftMessage,
      });
      if (response.ok) {
        responseData = response.data.checkoutStore;
      }
    } else if (shippingType === ShippingTypes.PICKUP && pickupStation?.id && contactPhone) {
      const response = await getBFFData(Mutations.setCheckoutPickupStation, {
        pickupStation,
        billing,
        gaClientId,
        contactPhone,
        giftMessage,
      });
      if (response.ok) {
        responseData = response.data.checkoutPickupStation;
      }
    }

    if (responseData) {
      const { cart, paymentMethods, token } = responseData;
      // to keep the storeId for user on cart step 3 going on step 2
      const cartWithStoreId = {
        ...cart,
        storeId,
      };
      const cartWithPickupStation = {
        ...cart,
        pickupStation,
      };
      persistData('paymentToken', token);
      persistData('paymentMethods', paymentMethods);
      dispatch(setStep(CartStep.PAYMENT));
      dispatch(
        requestCartSuccess(
          shippingType === ShippingTypes.STORE
            ? cartWithStoreId
            : shippingType === ShippingTypes.PICKUP
              ? cartWithPickupStation
              : cart
        )
      );
      dispatch(
        setFeedback({
          form: Forms.delivery,
          ok: false,
          message: '',
        })
      );
    } else {
      dispatch(
        setFeedback({
          form: Forms.delivery,
          ok: false,
          message: ERR_NOT_SAVED,
        })
      );
    }
    dispatch(setCtaState({ form: Forms.payment, ctaState: DynamicButtonStatus.Default }));

    refreshIfNeeded(getState());
  };
}

export function resetStep() {
  return async (
    dispatch: Dispatch<ActionType<typeof setFeedback | typeof setStep | typeof setFormValidation>>
  ) => {
    dispatch(setStep(CartStep.LISTING));
    dispatch(
      setFeedback({
        form: Forms.delivery,
        ok: false,
        message: '',
      })
    );
    dispatch(
      setFormValidation({
        form: Forms.delivery,
        values: {
          isConditionsAccepted: '',
        },
      })
    );
  };
}

type AddableWishlistProductInput = WishlistProductInput & {
  sku: string;
};

export function moveToWishlistFromCart(
  product: AddableWishlistProductInput,
  trackingProduct: CartItem
) {
  return async (dispatch) => {
    const response = await getBFFData(Mutations.moveToWishlistFromCart, { product });
    if (response.ok) {
      dispatch(requestCartSuccess(response.data.moveToFavourites.cart));
      dispatch(requestWishlistSuccess(response.data.moveToFavourites.wishlist));
      pushToGTM(Events.removeFromCart, {
        product: getTrackingProduct({ product: trackingProduct, isCart: true }),
      });
      pushToGTM(Events.addToWishlist, {
        product: getTrackingProduct({ product: trackingProduct, isCart: true }),
      });
    }
  };
}

export function moveToCartFromWishlist(
  product: AddableWishlistProductInput,
  trackingProduct: WishlistItem
) {
  return async (dispatch) => {
    const response = await getBFFData(Mutations.moveToCartFromWishlist, { product });
    if (response.ok) {
      dispatch(requestWishlistSuccess(response.data.moveToCart.wishlist));
      dispatch(requestCartSuccess(response.data.moveToCart.cart));
      pushToGTM(Events.removeFromWishlist, {
        product: getTrackingProduct({ product: trackingProduct }),
      });
      pushToGTM(Events.addToCart, {
        product: getTrackingProduct({ product: trackingProduct }),
      });
    }
  };
}

export const setPaypalECS = (payload: { email: string; firstName: string; orderNumber: string }) =>
  action(types.SET_PAYPAL_ECS, payload);

export const partialUserUpdate = (userPartial, callback?: () => void) => {
  return async (dispatch, getState: () => RootState) => {
    await dispatch(updateUserInfo(userPartial));

    const state = getState();
    const { user } = state.account;
    const form = state.form.personal;

    if (form.feedback.ok) {
      await dispatch(requestUserSuccess({ ...user, ...userPartial }));
      if (callback) {
        callback();
      }
    }
  };
};
