import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { UnknownAction } from 'redux';
import { css } from '@emotion/core';
import { PrismicRichText } from '@prismicio/react';
import ReactToPrint from 'react-to-print';
import * as qs from 'qs';

import { Box, Text, Icon, Divider } from '../../../design-system';
import accountPaths from '../paths';
import paths from '../../routing/paths';
import OrderItem from './OrderItem';
import { RootState } from '../../../store/rootReducer';
import { getOrder } from '../actions';
import LoadingOrderDetail from './LoadingOrderDetail';
import locale, { ORDER_STATUS, SHIPPING_TYPES } from '../locale';
import { formatPrice } from '../../common/utils';
import { ERR_GENERIC, ERR_NOT_FOUND } from '../../common/locale';
import { ShippingTypes } from '../../cart/types';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import Invoice from './Invoice';
import { getShowPrintInvoice, getTrackingNumbers, getPaymentInfo } from '../utils';
import BackButton from '../../common/components/BackButton';
import { hasValidCmsText } from '../../home/utils';

export const OrderDetail = () => {
  const { uid } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { migrated } = qs.parse(search.slice(1)) ?? {};

  const printComponentRef = useRef();
  useEffect(() => {
    if (uid) {
      dispatch(
        getOrder({
          orderNumber: Number.parseInt(uid, 10),
          migrated: Boolean(migrated),
        }) as unknown as UnknownAction
      );
    }
  }, [uid]);

  const { order, isFetching, errMsg } = useSelector((state: RootState) => state.account);

  const { body } = useSelector((state: RootState) => state.cms.order) ?? {};

  const cmsInvoice = useSelector((state: RootState) => state.cms.invoice) ?? {};

  useEffect(() => {
    if (!body?.[0]?.text || !cmsInvoice?.image?.url) {
      dispatch(loadCmsContent(CmsCustomTypes.order) as unknown as UnknownAction);
      dispatch(loadCmsContent(CmsCustomTypes.invoice) as unknown as UnknownAction);
    }
  }, []);

  if (isFetching) {
    return (
      <Box maxWidth="mwm" mt="xl">
        <LoadingOrderDetail />
      </Box>
    );
  }
  if (errMsg) {
    return (
      <Text preset="caption" color="ERROR">
        {ERR_GENERIC}
      </Text>
    );
  }
  if (!order) {
    return (
      <Text preset="caption" color="ERROR">
        {ERR_NOT_FOUND}
      </Text>
    );
  }

  const showPrintInvoice = getShowPrintInvoice(order.status);

  const trackingNumbers = getTrackingNumbers({
    tracking: order.tracking,
    legacyNumber: order.trackingNumber,
  });

  const paymentInfo = getPaymentInfo(order);

  return (
    <Box
      display="grid"
      gridTemplateAreas={[
        "'title' 'details' 'shipping' 'billing' 'payment' 'items' 'summary' 'return' 'retour' ",
        `'retour .'
        'title title'
        'details details'
        'shipping billing'
        'payment payment'
        'items summary'
        'return return'`,
        `'retour . . .'
        'title title . .'
        'details details details details'
        'shipping billing . .'
        'payment payment . .'
        'items items summary summary'
        'return return return return'`,
      ]}
      gridGap={['m', 'l']}
      maxWidth="mwm"
      mt="xl"
    >
      {showPrintInvoice && (
        <Box display="none">
          {
            // @ts-ignore
            <Invoice ref={printComponentRef} order={order} cmsContent={cmsInvoice} />
          }
        </Box>
      )}
      <BackButton
        id="order-detail-back-button"
        onClick={() => navigate(paths.ACCOUNT + accountPaths.ORDERS)}
        preset="darkTheme"
        isOrderDetail
      />
      <Box gridArea="title">
        <Box maxWidth="90vw">
          <Text preset="heading" withEllipsis>
            Votre commande n° {order.orderNumber}
          </Text>
        </Box>
      </Box>
      <Box gridArea="details" bg="BACKGROUND" p="l">
        <Box mb="l" display="flex" justifyContent="space-between" flexDirection={['column', 'row']}>
          <Box>
            <Text>
              Date de votre commande :{' '}
              {order.createdAt ? new Date(order.createdAt).toLocaleDateString('fr-FR') : ''}
            </Text>
            <Text>Numéro de votre commande : {order.orderNumber}</Text>
          </Box>
          {showPrintInvoice && (
            <Box my={['s', 'na']}>
              <ReactToPrint
                trigger={() => (
                  <Box display="flex" id="print-invoice">
                    <Icon name="print" />
                    <button
                      type="button"
                      data-testid="account-print-invoice-btn"
                      className="anchor-animated"
                      css={css`
                        font-size: 1.4rem;
                        margin-left: 8px;
                      `}
                    >
                      Imprimer ma facture
                    </button>
                  </Box>
                )}
                // @ts-ignore
                content={() => printComponentRef.current}
              />
            </Box>
          )}
        </Box>
        <Box mb={['s', 'na']}>
          <Text>Statut : {ORDER_STATUS[order.status]}</Text>
        </Box>
        <Box
          mb={['s', 'na']}
          display="grid"
          gridAutoFlow="column"
          justifyContent="start"
          gridGap="s"
        >
          <Text>N° de suivi colis : </Text>
          <Box display="grid" gridAutoFlow={['row', 'column']} gridGap="s">
            {trackingNumbers.map((trackingNumber, index) => (
              <Text key={trackingNumber}>
                <Link
                  className="anchor-animated"
                  data-testid={`link-order-detail-tracking-${index}`}
                  id={`link-order-detail-tracking-${index}`}
                  to={`${paths.ACCOUNT}${accountPaths.TRACKING}/${trackingNumber}`}
                >
                  {trackingNumber}
                </Link>
                {index < trackingNumbers.length - 1 && ', '}
              </Text>
            ))}
          </Box>
        </Box>
      </Box>
      <Box gridArea="shipping">
        <Box mb="s">
          <Text>Adresse de livraison :</Text>
        </Box>
        {order.isDigital ? (
          <Text>{locale.EMAIL_DELIVERY}</Text>
        ) : order.shippingType === ShippingTypes.STORE ? (
          order.storeInfos ? (
            <>
              <Text>{order.storeInfos?.name}</Text>
              <Text>{order.storeInfos.address1}</Text>
              <Text>{order.storeInfos?.address2}</Text>
              <Text>
                {order.storeInfos?.postalCode},{order.storeInfos?.city}
              </Text>
              <Text>{order.storeInfos?.countryCode}</Text>
            </>
          ) : (
            <Text>{SHIPPING_TYPES.STORE}</Text>
          )
        ) : order.shippingType === ShippingTypes.PICKUP ? (
          <>
            <Text>{SHIPPING_TYPES.PICKUP}</Text>
            <Text>{order.pickupStationInfos?.name}</Text>
            <Text>{order.pickupStationInfos?.line1}</Text>
            <Text>{order.pickupStationInfos?.line2}</Text>
            <Text>
              {order.pickupStationInfos?.postalCode} {order.pickupStationInfos?.city}
            </Text>
          </>
        ) : (
          <>
            <Text>{order.shippingAddress?.company}</Text>
            <Text>
              {order.shippingAddress.firstName} {order.shippingAddress?.lastName}
            </Text>
            <Text>{order.shippingAddress?.street}</Text>
            <Text>
              {order.shippingAddress?.postal} {order.shippingAddress?.city}
            </Text>
            <Text>{order.shippingAddress?.country}</Text>
          </>
        )}
      </Box>
      <Box gridArea="billing">
        <Box mb="s">
          <Text>Adresse de facturation :</Text>
        </Box>
        <Text>{order.billingAddress?.company}</Text>
        <Text>
          {order.billingAddress?.firstName} {order.billingAddress?.lastName}
        </Text>
        <Text>{order.billingAddress?.street}</Text>
        <Text>
          {order.billingAddress?.postal} {order.billingAddress?.city}
        </Text>
        <Text>{order.billingAddress?.country}</Text>
      </Box>

      <Box gridArea="payment">
        <Box mb="s">
          <Text>Moyen de Paiement :</Text>
        </Box>
        <Box display="grid" gridGap="xs">
          {paymentInfo.map((payment, index) => (
            <Text key={index} textTransform="capitalize">
              {payment.method} : **** **** **** {payment.cardSummary}{' '}
              {paymentInfo.length > 1 ? `(${payment.amount})` : ''}
            </Text>
          ))}
        </Box>
      </Box>
      <Box gridArea="items">
        {(order.items ?? []).map((item, index) => (
          <OrderItem key={index} item={item} />
        ))}
      </Box>
      <Box
        gridArea="summary"
        bg={['WHITE', 'BACKGROUND']}
        height="fit-content"
        py={['na', 'l']}
        mt={['na', 's']}
      >
        <Box display={['block', 'none']}>
          <Divider my="m" />
        </Box>
        <Box display="grid" gridTemplateColumns="2fr 1fr" px={['na', 'l']} alignItems="end">
          <Text>Sous-total</Text>
          <Box justifySelf="right">
            <Text>{formatPrice(order.originalBaseTotal)}</Text>
          </Box>
          <Text>Code promotionnel</Text>
          <Box justifySelf="right">
            <Text>{formatPrice(order.totalCouponAmount)}</Text>
          </Box>
          <Text>Offres en cours</Text>
          <Box justifySelf="right">
            <Text>{formatPrice(order.totalDiscount)}</Text>
          </Box>
          <Text>Livraison</Text>
          <Box justifySelf="right">
            <Text>{formatPrice(order.shippingAmount)}</Text>
          </Box>
        </Box>
        <Divider my="m" />
        <Box display="grid" gridTemplateColumns="1fr 1fr" px={['na', 'l']}>
          <Text preset="heading">Total :</Text>
          <Box justifySelf="right">
            <Text preset="heading">{formatPrice(order.total)}</Text>
          </Box>
        </Box>
      </Box>
      {hasValidCmsText(body) && (
        <Box gridArea="return" my="l">
          <PrismicRichText field={body} />
        </Box>
      )}
    </Box>
  );
};

export default OrderDetail;
