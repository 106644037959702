import React, { ComponentProps, useEffect, useRef } from 'react';
import { useCurrentRefinements } from 'react-instantsearch';
import { css } from '@emotion/core';

import { heights } from '../../common/constants';
import { HIERARCHICAL_ITEMS } from './HierarchicalMenu';
import { breakpoints } from '../../../design-system';

export const ScrollTo: React.FC<ComponentProps<'div'>> = ({ children, ...props }) => {
  const { items: currentRefinements } = useCurrentRefinements({
    excludedAttributes: HIERARCHICAL_ITEMS,
  });
  const divRef = useRef<HTMLDivElement | null>(null);
  const refinementsRef = useRef(currentRefinements);

  useEffect(() => {
    if (JSON.stringify(currentRefinements) === JSON.stringify(refinementsRef.current)) {
      return;
    }
    if (window.scrollY > (divRef.current?.offsetTop ?? 0)) {
      divRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    refinementsRef.current = currentRefinements;
  }, [currentRefinements]);

  return (
    <div
      {...props}
      ref={divRef}
      css={css`
        scroll-margin: ${heights.HEADER}px;
        position: relative;
        display: flex;
        justify-content: space-between;
        @media (max-width: ${breakpoints.L - 1}px) {
          scroll-margin: calc(${heights.HEADER + 150}px);
        }
      `}
    >
      {children}
    </div>
  );
};
