import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import {
  Selector,
  Image,
  ImageContainer,
  colors,
  Icon,
  opacities,
  breakpoints,
} from '../../../design-system';
import { CartItem as CartItemType } from '../types';
import { getProductImageLink, productImageWidths, isItGiftCard } from '../../product/utils';
import { formatPrice } from '../../common/utils';
import { ColorText } from '../../common/components/Custom';
import { updateQuantity, removeItem, moveToWishlistFromCart, removeGiftCard } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { checkCartItemValidity } from '../utils';
import {
  TXT_CART_ITEM_UNDO,
  MSG_CART_ITEM_REMOVED,
  MSG_CART_ITEM_INVALID,
  LBL_GIFT_ITEM,
  TXT_MOVE_TO_WISHLIST,
  MSG_MOVED_TO_WISHLIST,
} from '../locale';
import { getPromotionColors } from '../../catalog/utils';
import { transitions, durations } from '../../common/constants';
import { LBL_COLOR, LBL_QUANTITY, LBL_SIZE } from '../../product/locale';
import { HighlightedName } from '../../catalog/components/HighlightedName';
import { CartItemGiftCard } from './CartItemGiftCard';
import { openModalNew } from '../../common/actions';
import { QuickAdd } from '../../quickadd/components/QuickAdd';
import { QuickAddMobile } from '../../quickadd/components/QuickAddMobile';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

type CartItemContainerProps = {
  showUndo: boolean;
  showMove: boolean;
};

export const CartItemContainer = styled.div<CartItemContainerProps>(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    background: colors.BACKGROUND,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transform: 'scaleX(1)',
      transformOrigin: '0 50%',
      transition: transitions.GENERIC,
      background: colors.WHITE,
    },
  },
  ({ showUndo }) =>
    showUndo && {
      '&:before': {
        transform: 'scaleX(0)',
      },
    },
  ({ showMove }) =>
    showMove && {
      '&:before': {
        transform: 'scaleX(0)',
      },
      background: 'linear-gradient(90deg, #83FFD1 0%, #41DDA3 100%)',
    }
);

type AnimationContainerProps = {
  show: boolean;
  shouldDisappear: boolean;
};

export const AnimationContainer = styled.div<AnimationContainerProps>(
  {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transition: transitions.GENERIC,
  },
  ({ show }) =>
    show && {
      display: 'flex',
      opacity: 1,
    },
  ({ shouldDisappear }) =>
    shouldDisappear && {
      opacity: 0,
    }
);

type Props = {
  id: string;
  item: CartItemType;
  giftItemTextColor: string;
  isAvailable?: boolean;
};

export const CartItem = ({ id, item, giftItemTextColor, isAvailable }: Props) => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaQueries();

  const [showUndo, setShowUndo] = useState(false);
  const [hasWaitedForUndo, setHasWaitedForUndo] = useState(false);
  const [shouldDisappear, setShouldDisappear] = useState(false);
  const [isQuickAddMobileOpen, setIsQuickAddMobileOpen] = useState(false);
  const [showMove, setShowMove] = useState(false);

  const {
    productRef,
    colorRef,
    sku,
    productName,
    detailedColorLabel,
    rowBaseTotal,
    rowTotal,
    quantity,
    size,
    bandSize,
    cupSize,
    promotionLabel,
    promotionPercentage,
    stockQuantity: { stockQuantity },
    freeProduct,
    promoMessages,
    itemCouponAmount,
    promoPrice,
    collectionName,
  } = item;

  useEffect(() => {
    if (hasWaitedForUndo) {
      if (showUndo) {
        setShouldDisappear(true);
      }
      setHasWaitedForUndo(false);
    }
  }, [hasWaitedForUndo]);

  useEffect(() => {
    if (shouldDisappear) {
      if (isGiftCard && item.gcFields) {
        dispatch(removeGiftCard(item.gcFields.itemId, item) as unknown as UnknownAction);
      } else {
        dispatch(removeItem({ productRef, colorRef, sku }, item) as unknown as UnknownAction);
      }
    }
  }, [shouldDisappear]);

  const cmsContent = useSelector((state: RootState) => state.cms.promotion);

  const { textColor, backgroundColor } = getPromotionColors({
    cmsContent,
    promotionPercentage,
  });

  const thumbnail = getProductImageLink({
    productRef,
    colorRef,
    productName,
    position: 1,
    width: productImageWidths.CART,
  });

  const isQuantityAvailable = checkCartItemValidity(item);
  const objectID = `${productRef}-${colorRef}`;

  const hasPromo = promoPrice !== rowBaseTotal;
  const total = formatPrice(rowTotal);
  const baseTotal = formatPrice(rowBaseTotal);

  const optionCount = isQuantityAvailable
    ? stockQuantity > 10
      ? quantity > 10
        ? quantity
        : 10
      : stockQuantity
    : quantity;
  const dropdownOptions = [...Array(optionCount).keys()].map((index) => {
    const qty = index + 1;
    return {
      value: String(qty),
      label: String(qty),
      disabled: qty > stockQuantity,
    };
  });

  const promoMessageList = (promoMessages ?? []).filter((message) => {
    const messageWithoutSpaces = message.replace(/\s/g, '');
    return Boolean(messageWithoutSpaces);
  });

  const handleDeleteItem = () => {
    if (isAvailable) {
      setShowUndo(true);
      setTimeout(() => {
        setHasWaitedForUndo(true);
      }, durations.UNDO_DELAY);
    } else {
      setShouldDisappear(true);
    }
  };

  const handleUndo = () => {
    setShowUndo(false);
  };

  const handleItemQuantityChange = (newQuantity) => {
    dispatch(
      updateQuantity(
        {
          productRef,
          colorRef,
          sku,
          quantity: newQuantity,
        },
        item
      )
    );
  };

  const handleMoveToWishlist = () => {
    setShowMove(true);
    dispatch(
      moveToWishlistFromCart({ productRef, colorRef, sku }, item) as unknown as UnknownAction
    );
  };

  const showActions = !freeProduct || (freeProduct && !(isQuantityAvailable && isAvailable));
  const isGiftCard = isItGiftCard(productRef);

  const handleOpenQuickAdd = () => {
    isMobile
      ? setIsQuickAddMobileOpen(true)
      : dispatch(
          openModalNew({
            content: (
              <QuickAdd
                productRef={productRef}
                colorRef={colorRef}
                originalSelectedColor={colorRef}
              />
            ),
            preset: 'medium',
          })
        );
  };

  return (
    <CartItemContainer
      showUndo={showUndo}
      showMove={showMove}
      id={`box-cart-item-${id}${isGiftCard ? '-' + item.gcFields?.itemId : ''}`}
    >
      <div
        css={css`
          width: 100%;
          position: relative;
        `}
      >
        {isGiftCard ? (
          <CartItemGiftCard
            showMove={showMove}
            showUndo={showUndo}
            id={id}
            isAvailable={isAvailable}
            item={item}
            handleDeleteItem={handleDeleteItem}
          />
        ) : (
          <div
            css={css`
              display: flex;
              width: calc(100% - 32px);
              margin: 24px 16px;
              transition: ${transitions.GENERIC};
              opacity: ${showUndo || showMove ? 0 : 1};
              gap: 16px;

              @media (min-width: ${breakpoints.S}px) {
                margin: 24px 0;
                width: 100%;
              }
            `}
          >
            <button
              data-testid={`btn-cart-item-quick-add-${id}`}
              onClick={handleOpenQuickAdd}
              css={css`
                opacity: ${isAvailable ? 1 : opacities.DISABLED};
                min-width: ${`${productImageWidths.CART}px`};
                justify-self: center;
                align-self: center;
                border: none;
                backgound: unset;
                padding: 0;
                border-radius: 8px;
                transition: opacity ${durations.ENTER}ms ease-in-out;
                pointer-events: ${isAvailable ? 'auto' : 'none'};

                @media (hover: hover) {
                  &:hover {
                    cursor: pointer;
                    opacity: ${opacities.HOVERED};
                  }
                }
              `}
            >
              <ImageContainer>
                <Image isAbsolute src={thumbnail} alt="Product Image" />
              </ImageContainer>
            </button>
            <div
              css={css`
                display: flex;
                gap: 8px;
                width: 100%;
                justify-content: space-between;
              `}
            >
              <div
                css={css`
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                  gap: 8px;
                  justify-content: space-between;
                `}
              >
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: center;
                    gap: 8px;
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 100%;
                      justify-content: space-between;
                      gap: 8px;
                    `}
                  >
                    <HighlightedName
                      objectID={objectID}
                      productName={productName}
                      collectionName={collectionName}
                      isCartPage
                    />
                    {freeProduct ? (
                      <div
                        css={css`
                          display: flex;
                          gap: 8px;
                          justify-content: flex-end;
                        `}
                        id={`box-gift-item-msg-${productRef}-${colorRef}`}
                      >
                        <Icon name="gift" color={giftItemTextColor} />
                        <ColorText cmsColor={giftItemTextColor} fontWeight="bold">
                          {LBL_GIFT_ITEM}
                        </ColorText>
                      </div>
                    ) : (
                      <div
                        css={css`
                          display: flex;
                          flex-direction: column;
                          gap: 6px;
                          align-items: flex-end;
                        `}
                      >
                        <span
                          id={`text-cart-item-row-total-${id}`}
                          css={css`
                            font-size: 1.6rem;
                            font-weight: 700;
                          `}
                        >
                          {total}
                        </span>
                        {(hasPromo || (itemCouponAmount ?? 0) > 0) && (
                          <span
                            id={`text-cart-item-base-row-total-${id}`}
                            css={css`
                              font-size: 1.6rem;
                              color: ${colors.GREY2};
                              text-decoration: line-through;
                            `}
                          >
                            {baseTotal}
                          </span>
                        )}
                      </div>
                    )}
                  </div>
                  {(promoMessageList?.length > 0 || promotionPercentage || promotionLabel) && (
                    <div
                      css={css`
                        display: flex;
                        flex-wrap: wrap;
                        gap: 8px;
                      `}
                    >
                      {(promotionPercentage || promotionLabel) && (
                        <ColorText
                          borderRadius={4}
                          display="inline-flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          py="xxs"
                          px="s"
                          cmsColor={textColor}
                          bg={backgroundColor}
                          fontWeight="bold"
                          preset="caption"
                          alignSelf="start"
                        >
                          {promotionPercentage ? `-${promotionPercentage}` : promotionLabel}
                        </ColorText>
                      )}
                      {promoMessageList?.length > 0 && (
                        <div
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 4px;
                          `}
                        >
                          {promoMessageList.map((message) => (
                            <div
                              key={message}
                              css={css`
                                display: flex;
                                gap: 2px;
                              `}
                            >
                              <Icon name="demand" />
                              <p
                                css={css`
                                  margin: 0;
                                  font-size: 1.2rem;
                                  height: 20px;
                                  display: flex;
                                  align-items: center;
                                  text-transform: capitalize;
                                `}
                              >
                                {message}
                              </p>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    css={css`
                      display: flex;
                      flex-wrap: wrap;
                      gap: 16px;
                    `}
                  >
                    <p
                      css={css`
                        margin: 0;
                        font-size: 1.4rem;
                        color: ${colors.MAIN_GREY};
                      `}
                    >
                      {`${LBL_COLOR} `}
                      <span
                        css={css`
                          font-weight: 700;
                        `}
                      >
                        {detailedColorLabel}
                      </span>
                    </p>
                    <p
                      css={css`
                        margin: 0;
                        font-size: 1.4rem;
                        color: ${colors.MAIN_GREY};
                      `}
                    >
                      {`${LBL_SIZE} `}
                      <span
                        css={css`
                          font-weight: 700;
                        `}
                      >
                        {size || `${bandSize} ${cupSize}`}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  css={css`
                    display: flex;
                    width: 100%;
                    gap: 16px;
                  `}
                >
                  {!freeProduct ? (
                    <div
                      css={css`
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                      `}
                    >
                      <div
                        css={css`
                          pointer-events: ${isAvailable ? 'auto' : 'none'};
                          opacity: ${isAvailable ? 1 : opacities.DISABLED};
                        `}
                      >
                        <Selector
                          id={`select-quantity-product-${id}`}
                          options={dropdownOptions}
                          value={String(quantity)}
                          onChange={handleItemQuantityChange}
                          hasError={!isQuantityAvailable && isAvailable}
                          mini
                        />
                      </div>
                      {showActions && (
                        <div
                          css={css`
                            display: flex;
                            gap: 16px;
                            justify-content: flex-end;
                            height: 32px;
                            align-items: center;

                            @media (min-width: ${breakpoints.S}px) {
                              height: auto;
                              align-items: flex-end;
                            }
                          `}
                        >
                          <button
                            className="anchor-animated"
                            data-testid={`btn-cart-move-to-wishlist-${id}`}
                            id={`btn-cart-move-to-wishlist-${id}`}
                            type="button"
                            onClick={handleMoveToWishlist}
                            css={css`
                              font-size: 1.2rem;
                              font-weight: 500;
                              display: none;
                              text-transform: uppercase;
                              @media (min-width: ${breakpoints.S}px) {
                                display: block;
                              }
                            `}
                          >
                            {TXT_MOVE_TO_WISHLIST}
                          </button>
                          <button
                            data-testid={`btn-cart-item-remove-${id}`}
                            id={`btn-cart-item-remove-${id}`}
                            type="button"
                            onClick={handleDeleteItem}
                            css={css`
                              background: none;
                              border: none;
                              padding: 0;
                              cursor: pointer;
                              transition: opacity ${durations.ENTER}ms ease-in-out;

                              @media (hover: hover) {
                                &:hover {
                                  opacity: ${opacities.HOVERED};
                                }
                              }
                            `}
                          >
                            <Icon name="trash" size={20} />
                          </button>
                        </div>
                      )}
                    </div>
                  ) : quantity > 1 ? (
                    <p
                      css={css`
                        margin: 0;
                        font-size: 1.4rem;
                        color: ${colors.MAIN_GREY};
                      `}
                    >
                      {`${LBL_QUANTITY} `}
                      <span
                        css={css`
                          font-size: 1.4rem;
                          font-weight: 700;
                          color: ${colors.MAIN_GREY};
                        `}
                      >
                        {quantity}
                      </span>
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}

        {isAvailable && !isQuantityAvailable && (
          <p
            css={css`
              font-size: 1.2rem;
              color: ${colors.ERROR};
              margin: 0 16px 8px 16px;

              @media (min-width: ${breakpoints.S}px) {
                margin: 0 0 8px 0;
              }
            `}
            id={`text-cart-item-qty-not-available-${id}`}
          >
            {MSG_CART_ITEM_INVALID}
          </p>
        )}
        <AnimationContainer
          show={showUndo || showMove}
          shouldDisappear={shouldDisappear}
          id={`cart-item-animation-container-${id}`}
        >
          {showUndo ? (
            <>
              <p
                css={css`
                  margin: 0 0 8px 0;
                  font-size: 1.4rem;
                `}
                id={`text-cart-removed-${id}`}
              >
                {MSG_CART_ITEM_REMOVED}
              </p>
              <button
                className="anchor-animated"
                data-testid={`btn-cart-item-undo-${id}`}
                id={`btn-cart-item-undo-${id}`}
                type="button"
                onClick={handleUndo}
                css={css`
                  font-size: 1.4rem;
                `}
              >
                {TXT_CART_ITEM_UNDO}
              </button>
            </>
          ) : showMove ? (
            <div
              css={css`
                display: grid;
                grid-auto-flow: column;
                grid-gap: 16px;
              `}
            >
              <p
                id={`text-cart-moved-${id}`}
                css={css`
                  margin: 0;
                  color: ${colors.WHITE};
                  font-size: 1.6rem;
                  font-weight: 700;
                `}
              >
                {MSG_MOVED_TO_WISHLIST}
              </p>
              <Icon name="checkmark" color={colors.WHITE} size={16} />
            </div>
          ) : null}
        </AnimationContainer>
      </div>
      {isMobile && (
        <QuickAddMobile
          isActive={isQuickAddMobileOpen}
          productRef={productRef}
          colorRef={colorRef}
          originalSelectedColor={colorRef}
          onClose={() => setIsQuickAddMobileOpen(false)}
        />
      )}
    </CartItemContainer>
  );
};
