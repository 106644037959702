import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
  InstantSearch,
  Configure,
  InstantSearchSSRProvider,
  InstantSearchServerState,
  useInstantSearch,
  useSortBy,
  useSearchBox,
  useRange,
} from 'react-instantsearch';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';

import { RootState } from '../../../store/rootReducer';
import { customSearchClient as searchClient } from '../../api/search';
import { indices } from '../constants';
import { urlToSearchState, createURL, hasAlgoliaInPage } from '../utils';
import { sortIndices } from '../../sorting/constants';

const OnRouteChange: React.FC = () => {
  const { setUiState } = useInstantSearch();
  const indexName = indices.MAIN;
  const { pathname, search } = useLocation();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const elementId = useSelector((state: RootState) => state.catalog.lastPositionId) ?? '';

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (isFirstRender === false && hasAlgoliaInPage(pathname)) {
      setUiState({ [indexName]: urlToSearchState(pathname, search) });
    }

    if (elementId !== '') {
      timer = setTimeout(() => {
        const element = document.getElementById(elementId);
        element?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [pathname]);

  return null;
};

const VirtualWidgets: React.FC = () => {
  useSortBy({ items: sortIndices });
  useRange({ attribute: 'storePrice' });
  useSearchBox();
  return null;
};

type Props = {
  serverState?: Partial<InstantSearchServerState>;
};

export const SearchHandler: React.FC<PropsWithChildren<Props>> = ({ children, serverState }) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const indexName = indices.MAIN;
  const selection = pathname.includes('selection')
    ? pathname.split('/').filter(Boolean).slice(1)
    : undefined;

  return (
    <InstantSearchSSRProvider {...serverState}>
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        initialUiState={{
          [indexName]: urlToSearchState(pathname, search),
        }}
        onStateChange={({ uiState, setUiState }) => {
          navigate(`${pathname}${createURL(uiState, indexName)}`, { replace: true });
          setUiState(uiState);
        }}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <OnRouteChange />
        <Configure
          clickAnalytics
          attributesToRetrieve={['*', '-storesAvailable']}
          ruleContexts={selection ? selection : undefined}
        />
        <VirtualWidgets />
        {children}
      </InstantSearch>
    </InstantSearchSSRProvider>
  );
};
