import * as React from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import { colors, Icon } from '../../../design-system';
import locale from '../locale';
import { addToWishlist, removeFromWishlist, resetErrorMessage } from '../actions';
import { RootState } from '../../../store/rootReducer';
import { wishlistItemsSelector } from '../selectors';
import { Product } from '../../product/types';
import { CatalogProduct } from '../../catalog/types';
import { isItGiftCard } from '../../product/utils';
import styled from '@emotion/styled';
import { zIndex } from '../../common/constants';

type Props = {
  objectID: string;
  productRef: string;
  colorRef: string;
  product: Product | CatalogProduct;
  isQuickAddMobile?: boolean;
};

const StyledWishlistCtaForQuickAddMobile = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: left;
  align-items: center;
  .add-to-wishlist__description {
    font-size: 1.2rem;
    line-height: 1.6rem;
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
    &.add-to-wishlist__description--visible {
      opacity: 1;
    }
  }
`;
const StyledWishlistButton = styled.button`
  cursor: pointer;
  display: grid;
  transition: background-color 0.3s ease-in-out;
  align-items: center;
  justify-content: center;
  grid-gap: 8px;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  background-color: ${colors.WHITE};
  border: 1px solid ${colors.BLACK};
  @media (hover: hover) {
    &:hover {
      background-color: ${colors.LIGHT2};
    }
  }
`;
const StyledNotification = styled.div`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  font-size: 1.2rem;
  transition: opacity 0.3s ease-in-out;
  background: ${colors.BACKGROUND};
  border: 1px solid ${colors.LIGHT};
  border-radius: 3px;
  text-align: center;
  z-index: ${zIndex.TOOLTIP};
  width: max-content;
  opacity: 0;
  &.add-to-wishlist__notification--visible {
    opacity: 1;
  }
  &:before {
    content: '';
    position: absolute;
    z-index: ${zIndex.TOOLTIP};
    bottom: -6px;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${colors.LIGHT};
  }
  &:after {
    content: '';
    position: absolute;
    z-index: ${zIndex.TOOLTIP + 1};
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid ${colors.BACKGROUND};
  }
`;

const RECENT_MS = 1000;

const WishlistCta = ({ objectID, productRef, colorRef, product, isQuickAddMobile }: Props) => {
  const dispatch = useDispatch();
  const [isRecentlyAdded, setIsRecentlyAdded] = React.useState(false);
  const [isAdded, setIsAdded] = React.useState(false);

  const wishlistItems = useSelector((state: RootState) => wishlistItemsSelector(state));
  const isInWishlist = wishlistItems.some(
    (item) => item.productRef === productRef && item.colorRef === colorRef
  );
  const { errMsg, isFetching } = useSelector((state: RootState) => state.wishlist);

  const onClick = () => {
    if (isInWishlist && !isFetching) {
      setIsAdded(false);
      dispatch(removeFromWishlist({ productRef, colorRef }, product) as unknown as UnknownAction);
    }
    if (!isInWishlist && !isFetching) {
      setIsRecentlyAdded(true);
      setIsAdded(true);
      dispatch(addToWishlist({ productRef, colorRef }, product) as unknown as UnknownAction);
      setTimeout(() => {
        setIsRecentlyAdded(false);
      }, RECENT_MS);
    }
  };

  React.useEffect(() => {
    dispatch(resetErrorMessage());
    if (isInWishlist) {
      setIsAdded(true);
    } else {
      setIsAdded(false);
    }
  }, [isInWishlist, errMsg]);

  const isGiftCard = isItGiftCard(productRef);

  if (isQuickAddMobile) {
    return (
      <StyledWishlistCtaForQuickAddMobile>
        <StyledWishlistButton
          className="add-to-wishlist__button"
          data-testid={`btn-wishlist-${objectID}`}
          id={`btn-wishlist-${objectID}`}
          onClick={onClick}
        >
          <Icon name={isAdded ? 'heartFull' : 'heart'} size={24} />
        </StyledWishlistButton>
        <p
          className={`add-to-wishlist__description ${!isAdded || isRecentlyAdded ? 'add-to-wishlist__description--visible' : ''}`}
        >
          {isAdded ? locale.ADDED_TO_WISHLIST : locale.ADD_TO_WISHLIST}
        </p>
      </StyledWishlistCtaForQuickAddMobile>
    );
  }

  if (isGiftCard) {
    return null;
  }

  return (
    <>
      <StyledNotification
        className={`add-to-wishlist__notification ${isRecentlyAdded ? 'add-to-wishlist__notification--visible' : ''}`}
      >
        {locale.ADDED_TO_WISHLIST}
      </StyledNotification>
      <StyledWishlistButton
        className="add-to-wishlist__button"
        data-testid={`btn-wishlist-${objectID}`}
        id={`btn-wishlist-${objectID}`}
        onClick={onClick}
      >
        <Icon name={isAdded ? 'heartFull' : 'heart'} size={24} />
      </StyledWishlistButton>
    </>
  );
};

export default WishlistCta;
