import React from 'react';
import { PrismicRichText } from '@prismicio/react';

import { Box, Icon, Text, Divider } from '../../../design-system';
import { CmsUspContent } from '../../cms/types';
import { KNOW_MORE } from '../locale';
import { Link } from 'react-router-dom';
import { getFormatedLinkForReactRouterLink } from '../../TopTen/utils';
import { hasValidCmsText } from '../utils';

type Props = {
  item: CmsUspContent;
};

const UspModal = ({ item }: Props) => {
  const { icon, link, points, subtitle, title } = item ?? {};

  return (
    <Box>
      <Box
        display="grid"
        alignItems="center"
        justifyContent="center"
        gridGap="xs"
        textAlign="center"
      >
        {icon && (
          <Box mx="auto" mb="m">
            <Icon name={icon} size={40} />
          </Box>
        )}
        {title && (
          <Text preset="subheading" fontWeight="bold">
            {title}
          </Text>
        )}
        {subtitle && <Text color="GREY">{subtitle}</Text>}
      </Box>
      <Divider color="BLACK" my="m" />
      <Box>
        {hasValidCmsText(points) && (
          <Box mt="m" mb="m">
            <PrismicRichText field={points} />
          </Box>
        )}
        {link && (
          <Box ml="l" mb="xl" fontSize="1.4rem">
            <Link
              className="anchor-animated"
              data-testid={`usp-modal-link-${name}`}
              id="usp-modal-link"
              to={getFormatedLinkForReactRouterLink(link)}
            >
              {KNOW_MORE}
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UspModal;
