import { DynamicButtonStatus } from '../../design-system';
import { PickupStation } from '../pickup/types';

export type State = {
  errMsg: string;
  isFetching: boolean;
  couponCodeIsLoading: boolean;
  showFeedback: boolean;
  cart: Cart;
  step: CartStep;
  ctaState: DynamicButtonStatus;
  shippingTypes: ShippingPrice[];
  paypalECS: {
    email: string;
    firstName: string;
    orderNumber: string;
  };
};

export type Cart = {
  cartId: string;
  items: CartItem[];
  couponCode: string | null;
  totalCouponAmount: number;
  couponAccepted: boolean;
  shippingAmount: number;
  freeShipping: boolean;
  rejectCouponMessage: string;
  totalDiscount: number;
  notifications: CartNotification[];
  originalBaseTotal: number;
  total: number;
  isValidated: boolean;
  isGiftEnabled: boolean;
  isDigital: boolean;
  shippingType?: ShippingTypes;
  storeId?: string;
  pickupStation?: PickupStation;
};

export enum CartStep {
  LISTING,
  DELIVERY,
  PAYMENT,
  SUCCESS,
}

export type CartItem = {
  productRef: string;
  colorRef: string;
  sku: string;
  productName: string;
  colorLabel: string;
  collectionName: string;
  detailedColorLabel: string;
  rowBaseTotal: number;
  rowTotal: number;
  size: string;
  bandSize: string;
  cupSize: string;
  quantity: number;
  status: boolean;
  stockQuantity: {
    stockQuantity: number;
  };
  promotionLabel?: string;
  promotionPercentage?: string;
  freeProduct?: boolean;
  giftMessage?: string;
  promoMessages?: string[];
  itemCouponAmount?: number;
  promoPrice?: number;
  isDigital?: boolean;
  tax?: number;
  gcFields?: {
    date: string;
    itemId: string;
    email: string;
    from: string;
    to: string;
    message: string;
    price: number;
  };
};

export type CartNotification = {
  type: NotificationType;
  title: string;
  message: string;
};

export type ShippingPrice = {
  label: ShippingTypes;
  amount: number;
};

export enum NotificationType {
  Error = 'Error',
  Offer = 'Offer',
  Info = 'Info',
  Misc = 'Misc',
}

export enum ShippingTypes {
  HOME = 'HOME',
  STORE = 'STORE',
  PICKUP = 'PICKUP',
}
