import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';

import { RootState } from '../../store/rootReducer';
import types from './actionTypes';
import { getBFFData, Mutations } from '../api';
import { refreshIfNeeded } from '../cart/utils';
import { ECart } from './types';
import { Product } from '../product/types';

export const setEresaRequestLoading = () => action(types.SET_ERESA_REQUEST_LOADING);
export const setEresaRequestError = (payload: boolean) =>
  action(types.SET_ERESA_REQUEST_ERROR, payload);
export const requestECartSuccess = (payload: ECart) => action(types.REQUEST_ECART_SUCCESS, payload);

type AsyncECartActions = ActionType<
  typeof requestECartSuccess | typeof setEresaRequestLoading | typeof setEresaRequestError
>;

type EresaAction = ActionType<typeof setEresaMainProduct | typeof setEresaAdditionnalList>;
export type ECartActions = AsyncECartActions | EresaAction;

export type ProductEresa = {
  productRef: string;
  colorRef: string;
  sku: string;
};

export const addToECart = (product: ProductEresa) => {
  return async (dispatch: Dispatch<AsyncECartActions>, getState: () => RootState) => {
    dispatch(setEresaRequestLoading());
    const response = await getBFFData(Mutations.addToECart, { product });
    if (response.ok) {
      dispatch(requestECartSuccess(response.data.addToECart));

      await new Promise((resolve) => setTimeout(resolve, 2000));
    } else {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      dispatch(setEresaRequestError(true));
    }
    refreshIfNeeded(getState());
  };
};

export const bulkRemoveEreservation = (products: ProductEresa[]) => {
  return async (dispatch: Dispatch<AsyncECartActions>) => {
    dispatch(setEresaRequestLoading());
    const response = await getBFFData(Mutations.bulkECartRemove, { products });
    if (response.ok) {
      dispatch(requestECartSuccess(response.data.deleteECartProducts));
    } else {
      dispatch(setEresaRequestError(true));
    }
  };
};

export const setEresaAdditionnalList = (payload: Product[] | []) =>
  action(types.SET_ADDITIONNAL_LIST_ERESA, payload);

export const setEresaMainProduct = (payload: Product) =>
  action(types.SET_MAIN_PRODUCT_ERESA, payload);
