import React, { useRef } from 'react';
import styled from '@emotion/styled';

import ZoomImage from '../../../../shared/design-system/slider/components/ZoomImage';
import { ModelPDP, Product } from '../types';
import { getImagesList, productImageWidths } from '../utils';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { useContainerSize } from '../../common/hooks/useContainerSize';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import { ModelSizes } from './ModelSizes';

type Props = {
  imagePositions: number[];
  productRef: string;
  colorRef: string;
  productName: string;
  colorLabel: string;
  product: Product;
  videoUrl?: string;
  model?: ModelPDP[] | null;
};

const StyledImagesMozaic = styled.div<{ height: number }>`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr;
  position: relative;

  .image__container {
    width: 100%;
    position: relative;
  }

  .iframe-container {
    width: 100%;
    height: ${(props) => props.height}px;
  }
`;

export function ImagesMozaic({
  productRef,
  colorRef,
  productName,
  colorLabel,
  videoUrl,
  model = null,
  imagePositions = [],
}: Props) {
  const { isMobile, isTablet, isDesktop } = useMediaQueries();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const container = useContainerSize(containerRef, isMobile, isTablet, isDesktop);
  const ZOOM_LEVEL = 4;
  const images = getImagesList({
    imagePositions,
    productRef,
    productName,
    colorRef,
    colorLabel,
    width: productImageWidths.MOSAIC,
    zoomLevel: ZOOM_LEVEL,
  });
  return (
    <StyledImagesMozaic ref={containerRef} height={(((container.width - 8) / 2) * 4) / 3}>
      {images.map((image, index) => {
        return (
          <div className="image__container" key={`${image.default}-${index}`}>
            <ZoomImage
              src={image.default}
              srcLarge={image.zoomed}
              srcThumbnail={image.thumbnail}
              zoom={ZOOM_LEVEL}
              isMozaicPictures
            />
            {model && (imagePositions?.length ?? 0) > 0 && (
              <ModelSizes imagePositions={imagePositions} model={model} index={index} />
            )}
          </div>
        );
      })}
      {videoUrl && (
        <div className="iframe-container">
          <iframe
            data-src={videoUrl}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="lazyload"
          />
        </div>
      )}
    </StyledImagesMozaic>
  );
}
