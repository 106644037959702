import React from 'react';
import { Link } from 'react-router-dom';

import { CmsCatalogHighlightItem } from '../../../cms/types';
import { useProductsInfos } from '../../../common/hooks/useProductsInfos';
import { ProductCard } from '../../../catalog/components/ProductCard';
import { getFormatedLinkForReactRouterLink } from '../../../TopTen/utils';
import { CatalogProduct } from '../../../catalog/types';

type Props = {
  item: CmsCatalogHighlightItem;
};

export const BannerItem = ({ item }: Props) => {
  const { hits, isLoading } = useProductsInfos([item.banner_product_ref ?? '']);

  if ((isLoading || !hits?.length) && item.banner_product_ref) {
    return null;
  }

  const product = {
    ...hits[0],
    objectID: `${hits[0]?.productRef}/${hits[0]?.colorRef}`,
    rcFilter: `${hits[0]?.productRef}-${hits[0]?.colorRef}`,
  };

  return (
    <div>
      {item.banner_product_ref?.length ? (
        <ProductCard hit={product as unknown as CatalogProduct} />
      ) : (
        <Link
          data-testid="highlighted-banner-item-link"
          to={getFormatedLinkForReactRouterLink(item.banner_link)}
        >
          <img
            className="item-visual__image"
            src={item.banner_image?.url}
            alt={item.banner_image?.alt ?? ''}
          />
        </Link>
      )}
    </div>
  );
};
