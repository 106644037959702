import * as React from 'react';
import { css } from '@emotion/core';
import { PrismicRichText } from '@prismicio/react';

import { useSelector } from 'react-redux';
import { Icon, colors, breakpoints, durations } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { formatPrice } from '../../common/utils';
import { ShippingTypes } from '../../cart/types';
import { CmsText } from '../../cms/types';
import { LBL_FREE } from '../locale';
import { hasValidCmsText } from '../../home/utils';

export const DeliveryBlock = () => {
  const { cart } = useSelector((state: RootState) => state.cart);
  const { total, shippingAmount, shippingType } = cart;

  const cmsContent = useSelector((state: RootState) => state.cms.cart);

  const requiredAmount =
    shippingType === ShippingTypes.PICKUP
      ? (cmsContent?.pickup_shipping_promo_amount ?? 0)
      : (cmsContent?.shipping_promo_amount ?? 0);

  const messageFreeDelivery = cmsContent?.shipping_promo_message_free_delivery ?? '';
  const messageFreeDeliveryStore = cmsContent?.shipping_promo_message_pick_up ?? '';
  const totalWithoutShipping = total - shippingAmount;
  const remainingAmount = requiredAmount - totalWithoutShipping;

  const achievedPercentage = (totalWithoutShipping * 100) / requiredAmount;

  const [messageRemaining, setMessageRemaining] = React.useState<CmsText>([]);

  React.useEffect(() => {
    const cmsContentCopy = { ...cmsContent };
    const newMessageRemaining = cmsContentCopy?.shipping_promo_message_remaining.map((element) => {
      const newText =
        formatPrice(remainingAmount).length === 6
          ? element.text.replace('$remain', ` ${formatPrice(remainingAmount)}`) // add extra space to prevent gap on rich text spans index
          : element.text.replace('$remain', formatPrice(remainingAmount));
      return { ...element, text: newText };
    });
    setMessageRemaining(newMessageRemaining);
  }, [remainingAmount]);

  return (
    <div
      id="cart-delivery-block"
      css={css`
        display: flex;
        justify-content: space-between;
        margin: 16px 16px 0 16px;
        @media (min-width: ${breakpoints.S - 26}px) {
          // to match with ipad mini width
          justify-content: flex-start;
        }
        @media (min-width: ${breakpoints.L}px) {
          margin: 16px 72px 0 72px;
        }
      `}
    >
      <div
        id="cart-delivery-block-left"
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          border: 1px solid ${colors.LIGHT};
          border-radius: 4px;
          width: calc(50% - 4px);
          margin-right: 4px;
          padding: 16px;
          height: 145px;
          @media (min-width: ${breakpoints.S - 26}px) {
            height: auto;
          }
        `}
      >
        {(hasValidCmsText(messageRemaining) || hasValidCmsText(messageFreeDelivery)) && (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              margin-right: 8px;
              @media (min-width: ${breakpoints.S - 26}px) {
                flex-direction: row;
                align-items: center;
                width: 250px;
                margin-right: 0;
              }
            `}
          >
            <Icon name="deliveryGift" size={22} />
            <div
              css={css`
                margin-top: 12px;

                @media (min-width: ${breakpoints.S - 26}px) {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  min-width: 125px;
                  max-width: 192.5px;
                  margin: 0 0 0 8px;
                }
              `}
            >
              <PrismicRichText
                field={remainingAmount > 0 ? messageRemaining : messageFreeDelivery}
              />
            </div>
          </div>
        )}
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (min-width: ${breakpoints.S - 26}px) {
              width: 160px;
              margin: 12px 0;
            }
          `}
        >
          <div
            css={css`
              display: flex;
              width: 100px;
              @media (min-width: ${breakpoints.S - 26}px) {
                width: 132px;
              }
            `}
          >
            <div
              css={css`
                height: 4px;
                width: ${achievedPercentage < 100 ? achievedPercentage : 100}px;
                background: ${colors.MAIN_SUCCESS};
                transition: width ${durations.FOCUS_DELAY}ms ease-in-out;
                @media (min-width: ${breakpoints.S - 26}px) {
                  width: ${achievedPercentage < 100 ? achievedPercentage * 1.32 : 132}px;
                }
              `}
            />
            <div
              css={css`
                height: 4px;
                width: ${achievedPercentage > 0 && achievedPercentage < 100
                  ? 100 - achievedPercentage
                  : 0}px;
                background: ${colors.LIGHT};
                transition: width ${durations.FOCUS_DELAY}ms ease-in-out;
                @media (min-width: ${breakpoints.S - 26}px) {
                  width: ${achievedPercentage > 0 && achievedPercentage < 100
                    ? 132 - achievedPercentage * 1.32
                    : 0}px;
                }
              `}
            />
          </div>
          <div
            css={css`
              font-size: 1rem;
              margin-left: 8px;
            `}
          >
            {`${requiredAmount}€`}
          </div>
        </div>
      </div>
      <div
        id="cart-delivery-block-right"
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          border: 1px solid ${colors.LIGHT};
          border-radius: 4px;
          width: calc(50% - 4px);
          margin-left: 4px;
          padding: 16px;
          height: 145px;
          @media (min-width: ${breakpoints.S - 26}px) {
            height: auto;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            @media (min-width: ${breakpoints.S - 26}px) {
              flex-direction: row;
              align-items: center;
              width: 250px;
            }
          `}
        >
          <div
            css={css`
              display: block;
              @media (min-width: ${breakpoints.S - 26}px) {
                display: none;
              }
            `}
          >
            <Icon name={'shop'} size={22} />
          </div>
          <div
            css={css`
              display: none;
              @media (min-width: ${breakpoints.S - 26}px) {
                display: block;
              }
            `}
          >
            <Icon name={'deliveryBox'} size={22} />
          </div>
          {hasValidCmsText(messageFreeDeliveryStore) && (
            <div
              css={css`
                margin-top: 12px;

                @media (min-width: ${breakpoints.S - 26}px) {
                  margin-left: 8px;
                  margin-top: 0;
                  min-width: 135px;
                  max-width: 192.5px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              `}
            >
              <PrismicRichText field={messageFreeDeliveryStore} />
            </div>
          )}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <div
            css={css`
              width: 63px;
              height: 17px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2px 4px;
              background: rgba(54, 199, 84, 0.25);
              font-size: 1.2rem;
              color: #156700;
              @media (min-width: ${breakpoints.S - 26}px) {
                margin: 12px 0;
              }
            `}
          >
            {LBL_FREE.toUpperCase()}
          </div>
        </div>
      </div>
    </div>
  );
};
