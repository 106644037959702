import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from 'redux';
import { css } from '@emotion/core';

import { Divider, colors, breakpoints } from '../../../design-system';
import {
  MSG_CART_ITEM_UNAVAILABLE,
  TXT_CART_ITEM_REMOVE,
  MSG_CART_ITEMS_UNAVAILABLE,
  TXT_CART_ITEMS_REMOVE,
} from '../locale';
import { bulkRemove, RemoveItemParams } from '../actions';
import { CartItem } from './CartItem';
import { RootState } from '../../../store/rootReducer';
import { unavailableItemsSelector, availableItemsSelector } from '../selectors';
import { isItGiftCard } from '../../product/utils';

export const CartItemList = () => {
  const dispatch = useDispatch();

  const unavailableItems = useSelector((state: RootState) => unavailableItemsSelector(state));
  const availableItems = useSelector((state: RootState) => availableItemsSelector(state));

  const giftItemTextColor =
    useSelector((state: RootState) => state.cms.cart?.gift_text_color) ?? 'BLACK';

  const bulkDeleteParams: RemoveItemParams[] = unavailableItems.map(
    ({ productRef, colorRef, sku }) => ({
      productRef,
      colorRef,
      sku,
    })
  );

  const handleBulkDelete = () =>
    dispatch(bulkRemove(bulkDeleteParams, unavailableItems) as unknown as UnknownAction);

  return (
    <div>
      {unavailableItems.length > 0 && (
        <>
          <div
            css={css`
              border: solid 1px ${colors.ERROR};
              border-bottom: none;
              padding: 16px;
              text-align: center;
              margin-top: 16px;
            `}
          >
            <p
              css={css`
                font-size: 1.2rem;
                line-height: 1.6rem;
                color: ${colors.ERROR};
                margin-bottom: 8px;
                margin-block-start: 0;
                margin-block-end: 0;
                @media (min-width: ${breakpoints.S}px) {
                  font-size: 1.4rem;
                  line-height: 1.8rem;
                }
              `}
            >
              {unavailableItems.length > 1 ? MSG_CART_ITEMS_UNAVAILABLE : MSG_CART_ITEM_UNAVAILABLE}
            </p>
            <button
              className="anchor-animated"
              data-testid="btn-cart-bulk-delete"
              id="btn-cart-bulk-delete"
              type="button"
              onClick={handleBulkDelete}
              css={css`
                font-size: 1.4rem;
                color: ${colors.ERROR}!important;
              `}
            >
              {unavailableItems.length > 1 ? TXT_CART_ITEMS_REMOVE : TXT_CART_ITEM_REMOVE}
            </button>
          </div>
          <div
            css={css`
              border: solid 1px ${colors.ERROR};
              padding: 0 16px;
            `}
          >
            {unavailableItems.map((item, index) => {
              const id = `${item.productRef}${item.colorRef}${item.sku}`;
              return (
                <div key={id}>
                  {index > 0 && (
                    <div
                      css={css`
                        margin: 0 16px;
                        @media (min-width: ${breakpoints.S}px) {
                          margin: 0;
                        }
                      `}
                    >
                      <Divider />
                    </div>
                  )}
                  <CartItem id={id} item={item} giftItemTextColor={giftItemTextColor} />
                </div>
              );
            })}
          </div>
        </>
      )}
      {availableItems.map((item, index) => {
        const isGiftCard = isItGiftCard(item.productRef);
        const id = !isGiftCard
          ? `${item.productRef}${item.colorRef}${item.sku}`
          : `${item.productRef}${item.colorRef}${item.sku}${item?.gcFields?.itemId}`;
        return (
          <div key={id}>
            {index > 0 && (
              <div
                css={css`
                  margin: 0 16px;
                  @media (min-width: ${breakpoints.S}px) {
                    margin: 0;
                  }
                `}
              >
                <Divider />
              </div>
            )}
            <CartItem id={id} item={item} giftItemTextColor={giftItemTextColor} isAvailable />
          </div>
        );
      })}
    </div>
  );
};
