import { Size, Color, Price, ModelPDP } from './types';
import { CDN_URL } from '../api/constants';
import paths from '../routing/paths';
import { formatPrice } from '../common/utils';
import { universalSizes } from '../filters/utils';
import { TWO_LEFT, ONE_LEFT, NONE_LEFT } from './locale';
import { TPattern } from './components/PatternSelector';
import { LoadProductParams } from './actions';
import { Cart } from '../cart/types';
import { removeDiacritics } from '../store-locator/utils';

export function sortSizes(sizes: Size[]): Size[] {
  return (sizes ? [...sizes] : []).sort((a, b) => {
    const indexA = universalSizes.indexOf(a.size);
    const indexB = universalSizes.indexOf(b.size);
    if (indexA > -1 || indexB > -1) {
      return indexA - indexB;
    }
    if (a.bandSize === b.bandSize) {
      return a.cupSize?.localeCompare(b.cupSize);
    }
    return Number.parseInt(a.bandSize, 10) - Number.parseInt(b.bandSize, 10);
  });
}

export function sortColors(colors: Color[], selectedColorRef: string): Color[] {
  const selectedColorIndex = colors.findIndex((color) => color.colorRef === selectedColorRef);
  const selectedColor = colors[selectedColorIndex];
  const newColors = [...colors];
  newColors.splice(selectedColorIndex, 1);
  newColors.splice(0, 0, selectedColor);
  return newColors;
}

export function sortImagePositions(imageList: number[]): number[] {
  return imageList.sort(function (a, b) {
    return a - b;
  });
}

export function calculatePromotionPercentage(basePrice: number, promotionPrice: number): number {
  const percentage = Math.abs(Math.round(((promotionPrice - basePrice) * 100) / basePrice));
  return percentage === 100 ? 0 : percentage;
}

export function buildProductRichSnippet({
  productName,
  productRef,
  colorRef,
  imagePositions,
  productDescription,
  feedbacks,
  ratingAverage,
  ratingCount,
  pathname,
  storePrice,
}) {
  const feedbacksWithRating = (feedbacks ?? []).filter((feedback) => feedback.rating);
  const data = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: productName,
    image: [
      ...imagePositions.slice(0, 4).map((position) =>
        getProductImageLink({
          productRef,
          colorRef,
          productName,
          position,
          width: 100,
        })
      ),
    ],
    description: productDescription,
    mpn: 'EAN13',
    sku: productRef,
    brand: {
      '@type': 'Thing',
      name: 'Darjeeling',
    },
    review:
      feedbacksWithRating.length > 0
        ? feedbacksWithRating.slice(0, 3).map((feedback) => ({
            '@type': 'Review',
            reviewRating: {
              '@type': 'Rating',
              ratingValue: feedback.rating,
              bestRating: '5',
            },
            author: {
              '@type': 'Person',
              name: feedback.author,
            },
          }))
        : undefined,
    aggregateRating:
      ratingAverage && ratingCount
        ? {
            '@type': 'AggregateRating',
            ratingValue: ratingAverage,
            reviewCount: ratingCount,
          }
        : undefined,
    offers: {
      '@type': 'Offer',
      url: `https://www.darjeeling.fr${pathname}`,
      priceCurrency: 'EUR',
      price: storePrice,
      itemCondition: 'https://schema.org/UsedCondition',
      availability: 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: 'Chantelle Lingerie',
      },
    },
  };

  return JSON.stringify(data);
}

export const getProductPrice = ({
  storePrice,
  originalPrice,
}: {
  storePrice: number;
  originalPrice: number;
}): Price => {
  const formattedOriginalPrice = formatPrice(originalPrice);
  const formattedStorePrice = formatPrice(storePrice);
  const hasPromotion = originalPrice !== storePrice;

  return {
    storePrice: formattedStorePrice,
    originalPrice: formattedOriginalPrice,
    hasPromotion,
  };
};

export const getIsSizeAvailable = (size: Size): boolean =>
  Boolean(size.sku) && size.stockQuantity > 0 && size.storePrice > 0;

export function getLinkName(productName: string): string {
  return typeof productName === 'string'
    ? removeDiacritics(productName.replace(/\s+|'/g, '-').toLowerCase())
    : '';
}

export function getProductLink({
  productRef,
  colorRef,
  productName,
  colorLabel,
}: {
  productRef: string;
  colorRef: string;
  productName: string;
  colorLabel: string;
}): string {
  return `${paths.PRODUCT}/${getLinkName(productName)}-${getLinkName(
    colorLabel
  )}-${productRef}-${colorRef}`;
}

export function getProductImageLink({
  productRef,
  colorRef,
  productName,
  position,
  width,
}: {
  productRef: string;
  colorRef: string;
  productName: string;
  position: number;
  width?: number;
}): string {
  return `${CDN_URL}/product/${getLinkName(productName)}-${productRef}-${colorRef}-${position}.jpg${
    width ? `?w=${width}` : ''
  }`;
}

export function getColorImageLink(colorRef: string): string {
  return `${CDN_URL}/color/${colorRef}.jpg?w=24`;
}

export function getProductImageLinkWebP({
  productRef,
  productName,
  colorRef,
  position,
  width,
}: {
  productRef: string;
  productName: string;
  colorRef: string;
  position: number;
  width?: number;
}): string {
  return getProductImageLink({
    productRef,
    colorRef,
    productName,
    position,
    width,
  }).replace('.jpg', '.webp');
}

export function getImageAlt({
  productName,
  colorLabel,
  position,
}: {
  productName: string;
  colorLabel: string;
  position: number;
}): string {
  return `${colorLabel} ${productName} ${position}`;
}

export type ProductImage = {
  jpg: string;
  webp: string;
  alt: string;
};

export function getProductImage({
  productRef,
  productName,
  colorRef,
  colorLabel,
  position,
  width,
}: {
  productRef: string;
  productName: string;
  colorRef: string;
  colorLabel: string;
  position: number;
  width: number;
}): ProductImage {
  return {
    jpg: getProductImageLink({
      productRef,
      colorRef,
      productName,
      position,
      width,
    }),
    webp: getProductImageLinkWebP({
      productRef,
      colorRef,
      productName,
      position,
      width,
    }),
    alt: getImageAlt({ productName, colorLabel, position }),
  };
}

export const productImageWidths = {
  SLIDER: 768,
  MOSAIC: 448,
  ZOOMED: 1152,
  THUMBNAIL: 72,
  CART: 104,
  ECART: 125,
  COLOR_DRAWER: 152,
  CATALOG_MOBILE: 376,
  CATALOG_TABLET: 304,
  CATALOG_DESKTOP: 430,
  CATALOG_LARGE_DESKTOP: 512,
  PLACEHOLDER: 50,
} as const;

export type CatalogImageSet = {
  mobile: ProductImage;
  tablet: ProductImage;
  desktop: ProductImage;
  largeDesktop: ProductImage;
  placeholder: ProductImage;
};

export function getCatalogImageSet({
  productRef,
  productName,
  colorRef,
  colorLabel,
  position,
}: {
  productRef: string;
  productName: string;
  colorRef: string;
  colorLabel: string;
  position: number;
}): CatalogImageSet {
  return {
    mobile: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.CATALOG_MOBILE,
    }),
    tablet: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.CATALOG_TABLET,
    }),
    desktop: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.CATALOG_DESKTOP,
    }),
    largeDesktop: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.CATALOG_LARGE_DESKTOP,
    }),
    placeholder: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.PLACEHOLDER,
    }),
  };
}

export function getCatalogImageList({
  productRef,
  productName,
  colorRef,
  colorLabel,
}: {
  productRef: string;
  productName: string;
  colorRef: string;
  colorLabel: string;
}): CatalogImageSet[] {
  return [
    getCatalogImageSet({
      productRef,
      colorRef,
      productName,
      position: 1,
      colorLabel,
    }),
    getCatalogImageSet({
      productRef,
      colorRef,
      productName,
      position: 2,
      colorLabel,
    }),
  ];
}

export function getImagesList({
  imagePositions,
  productRef,
  productName,
  colorRef,
  colorLabel,
  width,
  zoomLevel,
}: {
  imagePositions: number[];
  productRef: string;
  productName: string;
  colorRef: string;
  colorLabel: string;
  width: number;
  zoomLevel: number;
}): ProductImageList {
  const positions = (pos: number[]) => {
    if (pos.length > 12) {
      return pos.slice(0, 12);
    }
    if (pos.length > 0) {
      return pos;
    }
    return [1];
  };

  return positions(imagePositions).map((position) => ({
    default: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width,
    }),
    zoomed: getProductImage({
      productRef,
      colorRef,
      colorLabel,
      productName,
      position,
      width: width * zoomLevel,
    }),
    thumbnail: getProductImage({
      productRef,
      colorRef,
      colorLabel,
      productName,
      position,
      width: productImageWidths.PLACEHOLDER,
    }),
  }));
}

export type ProductImageList = {
  default: ProductImage;
  zoomed: ProductImage;
  thumbnail: ProductImage;
}[];

export function getImageList({
  imagePositions = [],
  productRef,
  productName,
  colorRef,
  colorLabel,
}: {
  imagePositions?: number[];
  productRef: string;
  productName: string;
  colorRef: string;
  colorLabel: string;
}): ProductImageList {
  const positions = (pos: number[]) => {
    if (pos?.length > 12) {
      return pos.slice(0, 12);
    }
    if (pos?.length > 0) {
      return pos;
    }
    return [1];
  };

  return positions(imagePositions).map((position) => ({
    default: getProductImage({
      productRef,
      productName,
      colorRef,
      colorLabel,
      position,
      width: productImageWidths.SLIDER,
    }),
    zoomed: getProductImage({
      productRef,
      colorRef,
      colorLabel,
      productName,
      position,
      width: productImageWidths.ZOOMED,
    }),
    thumbnail: getProductImage({
      productRef,
      colorRef,
      colorLabel,
      productName,
      position,
      width: productImageWidths.THUMBNAIL,
    }),
  }));
}

export function getSizeMessage(size?: Size) {
  if (!size || size?.stockQuantity > 2) {
    return '';
  }
  if (size?.stockQuantity === 2) {
    return TWO_LEFT;
  }
  if (size?.stockQuantity === 1) {
    return ONE_LEFT;
  }
  return NONE_LEFT;
}

export function getSizeLabel(size: Size) {
  const { bandSize, cupSize, size: universalSize } = size;
  let formatedUniversalSize = universalSize;
  if (Array.isArray(universalSize)) {
    formatedUniversalSize = universalSize.join('/');
  }
  return formatedUniversalSize || `${bandSize}${cupSize}`;
}

function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export type TGiftCardErrors = {
  amount?: string;
  customAmount?: string;
  senderName?: string;
  receiverName?: string;
  receiverEmail?: string;
  date?: string;
  message?: string;
};

export type TVerifyGiftCardErrors = {
  isAnotherAmount?: boolean;
  giftCardAmount?: string;
  senderName: string;
  receiverName: string;
  receiverEmail: string;
  dateOfSending: string;
  message?: string;
  emailPattern?: TPattern;
};

export const verifyGiftCardInfos = (giftCardInfos: TVerifyGiftCardErrors) => {
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const dateInSixMonths = new Date(new Date().setMonth(new Date().getMonth() + 6));

  let errMsg: TGiftCardErrors = {};

  if (!giftCardInfos) {
    return errMsg;
  }

  if (giftCardInfos.isAnotherAmount === false && !giftCardInfos.giftCardAmount) {
    errMsg = { ...errMsg, amount: 'Choisissez votre montant' };
  }
  if (
    (giftCardInfos.isAnotherAmount && giftCardInfos.giftCardAmount === '') ||
    (giftCardInfos.giftCardAmount && parseFloat(giftCardInfos.giftCardAmount) < 1) ||
    (giftCardInfos.giftCardAmount && parseFloat(giftCardInfos.giftCardAmount) > 300) ||
    (giftCardInfos.giftCardAmount && giftCardInfos.giftCardAmount.match(/[e\\+\\-]/g))
  ) {
    errMsg = { ...errMsg, customAmount: 'Vous devez choisir un montant entre 1€ et 300€.' };
  }
  if (giftCardInfos.senderName === '') {
    errMsg = { ...errMsg, senderName: 'Vous devez entrer un nom.' };
  }
  if (giftCardInfos.receiverName === '') {
    errMsg = { ...errMsg, receiverName: 'Vous devez entrer un nom.' };
  }
  if (giftCardInfos.receiverEmail === '') {
    errMsg = { ...errMsg, receiverEmail: 'Vous devez entrer une adresse email valide.' };
  }
  if (giftCardInfos.receiverEmail !== '' && !validateEmail(giftCardInfos.receiverEmail)) {
    errMsg = { ...errMsg, receiverEmail: "L'email n'est pas valide." };
  }
  if (new Date(giftCardInfos.dateOfSending) < today) {
    errMsg = { ...errMsg, date: "La date d'envoi est invalide." };
  }
  if (new Date(giftCardInfos.dateOfSending) > dateInSixMonths) {
    errMsg = { ...errMsg, date: "La date d'envoi ne doit pas être dans plus de 6 mois." };
  }
  if (giftCardInfos.message && giftCardInfos.message.length > 300) {
    errMsg = { ...errMsg, message: 'Votre message doit faire moins de 300 caractères.' };
  }

  return errMsg;
};

const GIFT_CARD_PRODUCT_REF = '9500013';

export function getProductIdsFromUrl(path): LoadProductParams {
  const id = path.split('/').filter(Boolean).pop();
  const [colorRef, productRef] = id.split('-').reverse();
  return { productRef, colorRef };
}

export function isItGiftCard(productRef: string) {
  return productRef === GIFT_CARD_PRODUCT_REF;
}

export function sizeNoLongerAvailable(
  cart: Cart,
  productRef: string,
  colorRef: string,
  sizeVariants: Size[]
): string[] {
  const result = [] as string[];
  cart &&
    cart.items.forEach((item) => {
      if (item.productRef === productRef && item.colorRef === colorRef) {
        sizeVariants.forEach((itemSize) => {
          if (item.sku === itemSize.sku && itemSize.stockQuantity - item.quantity <= 0) {
            result.push(item.sku);
          }
        });
      }
    });
  return result;
}

export const findQtyOnCartItems = (
  cart: Cart,
  productRef: string,
  colorRef: string,
  size: Size
): number => {
  let cartQty = 0;
  cart &&
    cart.items.forEach((item) => {
      if (
        item.productRef === productRef &&
        item.colorRef === colorRef &&
        size.bandSize === item.bandSize &&
        size.cupSize === item.cupSize &&
        size.size === item.size
      ) {
        cartQty = item.quantity;
      }
    });
  return cartQty;
};

export const findSizeByModel = (model: ModelPDP[]): ModelPDP[] | null => {
  if (model.length) {
    const data: ModelPDP[] = [];
    model
      .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))
      .forEach((element: ModelPDP) => {
        if (!data?.find((el) => el.name === element.name)) {
          data.push(element);
        }
      });
    return data;
  }
  return null;
};
