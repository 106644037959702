import React from 'react';
import { useSelector } from 'react-redux';
import { PrismicRichText } from '@prismicio/react';
import { css } from '@emotion/core';

import { RootState } from '../../../store/rootReducer';
import { breakpoints, colors } from '../../../design-system';
import { hasValidCmsText } from '../../home/utils';

export const DescriptionBannerWithPicture = () => {
  const {
    description_banner_title,
    description_banner_title_font_family,
    description_banner_title_color,
    description_banner_subtitle_font_family,
    description_banner_subtitle_color,
    description_banner_title_font_size_desktop,
    description_banner_title_font_size_mobile,
    description_banner_subtitle,
    description_banner_subtitle_font_size_desktop,
    description_banner_subtitle_font_size_mobile,
    description_banner_content,
    description_banner_content_color,
    description_banner_faq_link_text,
    description_banner_faq_link_text_color,
    description_banner_link_target,
    description_banner_image_desktop,
    description_banner_image_mobile,
    description_banner_background_color,
    description_banner_is_active_on_desktop,
    description_banner_is_active_on_mobile,
  } = useSelector((state: RootState) => state.cms.catalog) ?? {};

  return (
    <div
      css={css`
        width: 100%;
        height: 297px;
        display: ${description_banner_is_active_on_desktop ? 'flex' : 'none'};
        flex-direction: row;
        @media (max-width: ${breakpoints.S - 1}px) {
          display: ${description_banner_is_active_on_mobile ? 'flex' : 'none'};
          flex-direction: column-reverse;
          height: auto;
        }
        @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.L}px) {
          margin-top: 16px;
        }
      `}
    >
      <div
        css={css`
          width: 50%;
          height: 297px;
          padding: 16px 120px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: left;
          background-color: ${description_banner_background_color ?? colors.WHITE};
          @media (max-width: ${breakpoints.S - 1}px) {
            height: auto;
            width: 100%;
            padding: 32px 16px;
          }
          @media (min-width: ${breakpoints.S}px) and (max-width: ${breakpoints.L}px) {
            padding: 0 16px;
          }
        `}
      >
        {description_banner_title ? (
          <h1
            css={css`
              width: 100%;
              margin-block-start: 0;
              margin-block-end: 0;
              font-family: ${description_banner_title_font_family ?? 'Ogg-text'};
              font-size: ${description_banner_title_font_size_desktop ?? '40px'};
              color: ${description_banner_title_color ?? colors.BLACK};
              font-weight: 700;
              line-height: 110%;
              @media (max-width: ${breakpoints.S - 1}px) {
                font-size: ${description_banner_title_font_size_mobile ?? '30px'};
              }
            `}
          >
            {description_banner_title}
          </h1>
        ) : null}
        {description_banner_subtitle ? (
          <p
            css={css`
              font-size: ${description_banner_subtitle_font_size_desktop ?? '20px'};
              font-family: ${description_banner_subtitle_font_family ?? 'Ogg-text'};
              color: ${description_banner_subtitle_color ?? colors.BLACK};
              line-height: 110%;
              font-weight: 700;
              margin-block-start: 0;
              margin-block-end: 0;
              @media (max-width: ${breakpoints.S - 1}px) {
                font-size: ${description_banner_subtitle_font_size_mobile ?? '20px'};
              }
            `}
          >
            {description_banner_subtitle}
          </p>
        ) : null}
        {hasValidCmsText(description_banner_content) && (
          <div
            css={css`
              margin-top: 16px;
              color: ${description_banner_content_color ?? colors.BLACK};
            `}
          >
            <PrismicRichText field={description_banner_content} />
          </div>
        )}
        {hasValidCmsText(description_banner_faq_link_text) ? (
          <div
            css={css`
              margin-top: 16px;
              width: max-content;
            `}
          >
            <a
              id={`catalog-anchor-to-${description_banner_link_target ?? 'catalog-faq-block'}`}
              data-testid={`catalog-anchor-to-${description_banner_link_target ?? 'catalog-faq-block'}`}
              href={`#${description_banner_link_target ?? 'catalog-faq-block'}`}
              className="anchor-animated"
              css={css`
                color: ${description_banner_faq_link_text_color ?? colors.BLACK};
              `}
            >
              <PrismicRichText field={description_banner_faq_link_text} />
            </a>
          </div>
        ) : null}
      </div>
      <div
        id="description-banner-desktop-image"
        css={css`
          display: flex;
          width: 50%;
          @media (max-width: ${breakpoints.S - 1}px) {
            display: none;
          }
        `}
      >
        <img
          src={description_banner_image_desktop?.url}
          alt="image-desktop-description-banner"
          css={css`
            object-fit: cover;
            width: 100%;
          `}
        />
      </div>
      {description_banner_image_mobile?.url ? (
        <div
          id="description-banner-mobile-image"
          css={css`
            display: none;
            @media (max-width: ${breakpoints.S - 1}px) {
              display: flex;
            }
          `}
        >
          <img
            src={description_banner_image_mobile.url}
            alt="image-mobile-description-banner"
            css={css`
              width: 100%;
            `}
          />
        </div>
      ) : null}
    </div>
  );
};
