import { css } from '@emotion/core';

export const rcSliderStyles = css`
  .rc-slider {
    position: relative;
    margin: 10px 0;
    width: 100%;
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .rc-slider-rail {
    position: absolute;
    height: 2px;
    background-color: rgb(230, 230, 230);
    border-radius: 2px;
    width: 100%;
  }

  .rc-slider-track {
    position: absolute;
    height: 2px;
    background-color: black;
    border-radius: 2px;
  }

  .rc-slider-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    background-color: black;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .rc-slider-handle:focus,
  .rc-slider-handle:hover {
    background-color: black;
  }

  .rc-slider-mark {
    position: absolute;
    top: 20px;
    text-align: center;
  }

  .rc-slider-mark-text {
    font-size: 12px;
    color: black;
  }

  .rc-slider-step {
    display: none;
  }
`;
