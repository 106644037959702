import React from 'react';

type Props = {
  children: React.ReactNode;
};

type State = {
  error?: Error | null;
};

export default class ExampleBoundary extends React.Component<Props, State> {
  public constructor(props) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error) {
    this.setState({ error });
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return process.env.DEPLOYMENT_ENV !== 'production'
        ? error.message
        : 'Une erreur est survenue.';
    }
    return children;
  }
}
