import React, { createRef, useEffect, useState, useRef } from 'react';
import { UnknownAction } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';

import { Box, breakpoints, colors } from '../../../design-system';
import { RootState } from '../../../store/rootReducer';
import { getAllStores, getStoresByLocation } from '../../store-locator/actions';
import Map from './../../store-locator/components/Map';
import { StoreList } from './../../store-locator/components/StoreList';
import {
  createMap,
  removeDiacritics,
  createPositionMarker,
  BASE_LOC,
  GEO_LOCATE_TIMEOUT_MS,
} from '../../store-locator/utils';
import { ERR_LOCATION, ERR_LOCATION_NOT_ACTIVE } from '../../store-locator/locale';
import useMarkers from '../../store-locator/hooks/useMarkers';
import usePrevious from '../../common/hooks/usePrevious';
import Search from './../../store-locator/components/Search';
import { ERR_REQUIRED } from '../../form/locale';
import ResultsTitle from './../../store-locator/components/ResultsTitle';
import {
  ZoomLevels,
  MapEvents,
  StoreModalMobileSteps,
  Position,
  Bounds,
  StoresListType,
  MapState,
  Suggestion,
} from '../../store-locator/types';
import {
  requestECartSuccess,
  setEresaRequestError,
  setEresaRequestLoading,
} from '../../ecart/actions';
import { StoreAvailable } from '../../product/types';
import useGoogleMapsAutocomplete from '../../common/hooks/useGoogleMapsAutocomplete';
import { StoreAutoComplete } from '../../store-locator/components/StoreAutoComplete';
import { getAreaCoordinates, getAddress } from '../../api';
import { loadCmsContent } from '../../cms/actions';
import { CmsCustomTypes } from '../../cms/types';
import { EresaStep, getStoreLocatorOpacity, getStoreLocatorPlaceHolder } from '../utils';
import { getBFFData, Mutations } from '../../api';
import { ScrollbarWrapper } from './EresaSummary';
import { LoadingScreen } from '../../quickadd/components/LoadingScreen';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';

let areaLayer: google.maps.Polygon;

type Props = {
  storesAvailable?: StoreAvailable[];
  setStepEresaModal: (value: number) => void;
};

export const EresaStoreLocator = ({ storesAvailable = [], setStepEresaModal }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!cmsContent) {
      dispatch(loadCmsContent(CmsCustomTypes.storelocator) as unknown as UnknownAction);
    }
  }, []);
  const reservationStore = useSelector((state: RootState) => state.ecart.ecart.storeInfo);
  const cmsContent = useSelector((state: RootState) => state.cms.storelocator);
  const placeholder = getStoreLocatorPlaceHolder(cmsContent?.placeholder);

  const {
    isFetching,
    storesByLocation: {
      stores,
      geoCoordinate: { longitude, latitude } = {} as { longitude?: number; latitude?: number },
    } = {
      stores: [],
      geoCoordinate: {},
    },
  } = useSelector((state: RootState) => state.stores) ?? {};

  const initialZoomLevel = ZoomLevels.COUNTRY;

  const { isMobile } = useMediaQueries();

  const [forceAutcompleteClosing, setForceAutcompleteClosing] = useState<boolean>(false);
  const [isFitBounds, setIsFitBounds] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [selectedMarker, setSelectedMarker] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [zoomLevel, setZoomLevel] = useState(initialZoomLevel);
  const [positionMarker, setPositionMarker] = useState<google.maps.Marker>();
  const [isPositioned, setIsPositioned] = useState(false);
  const [isLocating, setIsLocating] = useState(false);
  const [currentPosition, setCurrentPosition] = useState<Position>();
  const [step, setStep] = useState(StoreModalMobileSteps.LISTING);
  const [mapState, setMapState] = useState<MapState>({ loading: true });
  const [areaCoordinates, setAreaCoordinates] = useState([[]]);
  const [highlightedMarker, setHighlightedMarker] = useState('');
  const [clickedStoreIsLoading, setClickedStoreIsLoading] = useState('');
  const { main_product } = useSelector((state: RootState) => state.ecart);
  const prevZoomLevel = usePrevious(zoomLevel);

  const storeRefs = stores
    .sort((a, b) => {
      if (searchText.length === 0) {
        const postalCodeA = parseInt(a?.address?.postalCode || '0', 10);
        const postalCodeB = parseInt(b?.address?.postalCode || '0', 10);
        return postalCodeA - postalCodeB;
      }
      return 0;
    })
    .reduce((acc, _value, currentIndex) => {
      acc[currentIndex] = createRef();
      return acc;
    }, {});

  const mapRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const storeListRef = React.useRef<HTMLDivElement | null>(null);

  const loadStores = ({
    lat,
    lng,
    address = '',
    bounds,
  }: {
    lat?: number;
    lng?: number;
    address?: string;
    bounds?: Bounds;
  }) => {
    dispatch(
      getStoresByLocation({
        params: {
          bounds,
          lat,
          lng,
          address,
        },
        isReservation: true,
      }) as unknown as UnknownAction
    );
    setSelectedMarker('');
    setErrMsg('');
  };

  const handleZoomChange = () => {
    const zoom = mapState.map?.getZoom() ?? ZoomLevels.COUNTRY;
    setZoomLevel(zoom);
  };

  const handleSearch = (searchTerm = searchText) => {
    if (searchTerm) {
      setIsFitBounds(true);
      loadStores({ address: removeDiacritics(searchTerm) });
      setLastSearch(searchTerm);
      if (isPositioned) {
        setIsPositioned(false);
      }
    } else {
      setErrMsg(ERR_REQUIRED);
    }
  };

  const handleChange = (value: string) => {
    setForceAutcompleteClosing(false);
    setSearchText(value);
    if (!value) {
      dispatch(
        getAllStores({ offlineMode: true, isReservation: true }) as unknown as UnknownAction
      );
    }
    if (errMsg) {
      setErrMsg('');
    }
  };

  const handleLocate = () => {
    if (positionMarker) {
      positionMarker.setMap(null);
    }

    if (navigator.geolocation && mapState.map) {
      const { map } = mapState;
      setIsLocating(true);
      setSearchText('');
      navigator.geolocation.getCurrentPosition(
        async function (pos) {
          const { coords } = pos;
          const { latitude, longitude } = coords;
          const position = {
            lat: latitude,
            lng: longitude,
          };
          mapState.map?.setZoom(ZoomLevels.CITY);
          mapState.map?.setCenter(position);
          createPositionMarker({ map, position });
          setPositionMarker(positionMarker);
          setIsPositioned(true);
          setIsLocating(false);
          setCurrentPosition(position);
          const city = await getAddress(position.lat, position.lng);
          handleAutoComplete(
            `${city.address.road ?? ''}, ${city.address.postcode ?? ''} ${city.address.city ?? ''}`
          );
        },
        function () {
          setErrMsg(ERR_LOCATION_NOT_ACTIVE);
          setIsLocating(false);
        },
        {
          timeout: GEO_LOCATE_TIMEOUT_MS,
        }
      );
    } else {
      setErrMsg(ERR_LOCATION);
    }
  };

  const handleMapLinkClick = () => {
    setStep(StoreModalMobileSteps.MAP);
  };

  const handleStoreSelect = async (id: string) => {
    if (clickedStoreIsLoading === '') {
      setClickedStoreIsLoading(id);
      dispatch(setEresaRequestLoading());
      const product = {
        productRef: main_product.productRef,
        colorRef: main_product.colorRef,
        sku: main_product.sizeVariants[0].sku,
      };
      const initEresaResponse = await getBFFData(Mutations.initEreservation, {
        product,
        boutiqueId: id,
      });

      if (initEresaResponse.ok) {
        if (initEresaResponse.data.initEreservation?.items?.[0]?.stockQuantity === 0) {
          dispatch(setEresaRequestError(true));
        } else {
          dispatch(requestECartSuccess(initEresaResponse.data.initEreservation));
          setStepEresaModal(EresaStep.SUMMARY);
        }
      } else {
        dispatch(setEresaRequestError(true));
      }
    }
  };

  React.useEffect(() => {
    if (!stores.length) {
      dispatch(getAllStores({ isReservation: true }) as unknown as UnknownAction);
    }
    return () => {
      dispatch(
        getAllStores({ offlineMode: true, isReservation: true }) as unknown as UnknownAction
      );
    };
  }, []);

  useEffect(() => {
    if (stores.length && selectedMarker) {
      const { maps, map } = mapState;
      const selectedStore = stores.filter((store) => store.id === selectedMarker);
      if (maps && map && selectedStore.length) {
        map.setZoom(ZoomLevels.CITY);
      }
    }
  }, [selectedMarker]);

  useEffect(() => {
    const fetchCoordinates = async (searchText) => {
      const results = await getAreaCoordinates(searchText);
      if (results && results.length) {
        const filteredResults = results.filter((item) =>
          ['administrative', 'postal_code'].includes(item['type'])
        );
        if (filteredResults.length) {
          setAreaCoordinates(filteredResults[0]['geojson']['coordinates']);
        }
        return setAreaCoordinates([]);
      }
      return setAreaCoordinates([]);
    };

    if (stores.length) {
      const { maps, map } = mapState;

      if (maps && map && latitude && longitude) {
        const position = {
          lat: latitude,
          lng: longitude,
        };
        const dbounds = new maps.LatLngBounds(position);

        if (isFitBounds) {
          searchText && fetchCoordinates(searchText);
          setCurrentPosition(position);
          map.fitBounds(dbounds);
          stores.forEach((store) => {
            dbounds.extend({
              lat: store?.routableCoordinate?.latitude || store?.displayCoordinate?.latitude,
              lng: store?.routableCoordinate?.longitude || store?.displayCoordinate?.longitude,
            });
          });
          map.fitBounds(dbounds);
          isMobile && map.getZoom() === 0 && map.setZoom(ZoomLevels.COUNTRY);
        }

        if (stores.length === 1) {
          const centerPosition = {
            lat: stores[0].displayCoordinate.latitude,
            lng: stores[0].displayCoordinate.longitude,
          };
          map.panTo(centerPosition);
        }
      }
    }
    if (storeListRef?.current) {
      storeListRef.current.scrollTop = 0;
    }
  }, [stores]);

  useEffect(() => {
    const { map } = mapState;

    if (map) {
      const polygonCoordinates: google.maps.LatLngLiteral[] =
        areaCoordinates[0] &&
        areaCoordinates[0].map((coordiante) => ({
          lng: coordiante[0],
          lat: coordiante[1],
        }));

      if (Array.isArray(polygonCoordinates)) {
        areaLayer && areaLayer.setMap(null);
        areaLayer = new google.maps.Polygon({
          paths: polygonCoordinates,
          strokeColor: '#e78981',
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: '#fff',
          fillOpacity: 0.5,
        });

        areaLayer.setMap(map);
      }
    }
  }, [areaCoordinates]);

  useEffect(() => {
    if (mapState.map && zoomLevel === prevZoomLevel - 1 && zoomLevel >= ZoomLevels.COUNTRY) {
      loadStoresInView();
    } else {
      setIsFitBounds(true);
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (stores.length && highlightedMarker) {
      const { maps, map } = mapState;
      const highlightedStore = stores.filter((store) => store.id === highlightedMarker);
      if (maps && map && highlightedStore.length) {
        const centerPosition = {
          lat: highlightedStore[0].displayCoordinate.latitude,
          lng: highlightedStore[0].displayCoordinate.longitude,
        };

        map.panTo(centerPosition);
      }
    }
  }, [highlightedMarker]);

  useEffect(() => {
    const initMap = async (element: HTMLDivElement) => {
      const { map, sessionToken, autocomplete, maps } = await createMap({
        center: { ...BASE_LOC },
        element,
      });
      setMapState({ loading: false, map, sessionToken, autocomplete, maps });
    };

    if (mapRef.current) {
      initMap(mapRef.current);
    }
  }, [mapRef]);

  useEffect(() => {
    if (!mapState.map) {
      return () => {};
    }
    const listenerZoom = mapState.map?.addListener(MapEvents.zoomChanged, handleZoomChange);
    const listenerDrag = mapState.map?.addListener(MapEvents.dragend, handleDragEvent);

    return () => {
      listenerZoom && google.maps.event.removeListener(listenerZoom);
      listenerDrag && google.maps.event.removeListener(listenerDrag);
    };
  }, [mapState.map]);

  useEffect(() => {
    if (step === StoreModalMobileSteps.MAP && mapState.map) {
      const selectedStore = stores.find((store) => store.id === selectedMarker);
      if (selectedStore) {
        mapState.map.setZoom(ZoomLevels.CITY);
        mapState.map.setCenter({
          lat: selectedStore.displayCoordinate.latitude + 0.1,
          lng: selectedStore.displayCoordinate.longitude,
        });
      }
    }
  }, [step, mapState.map]);

  useMarkers({ map: mapState.map, stores, selectedMarker, onClick: setSelectedMarker });

  const loadStoresInView = () => {
    const getBounds = mapState.map?.getBounds();
    const getCenter = mapState.map?.getCenter();
    const bounds: Bounds = {
      northeast: {
        lat: getBounds?.getNorthEast().lat() || BASE_LOC.lat + 0.01,
        lng: getBounds?.getNorthEast().lng() || BASE_LOC.lng + 0.01,
      },
      southwest: {
        lat: getBounds?.getSouthWest().lat() || BASE_LOC.lat - 0.01,
        lng: getBounds?.getSouthWest().lng() || BASE_LOC.lng - 0.01,
      },
    };
    setIsFitBounds(false);
    loadStores({ lat: getCenter?.lat(), lng: getCenter?.lng(), bounds });
  };

  const handleDragEvent = () => {
    const zoom = mapState.map?.getZoom() ?? 0;
    if (zoom > ZoomLevels.COUNTRY) {
      loadStoresInView();
    }
  };

  const handleAutoComplete = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
    setForceAutcompleteClosing(true);
  };

  const predictions = useGoogleMapsAutocomplete(
    mapState.sessionToken,
    mapState.autocomplete,
    searchText
  );

  const suggestionsList: Suggestion[] = predictions.map((prediction) => {
    return { name: prediction.description };
  });
  const { loading: mapIsLoading } = mapState;

  const [showMapMobile, setShowMapMobile] = useState(false);

  return (
    <div
      css={css`
        display: flex;
        width: 100%;
        height: 466px;
        flex-direction: column;
        justify-content: ${stores.length === 1 ? 'initial' : 'space-between'};
        opacity: ${getStoreLocatorOpacity({ isFetching, isLocating })};
        @media (min-width: ${breakpoints.S}px) {
          flex-direction: row;
          justify-content: space-between;
        }
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
          @media (min-width: ${breakpoints.S}px) {
            width: calc(50% - 16px);
          }
        `}
      >
        <div
          ref={searchRef}
          css={css`
            margin-bottom: 8px;
            position: relative;
          `}
        >
          <div
            css={css`
              width: 100%;
              margin-bottom: 8px;
              text-align: left;
              font-size: 1.1rem;
              color: ${colors.GREY2};
            `}
          >
            Ma localisation
          </div>
          <Search
            onIconClick={handleLocate}
            onChange={handleChange}
            onClick={handleSearch}
            value={searchText}
            isPositioned={isPositioned}
            placeholder={placeholder}
            errMsg={errMsg}
            setForceAutcompleteClosing={setForceAutcompleteClosing}
            isDelivery
            isEresaModal
          />
          <StoreAutoComplete
            suggestionsList={suggestionsList}
            query={searchText}
            onClick={handleAutoComplete}
            forceAutcompleteClosing={forceAutcompleteClosing}
            isPositionAbsolute
            isEreservation
          />
          <div
            css={css`
              margin: 16px 0;
            `}
          >
            <ResultsTitle
              lastSearch={lastSearch}
              isPositioned={isPositioned}
              nbResults={stores.length}
              isEresaModal
            />
          </div>
          <div
            css={css`
              width: 100%;
              display: flex;
              @media (min-width: ${breakpoints.S}px) {
                display: none;
              }
            `}
          >
            <Box
              data-cy="ereservation-tab-show-list-mobile"
              width="50%"
              height="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderBottom={showMapMobile ? 'solid 1px #E6E6E6' : 'solid 1px #000'}
              fontSize="16px"
              fontWeight={showMapMobile ? 400 : 700}
              textAlign="center"
              onClick={() => setShowMapMobile(false)}
            >
              Liste
            </Box>
            <Box
              data-cy="ereservation-tab-show-map-mobile"
              width="50%"
              height="50px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderBottom={!showMapMobile ? 'solid 1px #E6E6E6' : 'solid 1px #000'}
              fontSize="16px"
              fontWeight={showMapMobile ? 700 : 400}
              textAlign="center"
              onClick={() => setShowMapMobile(true)}
            >
              Carte
            </Box>
          </div>
          <div
            css={css`
              display: none;
              @media (min-width: ${breakpoints.S}px) {
                display: block;
              }
            `}
          >
            <ScrollbarWrapper
              ref={storeListRef}
              css={css`
                overflow-x: hidden;
                max-height: 341px;
                margin-bottom: 16px;
                pointer-events: ${isFetching || isLocating ? 'none' : 'initial'};
                @media (min-width: ${breakpoints.L}px) {
                  max-height: 343px;
                  margin-bottom: 0;
                }
              `}
            >
              <StoreList
                storeslistType={StoresListType.ERESERVATION_STORE}
                onClick={setSelectedMarker}
                onSelect={handleStoreSelect}
                onMapLinkClick={handleMapLinkClick}
                highlightMarker={setHighlightedMarker}
                currentStore={reservationStore?.id}
                stores={stores}
                selectedMarker={selectedMarker}
                storeRefs={storeRefs}
                currentPosition={currentPosition}
                storesAvailable={storesAvailable}
                clickedStoreIsLoading={clickedStoreIsLoading}
                searchText={searchText}
                isEresaModal
              />
            </ScrollbarWrapper>
          </div>
        </div>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          margin-top: ${showMapMobile ? '8px' : 0};
          width: 100%;
          @media (min-width: ${breakpoints.S}px) {
            width: calc(50% - 8px);
          }
        `}
      >
        <div
          css={css`
            height: 353px;
            display: ${!showMapMobile ? 'none' : 'block'};
            position: relative;
            @media (min-width: ${breakpoints.S}px) {
              height: 466px;
              display: block;
            }
          `}
        >
          {(isLocating || mapIsLoading) && <LoadingScreen />}
          <Map mapRef={mapRef} />
        </div>
        <ScrollbarWrapper
          ref={storeListRef}
          css={css`
            display: block;
            pointer-events: ${isFetching || isLocating ? 'none' : 'initial'};
            @media (min-width: ${breakpoints.S}px) {
              display: none;
            }
          `}
          mt={showMapMobile ? 'l' : 'm'}
          overflowX="hidden"
          maxHeight="542px"
        >
          <StoreList
            storeslistType={StoresListType.ERESERVATION_STORE}
            onClick={setSelectedMarker}
            onSelect={handleStoreSelect}
            onMapLinkClick={handleMapLinkClick}
            highlightMarker={setHighlightedMarker}
            currentStore={reservationStore?.id}
            isEresaModal
            stores={stores}
            selectedMarker={selectedMarker}
            storeRefs={storeRefs}
            currentPosition={currentPosition}
            storesAvailable={storesAvailable}
            clickedStoreIsLoading={clickedStoreIsLoading}
            searchText={searchText}
          />
        </ScrollbarWrapper>
      </div>
    </div>
  );
};
