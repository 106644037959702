import { State } from './types';

export const emptyText = [{ type: '', text: '', spans: [] }];

const cmsState: State = {
  cart: {
    activate_banner: false,
    title: '',
    block: '',
    link: '',
    text_color: '',
    background_color: '',
    reassurance: [],
    reassurances: [],
    shipping_promo_amount: 0,
    pickup_shipping_promo_amount: 0,
    shipping_promo_threshold_message: '',
    shipping_promo_message: '',
    shipping_promo_message_remaining: [...emptyText],
    shipping_promo_message_free_delivery: [...emptyText],
    shipping_promo_message_pick_up: [...emptyText],
    shipping_promo_label: '',
    cart_recommendation_title: '',
    body: [
      {
        id: '',
        items: [
          {
            cart_faq_answer: [...emptyText],
            cart_faq_question: '',
          },
        ],
        primary: {
          cart_faq_answer_color: '',
          cart_faq_question_color: '',
          cart_faq_background_color: '',
          cart_faq_title: '',
          cart_faq_title_color: '',
          cart_faq_link_text: '',
          cart_faq_link_color: '',
          cart_faq_divider_color: '',
        },
        slice_label: null,
        slice_type: '',
      },
    ],
    cart_faq_is_active_desktop: false,
    cart_faq_is_active_mobile: false,
  },
  ecart: {
    reservation_text_modal: [...emptyText],
    reservation_text_line_one: '',
    reservation_text_line_two: '',
    reservation_text_line_three: '',
    standards: [],
    default_cross_sell: [
      {
        productref_default_cs: '',
        colorref_default_cs: '',
      },
    ],
  },
  ordersuccess: {
    image: {
      url: '',
    },
    title: [...emptyText],
    block: [...emptyText],
    cta: '',
    link: '',
  },
  catalog: {
    seotitle: '',
    seodescription: '',
    hide_filters: false,
  },
  product: {
    usp: [],
    cross_sell_title: '',
    up_sell_title: '',
  },
  banner: {
    body: '',
    textcolor: '',
    backgroundcolor: '',
    link: '',
  },
  footer: {
    body: [],
  },
  generic: {
    type: '',
    title: [...emptyText],
    subtitle: [...emptyText],
    message: [...emptyText],
    image: '',
    body: [],
  },
  contact: {
    description: [...emptyText],
    subtitle: [...emptyText],
    link: [
      {
        icon: '',
        text: [...emptyText],
        note: [...emptyText],
      },
    ],
    info: [...emptyText],
    body: [],
  },
  homepage: {
    seotitle: '',
    seodescription: '',
    banners: [],
    categories: [],
    spaces: [],
    titles: [],
    categories_block_is_active_desktop: false,
    categories_block_is_active_mobile: false,
    category_position: '1',
    recommendations_block_is_active: false,
    recommendations_position: '1',
    recommendations_t2s_title: '',
    body: [],
    body1: [],
    body2: [],
    recommendations_tabs: [],
  },
  menu: {
    items: [
      {
        type: '',
        picto_for_mobile: '',
        name: '',
        link: '',
        image: {
          url: '',
        },
      },
    ],
  },
  storelocator: {
    title: '',
    body: '',
    placeholder: '',
    services: [],
    store_image: { url: '' },
    promotions: [{ promotion_image: { url: '' }, promotion_title: '', promotion_subtitle: '' }],
    promotions_title: '',
    promotions_subtitle: '',
    assistances: [
      { assistance_image: { url: '' }, assistance_text: emptyText, assistance_title: '' },
    ],
  },
  notfound: {
    title: '',
    body: [],
    subtitle: '',
    items: [],
  },
  promotion: {
    label_text_color: '',
    label_background_color: '',
    discount_percentages: [],
  },
  offers: {
    items: [],
  },
  grid: {
    type: '',
    seotitle: '',
    seodescription: '',
    items: [],
  },
  account: {
    body: [...emptyText],
  },
  order: {
    body: [...emptyText],
  },
  invoice: {
    body: [...emptyText],
    image: {
      url: '',
    },
  },
  emptycart: {
    title: '',
    recommendations: [],
  },
  search: {
    placeholder: '',
    body: [],
    body1: [],
    body2: [],
    body3: [],
  },
  delivery: {
    hometext: '',
    storetext: '',
    pickuptext: '',
    home_delivery_min_day: 1,
    home_delivery_max_day: 1,
    store_delivery_min_day: 1,
    store_delivery_max_day: 1,
    pickup_delivery_min_day: 1,
    pickup_delivery_max_day: 1,
  },
  usp: {
    usp_main_title: '',
    usp: [],
  },
  payment: {
    tooltip_icon: '',
    tooltip_title: '',
    tooltip_reasons: [...emptyText],
    warnings: [],
  },
  affiliation: {
    first_image: {
      url: '',
    },
    first_video: '',
    second_video_mobile: '',
    second_video_desktop: '',
    pdf_affiliation: {
      url: '',
    },
    body: [],
    seo_description: [...emptyText],
    meta_title: '',
    meta_description: '',
  },
  bestsellers: {
    body: [],
    slider_with_number_is_active_desktop: false,
    slider_with_number_is_active_mobile: false,
    slider_with_number_text_is_active: false,
    slider_with_number_is_sticky_mobile: false,
    top_ten_bottom_seo_description: [...emptyText],
    top_ten_meta_description: '',
    top_ten_meta_title: '',
    top_ten_bottom_description_alignment: '',
  },
  ourbrand: {
    body: [],
    body1: [],
    body2: [],
    body3: [],
    body4: [],
    body5: [],
    body6: [],
    body7: [],
    body8: [],
  },
  masterList: [],
  isLoading: false,
  uid: '',
};

export default cmsState;
