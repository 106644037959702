import React from 'react';

import { StandardFilter } from './StandardFilter';
import { Sizes } from './Sizes';
import { Colors } from './Colors';
import { PriceFilter } from './PriceFilter';
import { StandardWithIconsFilter } from './StandardWithIconFilter';

export type FilterListType = {
  title: string;
  attribute: string;
  component: React.ReactNode;
};

const filterPromotion: FilterListType = {
  title: 'Promotions',
  attribute: 'promotionPercentage',
  component: <StandardFilter attribute="promotionPercentage" limit={5} />,
};

const filterSize: FilterListType = {
  title: 'Tailles',
  attribute: 'sizeVariants.sizeFilter',
  component: <Sizes attribute="sizeVariants.sizeFilter" limit={100} />,
};

const filterColor: FilterListType = {
  title: 'Couleurs',
  attribute: 'colorLabel',
  component: <Colors attribute="colorLabel" />,
};

const filterPrice: FilterListType = {
  title: 'Prix',
  attribute: 'storePrice',
  component: <PriceFilter attribute="storePrice" />,
};

const filterTypology: FilterListType = {
  title: 'Formes',
  attribute: 'typology',
  component: <StandardFilter attribute="typology" limit={50} shouldHaveTooltip />,
};

const filterCollection: FilterListType = {
  title: 'Collection',
  attribute: 'collectionName',
  component: <StandardFilter attribute="collectionName" limit={50} />,
};

const filterSupport: FilterListType = {
  title: 'Maintien',
  attribute: 'maintien',
  component: <StandardWithIconsFilter attribute="maintien" limit={3} />,
};

const filterWaist: FilterListType = {
  title: 'Hauteur de taille',
  attribute: 'waist',
  component: <StandardWithIconsFilter attribute="waist" limit={3} />,
};

const filterLayer: FilterListType = {
  title: 'Matière principale',
  attribute: 'layer',
  component: <StandardFilter attribute="layer" limit={5} />,
};

const filterWire: FilterListType = {
  title: 'Armature',
  attribute: 'wire',
  component: <StandardFilter attribute="wire" suffix="armatures" limit={2} />,
};

const filterClosure: FilterListType = {
  title: 'Fermeture',
  attribute: 'closure',
  component: <StandardWithIconsFilter attribute="closure" limit={3} />,
};

const filterCoques: FilterListType = {
  title: 'Coques',
  attribute: 'coques',
  component: <StandardFilter attribute="coques" suffix="coques" limit={2} />,
};

const filterNeckline: FilterListType = {
  title: 'Décolleté',
  attribute: 'neckline',
  component: <StandardWithIconsFilter attribute="neckline" limit={3} />,
};

const filterOpacity: FilterListType = {
  title: 'Opacité',
  attribute: 'opacity',
  component: <StandardWithIconsFilter attribute="opacity" limit={3} />,
};

const filterEchancrure: FilterListType = {
  title: 'Échancrure',
  attribute: 'echancrure',
  component: <StandardFilter attribute="echancrure" limit={2} />,
};

export const FilterList: FilterListType[] = [
  filterSize,
  filterTypology,
  filterColor,
  filterPrice,
  filterPromotion,
  filterLayer,
  filterSupport,
  filterWire,
  filterCoques,
  filterNeckline,
  filterClosure,
  filterEchancrure,
  filterWaist,
  filterOpacity,
  filterCollection,
];
