import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import styled from '@emotion/styled';
import pathOr from 'ramda/src/pathOr';
import { useFeatureGate } from '@statsig/react-bindings';

import { Box, DynamicButton, DynamicButtonStatus, Breadcrumbs } from '../../../design-system';
import { Status } from '../../../design-system/button/components/DynamicButton';
import {
  loadProduct,
  loadReviews,
  LoadProductParams,
  setSizeErrMsg,
  changeSize,
  requestProductSuccessModal,
  changeSizeModal,
  updateQuantity,
} from '../actions';
import { addGiftCard, addToCart, ProductInput, TAddGiftCardRequest } from '../../cart/actions';
import Images from './Images';
import { RootState } from '../../../store/rootReducer';
import { State, Product as ProductType } from '../types';
import { CmsProduct, CmsUsp } from '../../cms/types';
import { cmsProductInit } from '../../cms/actions';
import {
  buildProductRichSnippet,
  sortImagePositions,
  getIsSizeAvailable,
  getProductImageLink,
  productImageWidths,
  verifyGiftCardInfos,
  TGiftCardErrors,
  isItGiftCard,
  findQtyOnCartItems,
} from '../utils';
import { CTA_CONTENT, ERR_CHOOSE_SIZE } from '../locale';
import { ERR_GENERIC } from '../../common/locale';
import Reviews from '../../reviews';
import { InfoPanel } from './InfoPanel';
import { Details } from './Details';
import LoadingProduct from './LoadingProduct';
import { CrossSellProductPage } from './CrossSellProductPage';
import { UpSell } from './UpSell';
import { BreadcrumbsItems, AlgoliaQuery } from '../../catalog/types';
import { getBreadcrumbItems } from '../../catalog/utils';
import { NotFound } from '../../routing';
import { useMediaQueries } from '../../common/hooks/useMediaQuery';
import UspSection from '../../home/components/UspSection';
import { Selectors } from './Selectors';
import { ProductUsp } from './ProductUsp';
import { QuantitySelector } from './QuantitySelector';
import { analyticsEvent } from '../../search/utils';
import { AnalyticsEventTypes, AnalyticsEvents } from '../../search/types';
import { PromotionLabel } from './PromotionLabel';
import { Feedback } from '../../cart/components/Feedback';
import {
  loadProductRecommendationsHomemade,
  loadProductRecommendations,
} from '../../recommendations/actions';
import { LoadProductRecommendationsParams } from '../../recommendations/types';
import GiftCardInfoPanel from './GiftCardInfoPanel';
import { patternVariants, TPattern } from './PatternSelector';
import { GiftCardCustomInfos } from './GiftCardCustomInfos';
import { GiftCardMiniDescription } from './GiftCardMiniDescription';
import { GiftCardDescription } from './GiftCardDescription';
import { getCanonicalUrl, scrollToTop } from '../../common/utils';
import CsrBlock from './CsrBlock';
import StickySelector from './StickySelector';
import { Cart } from '../../cart/types';
import { ImagesMozaic } from './ImagesMozaic';
import { heights } from '../../common/constants';
import { CommitmentsBanner } from '../../banners/components/BannerCommitments';
import { breakpoints } from '../../../design-system';
import { ProductVariantBlock } from './ProductVariantBlock';
import WishlistCta from '../../wishlist/components/WishlistCta';

const StyledProductPage = styled.div`
  display: grid;
  grid-gap: 40px;
  margin: -56px auto 0;
  max-width: 1440px;
  @media (min-width: ${breakpoints.S}px) {
    margin: 16px auto;
  }

  .cta-for-variant {
    margin: 16px 0;
  }
  .add-to-cart-wishlist__btn-container {
    display: flex;
    gap: 8px;
  }
  .wishlist-cta {
    position: relative;
  }
`;

type DispatchedActions = {
  cmsProductInit: () => void;
  loadProduct: ({ productRef, colorRef }: LoadProductParams) => void;
  loadReviews: (productCode: string) => void;
  loadProductRecommendations: (params: LoadProductRecommendationsParams) => void;
  loadProductRecommendationsHomemade: ({ productRef, colorRef }: LoadProductParams) => void;
  addToCart: (product: ProductInput, trackingProduct: ProductType) => void;
  addGiftCard: (
    giftCard: TAddGiftCardRequest,
    trackingProduct: ProductType
  ) => Promise<{ ok: boolean; data?: string }>;
  setSizeErrMsg: (msg: string) => void;
};

type Props = State &
  DispatchedActions & {
    cmsProduct: CmsProduct;
    cmsUsp: CmsUsp;
    ctaState: DynamicButtonStatus;
    cart: Cart;
    algoliaQuery: AlgoliaQuery | undefined;
  };

export type TGiftCardInfos = {
  isAnotherAmount: boolean;
  giftCardAmount: string;
  senderName: string;
  receiverName: string;
  receiverEmail: string;
  dateOfSending: string;
  message: string;
  emailPattern: TPattern;
};

export const Product = ({
  loadProduct,
  loadReviews,
  loadProductRecommendations,
  loadProductRecommendationsHomemade,
  cmsProductInit,
  isFetching,
  errMsg,
  product,
  productReviews,
  size,
  sizeErrMsg,
  quantity,
  ctaState,
  cart,
  cmsProduct,
  cmsUsp,
  algoliaQuery,
  addToCart,
  addGiftCard,
  setSizeErrMsg,
}: Props) => {
  const { pathname, hash } = useLocation();
  const { id } = useParams() as { id: string };
  const { isMobile, isTablet } = useMediaQueries();
  const dispatch = useDispatch();
  const reviewsRef = useRef<HTMLDivElement | null>(null);
  const pdpAddToCartRef = useRef<HTMLDivElement | null>(null);
  const [giftCardInfos, setGiftCardInfos] = useState<TGiftCardInfos>({
    isAnotherAmount: false,
    giftCardAmount: '',
    senderName: '',
    receiverName: '',
    receiverEmail: '',
    dateOfSending: '',
    message: '',
    emailPattern: patternVariants[0],
  });
  const [giftCardAmount, setGiftCardAmount] = useState<string>('');
  const [isCustomAmountDeselected, setIsCustomAmountDeselected] = useState<boolean>(false);
  const [giftCardErrMsg, setGiftCardErrMsg] = useState<TGiftCardErrors>({});
  const [customSizeBlockForVariantTwoIsOpen, setCustomSizeBlockForVariantTwoIsOpen] =
    useState(false);
  const [productQuantityAdded, setProductQuantityAdded] = useState(1);
  const cmsCommitmentsBanner = cmsProduct.body?.find(
    (el) => el.slice_type === 'commitments_banner'
  );
  const [paramColorRef, paramProductRef] = id.split('-').reverse();
  const isValidProduct = () =>
    product &&
    product.productRef &&
    product.colorRef &&
    product.productName &&
    product.categories &&
    product.productRef === paramProductRef &&
    product.colorRef === paramColorRef &&
    product.status;

  const showReviews = !productReviews.isFetching && Boolean(productReviews.reviews.product_code);
  const productCode = paramProductRef;

  const {
    productRef,
    colorRef,
    colorLabel,
    detailedColorLabel,
    productName,
    productDescription,
    productComposition,
    status,
    realColorRef,
    cleaningAdvice,
    categories,
    colorVariants,
    sizeVariants,
    model,
    moreDetails,
    promoLongLabel: promotionLongLabel,
    promoTColor: promotionTextColor,
    promoBColor: promotionBackgroundColor,
    videoUrl,
    isRecyclable,
    label1: rankLabel,
    label2: attributeLabel,
  } = product ?? {};

  const { refinementsItems } = useSelector((state: RootState) => state.filters);
  const { showFeedback } = useSelector((state: RootState) => state.cart);

  const { productRef: productRefModal, colorLabel: colorLabelModal } = useSelector(
    (state: RootState) => state.product.productModal
  );
  const { crossSellIsLoading, upSellIsLoading } = useSelector((state: RootState) => state.product);

  const isQuickAddOnPDP =
    productRef !== productRefModal ||
    (productRef === productRefModal && colorLabel !== colorLabelModal);
  const isGiftCard = isItGiftCard(productRef);
  const hideCsrBlock = cmsProduct.hide_csr_block;

  const navHeight = isMobile
    ? heights.HEADER_MOBILE
    : isTablet
      ? heights.HEADER_TABLET
      : heights.HEADER;
  const ctaHeight = isMobile ? heights.CTA_HEIGHT : 0;

  useEffect(() => {
    if (sizeVariants.length > 0) {
      const refinedSizes = refinementsItems.reduce<string[]>((acc, cVal) => {
        if (cVal?.attribute === 'sizeVariants.sizeFilter') {
          return [...acc, cVal.label];
        }
        return acc;
      }, []);

      if (refinedSizes.length === 1) {
        const refinedSize = sizeVariants.filter((variant) =>
          [variant.size, `${variant.bandSize}${variant.cupSize}`].includes(refinedSizes[0])
        );
        if (refinedSize.length === 1 && getIsSizeAvailable(refinedSize[0])) {
          dispatch(changeSize(refinedSize[0]));
        }
      }
    }
  }, [sizeVariants]);

  useEffect(() => {
    if (!isValidProduct()) {
      loadProduct({ productRef: paramProductRef, colorRef: paramColorRef });
    }
    if (!isValidProduct() || !productReviews.reviews.product_code) {
      loadReviews(productCode);
    }
  }, [id]);

  useEffect(() => {
    dispatch(updateQuantity(1));
  }, [size, cart]);

  const cmsProductUsp = cmsProduct?.usp ?? [];
  const cmsThumbnailImage = cmsProduct?.thumbnail_image?.thumbnail?.url ?? '';

  useEffect(() => {
    if (isMobile && hash !== '#reviews') {
      scrollToTop();
    }
    if (!Object.values(cmsProduct)) {
      cmsProductInit();
    }
  }, []);

  const isHomeMadeRecommendationsEnabled = useFeatureGate('home-made-reco').value;

  useEffect(() => {
    if (isValidProduct()) {
      if (isHomeMadeRecommendationsEnabled) {
        loadProductRecommendationsHomemade({ productRef, colorRef });
      } else {
        loadProductRecommendations({
          productRef,
          colorRef,
        });
      }
    }
  }, [product]);

  useEffect(() => {
    if (isGiftCard && giftCardInfos && giftCardInfos.isAnotherAmount) {
      setGiftCardAmount(giftCardInfos.giftCardAmount);
    }
  }, [giftCardInfos]);

  useEffect(() => {
    if (showFeedback) {
      setCustomSizeBlockForVariantTwoIsOpen(false);
    }
  }, [showFeedback]);

  if (isFetching) {
    return <LoadingProduct />;
  }
  if (errMsg || !isValidProduct()) {
    return <NotFound />;
  }

  const { lvl0, lvl1, lvl2, lvl3 } = categories ?? {};
  const categoryLevels = [lvl0, lvl1, lvl2, lvl3].filter(Boolean);
  const breadcrumbItems: BreadcrumbsItems = getBreadcrumbItems(categoryLevels);
  const breadcrumbItemsWithCurrentProduct = [
    ...breadcrumbItems,
    {
      label: `${productName} - ${detailedColorLabel}`,
      value: '#',
      readonly: true,
    },
  ];

  const imagePositions: number[] = sortImagePositions(pathOr([], ['images'], product));

  const { sku } = size;
  const { storePrice, originalPrice } = sku ? size : (product ?? {});

  const feedbacks = pathOr([], ['reviews', 'feedbacks'], productReviews);
  const ratingAverage = pathOr('', ['reviews', 'rating_average'], productReviews);
  const ratingCount = pathOr('', ['reviews', 'rating_count'], productReviews);
  const JsonLdData = buildProductRichSnippet({
    productName,
    productRef,
    colorRef,
    imagePositions,
    productDescription,
    feedbacks,
    ratingAverage,
    ratingCount,
    pathname,
    storePrice,
  });

  const uspContent = cmsUsp?.usp ?? [];
  const uspTitle = cmsUsp?.usp_main_title ?? '';

  const metaTitle = `${productName} ${detailedColorLabel} | Darjeeling`;

  const objectID = `${productRef}/${colorRef}`;

  const handleCtaClick = async () => {
    if (sku) {
      if ((status && getIsSizeAvailable(size) && size.stockQuantity >= quantity) || isGiftCard) {
        let errMsg: TGiftCardErrors = {};
        if (isGiftCard) {
          errMsg = verifyGiftCardInfos(giftCardInfos as TGiftCardInfos);
          setGiftCardErrMsg(errMsg);
          if (Object.keys(errMsg).length === 0 && errMsg.constructor === Object) {
            const giftCard = {
              productRef: productRef,
              colorRef: colorRef,
              sku: size.sku,
              price: Math.round(parseFloat(giftCardInfos.giftCardAmount) * 100) / 100,
              from: giftCardInfos.senderName,
              to: giftCardInfos.receiverName,
              email: giftCardInfos.receiverEmail,
              date: giftCardInfos.dateOfSending,
              message: giftCardInfos.message,
            };

            const response = await addGiftCard(giftCard, product);
            if (!response.ok) {
              setGiftCardErrMsg({ receiverEmail: response.data });
            }
          }
        } else {
          if (quantity > 1) {
            setProductQuantityAdded(quantity);
          }
          addToCart(
            {
              productRef,
              colorRef,
              sku,
              quantity,
            },
            product
          );
          dispatch(requestProductSuccessModal(product));
          dispatch(changeSizeModal(size));
          if (algoliaQuery) {
            analyticsEvent({
              objectID,
              queryID: algoliaQuery.queryID,
              index: algoliaQuery.index,
              type: AnalyticsEventTypes.convert,
              eventName: AnalyticsEvents.addToCartFromPDP,
            });
          }
        }
      } else {
        setSizeErrMsg(ERR_GENERIC);
      }
    } else {
      setSizeErrMsg(ERR_CHOOSE_SIZE);
      setCustomSizeBlockForVariantTwoIsOpen(true);
    }
  };

  const handleGiftCardInfo = (infos: TGiftCardInfos) => {
    setGiftCardInfos(infos);
    if (infos.isAnotherAmount) {
      setIsCustomAmountDeselected(false);
    }
  };

  const handleNewAmount = (newAmount: string) => {
    setGiftCardAmount(newAmount);
    if (giftCardInfos?.isAnotherAmount) {
      setIsCustomAmountDeselected(true);
    }
  };

  const handleCustomSizeBlockToggle = (isOpen: boolean) => {
    setCustomSizeBlockForVariantTwoIsOpen(isOpen);
  };

  const CtaFragment = (
    <div ref={pdpAddToCartRef} className="add-to-cart-wishlist__btn-container">
      <DynamicButton
        id="btn-add-to-cart"
        onClick={handleCtaClick}
        data={CTA_CONTENT(isMobile)}
        feedback={isQuickAddOnPDP ? Status.Default : ctaState}
        disabled={!status}
        fontWeight={status ? '700' : '400'}
      />
      {!isGiftCard && (
        <div className="wishlist-cta">
          <WishlistCta
            product={product}
            productRef={productRef}
            colorRef={colorRef}
            objectID={`${productRef}/${colorRef}`}
          />
        </div>
      )}
    </div>
  );

  const fragments = {
    InfoPanel: (
      <InfoPanel
        productName={productName}
        storePrice={storePrice}
        originalPrice={originalPrice}
        ratingAverage={ratingAverage}
        ratingCount={ratingCount}
        reviewsRef={reviewsRef}
        showReviews={showReviews}
        isRecyclable={isRecyclable}
      />
    ),
    Selectors: (
      <Selectors
        size={size}
        sizeErrMsg={sizeErrMsg}
        sizeVariants={sizeVariants}
        colorRef={colorRef}
        detailedColorLabel={detailedColorLabel}
        colorVariants={colorVariants}
        productRef={productRef}
        pathname={pathname}
        ctaState={ctaState}
        realColorRef={realColorRef}
        status={status}
        quantity={quantity}
        objectID={objectID}
        isGiftCard={isGiftCard}
        handleNewAmount={handleNewAmount}
        isCustomAmountDeselected={isCustomAmountDeselected}
        isQuickAddOnPDP={isQuickAddOnPDP}
        colorLabel={colorLabel}
        onCtaClick={handleCtaClick}
        onGiftCardInfo={handleGiftCardInfo}
        giftCardInfos={giftCardInfos}
        giftCardErrMsg={giftCardErrMsg}
        giftCardAmount={giftCardAmount}
        quantityAlreadyAddedOnCart={findQtyOnCartItems(cart, productRef, colorRef, size)}
        handleCustomSizeBlockToggle={handleCustomSizeBlockToggle}
        customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
      />
    ),
    Cta: CtaFragment,
    Images: (
      <>
        <Images
          productRef={productRef}
          colorRef={colorRef}
          productName={productName}
          colorLabel={colorLabel}
          imagePositions={imagePositions}
          videoUrl={videoUrl}
          cmsThumbnailImage={cmsThumbnailImage}
          product={product}
          model={model}
          breadcrumbItems={breadcrumbItemsWithCurrentProduct}
        />
      </>
    ),
    Details: (
      <Details
        productDescription={productDescription}
        cleaningAdvice={cleaningAdvice}
        productComposition={productComposition}
        moreDetails={moreDetails}
        product={product}
        isHomeMadeRecommendationsEnabled={isHomeMadeRecommendationsEnabled}
      />
    ),
    ProductUsp: <ProductUsp content={cmsProductUsp} />,
    Reviews:
      !crossSellIsLoading && !upSellIsLoading ? (
        <Box
          ref={reviewsRef}
          style={{ scrollMarginTop: navHeight + ctaHeight }}
          width="100%"
          maxWidth="1072px"
          px={isTablet ? 'm' : isMobile ? 'na' : 'l'}
          m="auto"
        >
          <Reviews
            reviews={productReviews.reviews}
            productCode={productCode}
            reviewsRef={reviewsRef}
          />
        </Box>
      ) : null,
    CommitmentsBanner: (
      <CommitmentsBanner
        id={cmsCommitmentsBanner?.id}
        items={cmsCommitmentsBanner?.items}
        title={cmsCommitmentsBanner?.primary.commitments_banner_title}
      />
    ),
    CrossSell: <CrossSellProductPage />,
    UpSell: <UpSell />,
    PromotionLabel: (
      <PromotionLabel
        promotionLongLabel={promotionLongLabel}
        promotionTextColor={promotionTextColor}
        promotionBackgroundColor={promotionBackgroundColor}
        rankLabel={rankLabel}
        attributeLabel={attributeLabel}
        objectID={objectID}
      />
    ),
  };

  return (
    <StyledProductPage>
      <Helmet>
        <title>{metaTitle}</title>
        <link rel="canonical" href={getCanonicalUrl(pathname)} />
        <meta name="description" content={productDescription} />
        <script type="application/ld+json">{`${JsonLdData}`}</script>
        <meta property="og:title" content={metaTitle} />
        <meta
          property="og:image"
          content={getProductImageLink({
            productRef,
            colorRef,
            productName,
            position: 1,
            width: productImageWidths.SLIDER,
          })}
        />
        <meta property="og:description" content={productDescription} />
      </Helmet>

      <Feedback
        giftCardInfos={giftCardInfos}
        onClose={() => handleCustomSizeBlockToggle(false)}
        quantity={productQuantityAdded}
        setProductQuantityAdded={setProductQuantityAdded}
      />
      {isMobile ? (
        <Box display="grid" gridGap="s" mx="m">
          <Box display="grid" gridGap="s">
            <Box mx="-16px">{fragments.Images}</Box>
            {!isGiftCard ? (
              <ProductVariantBlock
                product={product}
                size={size}
                ratingAverage={ratingAverage}
                ratingCount={ratingCount}
                reviewsRef={reviewsRef}
                showReviews={showReviews}
                sizeErrMsg={sizeErrMsg}
                ctaState={ctaState}
                handleCtaClick={handleCtaClick}
                customSizeBlockForVariantTwoIsOpen={customSizeBlockForVariantTwoIsOpen}
                handleCustomSizeBlockToggle={handleCustomSizeBlockToggle}
              />
            ) : (
              <Box>
                <GiftCardInfoPanel
                  ratingAverage={ratingAverage}
                  ratingCount={ratingCount}
                  showReviews={showReviews}
                  reviewsRef={reviewsRef}
                  amount={giftCardAmount}
                />
              </Box>
            )}
            {!isGiftCard && (
              <QuantitySelector
                objectID={objectID}
                quantity={quantity}
                size={size}
                quantityAlreadyAddedOnCart={findQtyOnCartItems(cart, productRef, colorRef, size)}
              />
            )}
            {!isGiftCard && (
              <div className="cta-for-variant add-to-cart-wishlist__btn-container">
                <DynamicButton
                  id="btn-add-to-cart"
                  data-testid="btn-add-to-cart"
                  onClick={handleCtaClick}
                  data={CTA_CONTENT(true)}
                  feedback={ctaState}
                />
                <div className="wishlist-cta">
                  <WishlistCta
                    product={product}
                    productRef={productRef}
                    colorRef={colorRef}
                    objectID={`${productRef}/${colorRef}`}
                  />
                </div>
              </div>
            )}
            {!isGiftCard && fragments.Details}
            {isGiftCard && (
              <GiftCardCustomInfos
                onGiftCardInfo={handleGiftCardInfo}
                isCustomAmountDeselected={isCustomAmountDeselected}
                giftCardErrMsg={giftCardErrMsg}
                giftCardInfos={giftCardInfos}
              />
            )}
            {fragments.Selectors}
            {!isGiftCard && fragments.ProductUsp}
            {isGiftCard && <GiftCardDescription />}
            {!isGiftCard && (
              <>
                <Box display="grid" mt="m">
                  {fragments.CrossSell}
                </Box>
                {fragments.UpSell}
              </>
            )}
            {showReviews && fragments.Reviews}
            {fragments.CommitmentsBanner}
            {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
            <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
            <UspSection content={uspContent} title={uspTitle} isProductPage />
          </Box>
        </Box>
      ) : isTablet ? (
        <>
          <Box display="grid" gridGap="l">
            <Box display="grid" gridTemplateColumns="1fr 1fr" justifySelf="center">
              <Box
                display="grid"
                gridGap="xl"
                gridAutoRows="min-content"
                alignSelf="start"
                position="sticky"
                top={`${heights.HEADER_TABLET}px`}
              >
                {fragments.Images}
                {isGiftCard ? (
                  <Box ml={'m'}>
                    <GiftCardDescription />
                  </Box>
                ) : (
                  <Box ml={'m'}>{fragments.Details}</Box>
                )}
              </Box>
              <Box mx="m" alignSelf="start" position="sticky" top={`${heights.HEADER_TABLET}px`}>
                <Box display="grid" gridGap="s">
                  <Box display="grid" gridGap="m" gridAutoRows="min-content">
                    {!isGiftCard ? (
                      fragments.InfoPanel
                    ) : (
                      <>
                        <GiftCardInfoPanel
                          ratingAverage={ratingAverage}
                          ratingCount={ratingCount}
                          showReviews={showReviews}
                          reviewsRef={reviewsRef}
                          amount={giftCardAmount}
                        />
                        <GiftCardMiniDescription />
                      </>
                    )}
                    {fragments.PromotionLabel}
                    {fragments.Selectors}
                  </Box>
                  {CtaFragment}
                  {!isGiftCard && fragments.ProductUsp}
                  {!isGiftCard && fragments.CrossSell}
                </Box>
              </Box>
            </Box>
            {fragments.UpSell}
          </Box>
          {showReviews && fragments.Reviews}
          {fragments.CommitmentsBanner}
          {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
          <Box mx={'m'}>
            <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
          </Box>
          <UspSection content={uspContent} title={uspTitle} isProductPage />
          {!isGiftCard && (
            <StickySelector
              product={product}
              sizeErrMsg={sizeErrMsg}
              size={size}
              quantity={quantity}
              objectID={objectID}
              handleCtaClick={handleCtaClick}
              isQuickAddOnPDP={isQuickAddOnPDP}
              ctaState={ctaState}
              isTablet={isTablet}
              pdpAddToCartRef={pdpAddToCartRef}
            />
          )}
        </>
      ) : (
        <>
          <Box display="grid" gridGap="l" mx="l">
            <Breadcrumbs items={breadcrumbItemsWithCurrentProduct} />
            <Box display="flex" gridGap="32px">
              <Box flex={'0 0 63%'} alignSelf="start" position="sticky" top={`${heights.HEADER}px`}>
                <ImagesMozaic
                  productRef={productRef}
                  colorRef={colorRef}
                  productName={productName}
                  colorLabel={colorLabel}
                  imagePositions={imagePositions}
                  videoUrl={videoUrl}
                  product={product}
                  model={model}
                />
              </Box>
              <Box flex={'0 1 37%'} alignSelf="start" position="sticky" top={`${heights.HEADER}px`}>
                <Box display="grid" gridGap="s">
                  <Box display="grid" gridGap="m" gridAutoRows="min-content">
                    {!isGiftCard ? (
                      fragments.InfoPanel
                    ) : (
                      <>
                        <GiftCardInfoPanel
                          ratingAverage={ratingAverage}
                          ratingCount={ratingCount}
                          showReviews={showReviews}
                          reviewsRef={reviewsRef}
                          amount={giftCardAmount}
                        />
                        <GiftCardMiniDescription />
                      </>
                    )}
                    {fragments.PromotionLabel}
                    {fragments.Selectors}
                  </Box>
                  {CtaFragment}
                  {!isGiftCard && fragments.ProductUsp}
                  {isGiftCard ? (
                    <GiftCardDescription />
                  ) : (
                    <Details
                      productDescription={productDescription}
                      cleaningAdvice={cleaningAdvice}
                      productComposition={productComposition}
                      moreDetails={moreDetails}
                      product={product}
                      isHomeMadeRecommendationsEnabled={isHomeMadeRecommendationsEnabled}
                    />
                  )}
                  {!isGiftCard && <Box mt="m">{fragments.CrossSell}</Box>}
                </Box>
              </Box>
            </Box>
            {fragments.UpSell}
          </Box>
          {showReviews && fragments.Reviews}
          {fragments.CommitmentsBanner}
          {!hideCsrBlock && <CsrBlock product={product} cmsProduct={cmsProduct} />}
          <UspSection content={uspContent} title={uspTitle} isProductPage />
          {!isGiftCard && (
            <StickySelector
              product={product}
              sizeErrMsg={sizeErrMsg}
              size={size}
              quantity={quantity}
              objectID={objectID}
              handleCtaClick={handleCtaClick}
              isQuickAddOnPDP={isQuickAddOnPDP}
              ctaState={ctaState}
              isTablet={isTablet}
              pdpAddToCartRef={pdpAddToCartRef}
            />
          )}
        </>
      )}
    </StyledProductPage>
  );
};

export default connect(
  (state: RootState) => ({
    ...state.product,
    ctaState: state.cart.ctaState,
    cart: state.cart.cart,
    cmsProduct: state.cms.product,
    cmsUsp: state.cms.usp,
    algoliaQuery: state.catalog.algoliaQuery,
  }),
  {
    addToCart,
    addGiftCard,
    loadProduct,
    loadReviews,
    loadProductRecommendations,
    loadProductRecommendationsHomemade,
    cmsProductInit,
    setSizeErrMsg,
  }
)(Product);
